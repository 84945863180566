import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import { useState } from 'react';

import { NotificationsSettings } from '../../components/NotificationSettings/NotificationSettings';
import { useUserBulkUpdateQuery } from '../../domain/users/queries';

interface IUserBulkFormProps {
    ids: string[];
    onClose: () => any;
}

export function UserBulkForm({ ids, onClose }: IUserBulkFormProps) {
    const [form] = Form.useForm<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const bulkUpdateUserQuery = useUserBulkUpdateQuery();

    const update = {
        notificationSettings: {
            eventCreated: {
                email: true,
                sms: true
            },        
            preEvent: {
                email: true,
                sms: true
            },        
            eventStarted: {
                email: true,
                sms: true
            },        
            eventCompleted: {
                email: true,
                sms: true
            }
        }
    };

    async function onFormFinish(data: any) {
        console.info(`onFormFinish=`, data);
        try {
            setLoading(true);

            const update = {...data};
            for (let key of Object.keys(update)) {
                if (update[key] === null) delete update[key];
            }

            await bulkUpdateUserQuery.mutateAsync({ids, update});

            notification.info({ key: 'bulk-user-save-info', message: 'Users updated' });
            onClose();
        } catch (err: any) {
            notification.error({ key: 'bulk-user-save-error', message: err.message || 'Cannot update users!' });
        }
        setLoading(false);
    }

    return (
        <Modal
            title={'Update users'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose()}
            footer={[
                <Button onClick={() => onClose()} key='bulk-user-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='bulk-user-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='bulk-user-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={update}
                className='user-form'
            >
                <NotificationsSettings />
            </Form>
        </Modal>
    )
}