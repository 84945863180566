export enum AppTokenPermission {
    EVENT_READ = 'event:read'
}

export interface IAppToken {
    id: string;
    clientSecret: string | null;
    permissions: AppTokenPermission[];
    enabled: boolean;
    lastUsedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface IAppTokenForm {
    id: string | null;
    permissions: string[];
    enabled?: boolean;
}