import { Modal, notification } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Search } from '../../components/Search/Search';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { useAuth } from '../../domain/auth/useAuth';
import { IProgram, IProgramForm } from '../../domain/programs/Program';
import {
    exportPrograms, useProgramBulkDeleteQuery, useProgramDeleteQuery, useProgramListQuery
} from '../../domain/programs/queries';
import { usePageState } from '../../domain/shared/usePageState';
import { ProgramForm, ProgramFormData } from './ProgramForm';

export const Programs = () => {
    const {actor} = useAuth();

    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [program, setProgram] = useState<IProgramForm | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    
    const {data, isError, error, isLoading} = useProgramListQuery({...pageState, include: 'enrolled-sites-count'});
    const programDeleteQuery = useProgramDeleteQuery();
    const programBulkDeleteQuery = useProgramBulkDeleteQuery();

    if (isError) {
        notification.error({
            key: 'fetch-program-list-error',
            message: (error as Error)?.message || 'Cannot fetch program!'
        });
    }

    useEffect(() => {
        document.title = 'Programs | Daydream Energy';
    }, []);
    
    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);


    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
        setSelectedRowKeys([]);
    }

    function onProgramFormClose() {
        setProgram(null);
    }

    function showNewProgramForm() {
        setProgram(ProgramFormData.fromDefault());
    }
    
    function showProgramForm(program: IProgram) {
        setProgram(ProgramFormData.fromProgram(program));
    }
    
    function deleteProgramWithConfirmation(program: IProgram) {
        Modal.confirm({
            title: 'DELETE PROGRAM',
            icon: <ExclamationCircleOutlined />,
            content: `Program \n"${program.title || ''}" will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await programDeleteQuery.mutateAsync(program);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function bulkDeleteProgramsWithConfirmation(ids: any[]) {
        console.info('bulkDeleteProgramsWithConfirmation Ids', ids);
        Modal.confirm({
            title: 'DELETE PROGRAMS',
            icon: <ExclamationCircleOutlined />,
            content: `${ids.length} programs will be deleted. Are you sure?`,
            okText: ids.length === 1 ? `DELETE PROGRAM` : `DELETE ${ids.length} PROGRAMS`,
            async onOk() {
                await programBulkDeleteQuery.mutateAsync(selectedRowKeys as string[]);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function onSelectChange(newSelectedRowKeys: React.Key[]) {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IProgram> | SorterResult<IProgram>[], extra: any) {
        const tableState = tableParamsToPageState<IProgram>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'Program')) {
        actions.push({title: 'Edit', onClick: (record: IProgram) => showProgramForm(record)})
    }

    if (actor.can('delete', 'Program')) {
        actions.push({title: 'Delete', onClick: (record: IProgram) => deleteProgramWithConfirmation(record)})
    }

    const columns: ColumnsType<IProgram> = [
        {
            title: 'Title',
            dataIndex: 'title',
            ellipsis: true,
            ...makeSorter(pageState.sort, 'title'),
            render: (text, record) => text
        },
        {
            title: 'Utility',
            dataIndex: 'utility',
            width: 150,
            ellipsis: true,
            ...makeSorter(pageState.sort, 'utility'),
            render: (text) => text
        },
        {
            title: 'Enrolled Sites',
            dataIndex: 'enrolledSitesCount',
            width: 120,
            align: 'right',
            render: (text) => text
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    const rowSelection: TableRowSelection<IProgram> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };
    
    return (
        <>
            <PageHeader
                pageTitle={<>Programs <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='program-search' />,
                    selectedRowKeys.length > 0 && <Button onClick={() => bulkDeleteProgramsWithConfirmation(selectedRowKeys)} size='large' key='programs-bulk-remove' type='primary' icon={<DeleteOutlined />}>Delete Selected</Button>,
                    <ExportButton fn={() => exportPrograms({pagination: {page: 1, size: 1000}})} key='program-export'>Export</ExportButton>,
                    actor.can('create', 'Program') && <Button size='large' key='new-program' type='primary' onClick={showNewProgramForm} icon={<PlusCircleOutlined />}>New Program</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                rowSelection={rowSelection}
                onChange={onTableChange}
            />
            }
            {program && <ProgramForm program={program} onClose={onProgramFormClose}></ProgramForm>}
        </>
    )
}