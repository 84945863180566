import format from 'date-fns/format';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IUser, IUserForm } from './User';

export interface IUserListQuery extends IListQuery {
    filter?: {
        orgId?: string;
        role?: string;
        search?: string;
    }
}

async function getUser(userId: string): Promise<IUser> {
    return await API.fetch<IUser>(`/api/v1/users/${userId}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function getUsers(query: IUserListQuery): Promise<WithPagination<IUser>> {
    const searchParams = makeURLSearchParams(query);
    
    return await API.fetch<WithPagination<IUser>>(`/api/v1/users?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createUser(user: IUserForm) {
    const data: any = {
        orgId: user.orgId,
        role: user.role,
        email: user.email,
        password: user.password,
        phone: user.phone,
        fullname: user.fullname,
        notificationSettings: user.notificationSettings
    };

    return await API.fetch<IUser>(`/api/v1/users`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateUser(user: IUserForm) {
    const data: any = {
        orgId: user.orgId,
        role: user.role,
        email: user.email,
        password: user.password,
        phone: user.phone,
        fullname: user.fullname,
        notificationSettings: user.notificationSettings
    };

    return await API.fetch<IUser>(`/api/v1/users/${user.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveUser(user: IUserForm) {
    return (user.id) ? await updateUser(user) : await createUser(user);
}

export interface IUserChangePassword {
    oldPassword: string;
    newPassword: string;
}

async function changePassword(data: IUserChangePassword) {
    return await API.fetch<IUser>(`/api/v1/users/self/password`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

export interface IUserSetTwoFactor {
    enabled: boolean;
    twoFactorToken?: string; // 2fa token from sms
}

async function setTwoFactorAuth({enabled, twoFactorToken}: IUserSetTwoFactor) {
    const data: any = {
        enabled
    };

    return await API.fetch<IUser>(`/api/v1/users/self/2fa`, {
        method: 'POST',
        headers: {
            ...(twoFactorToken ? {'dd-2fa-token': twoFactorToken} : {}),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

export interface IUserResendTwoFactorToken {
    tokenId: string;
}

async function resendTwoFactorTokenAuth({tokenId}: IUserResendTwoFactorToken) {
    const data: any = {
        tokenId
    };

    return await API.fetch<IUser>(`/api/v1/users/self/2fa/resend`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function revokeTrustedDevices() {
    return await API.fetch<IUser>(`/api/v1/users/self/2fa/trusted-devices/revoke`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function logoutAllSessions() {
    return await API.fetch<void>(`/api/v1/users/self/logout-all`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function deleteUser(user: IUser) {
    await API.fetch(`/api/v1/users/${user.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function bulkDeleteUsers(ids: string[]) {
    await API.fetch(`/api/v1/users/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            remove: true
        })
    });
}

async function bulkUpdateUsers(ids: string[], update: any) {
    await API.fetch(`/api/v1/users/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            update: update
        })
    });
}

export async function exportUsers(query: IUserListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv'
    });
    const result = await API.fetch<string>(`/api/v1/users?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `users--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function useUserListQuery(query: IUserListQuery) {
    return useQuery(['user', query], () => getUsers(query));
}

export function useUserQuery(userId: string, opts: any = {}) {
    return useQuery(['user', userId], () => getUser(userId), { ...opts });
}

export function useUserCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((user: IUserForm) => createUser(user), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}

export function useUserUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((user: IUserForm) => updateUser(user), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}

export function useUserSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((user: IUserForm) => saveUser(user), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}

export function useUserChangePasswordQuery() {
    return useMutation((params: IUserChangePassword) => changePassword(params), {
        onSuccess() {}
    });
}

export function useUserSetTwoFactorQuery() {
    return useMutation((params: IUserSetTwoFactor) => setTwoFactorAuth(params), {
        onSuccess() {}
    });
}

export function useUserResendTwoFactorTokenQuery() {
    return useMutation((params: IUserResendTwoFactorToken) => resendTwoFactorTokenAuth(params), {
        onSuccess() {}
    });
}

export function useUserRevokeTrustedDevicesQuery() {
    return useMutation(() => revokeTrustedDevices(), {
        onSuccess() {}
    });
}

export function useUserLogoutAllSessionsQuery() {
    return useMutation(() => logoutAllSessions(), {
        onSuccess() {}
    });
}

export function useUserDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((user: IUser) => deleteUser(user), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}

export function useUserBulkDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((ids: string[]) => bulkDeleteUsers(ids), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}

export function useUserBulkUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation(({ids, update}: {ids: string[]; update: any}) => bulkUpdateUsers(ids, update), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}