import Button from 'antd/lib/button';
import Typography from 'antd/lib/typography';
import { useState } from 'react';
import PinInput from 'react-pin-input';

import { useUserResendTwoFactorTokenQuery } from '../../domain/users/queries';

export interface ITwoFactorStepProps {
    tokenId: string;
    onClose: () => void;
    onComplete: (val: any) => void;
}

export function TwoFactorStep(props: ITwoFactorStepProps) {
    // const [loading, setLoading] = useState<boolean>(false);
    const [token, setToken] = useState<string | null>(null);
    const userResendTwoFactorTokenQuery = useUserResendTwoFactorTokenQuery();

    async function onResend() {
        await userResendTwoFactorTokenQuery.mutateAsync({tokenId: props.tokenId});
    }

    function onChange(value: string, index: number) {
        console.info('TwoFactorStep.onChange', index, value);
        if (value.length !== 6) {
            setToken(null);
        }
    }

    function onComplete(value: string) {
        console.info('TwoFactorStep.onComplete', value);
        setToken(value);
        props.onComplete({ twoFactorToken: value });
    }

    return (
        <>
            <Typography.Text>Confirmation token has been sent to your phone number. Please enter the token in the field below.</Typography.Text>
            <PinInput 
                length={6}
                focus={true} 
                initialValue=""
                type="numeric" 
                inputMode="number"
                style={{padding: '10px', width: '344px', margin: '0 auto'}}  
                inputStyle={{borderColor: 'red'}}
                inputFocusStyle={{borderColor: 'blue'}}
                onChange={onChange} 
                onComplete={onComplete}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            <Button onClick={onResend} key='two-factor-resend-submit' type='link' loading={false}>resend token</Button>
        </>
    )
}