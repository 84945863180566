import './SiteInfoCard.css';

import Space from 'antd/lib/space';
import endOfTomorrow from 'date-fns/endOfTomorrow';
import startOfToday from 'date-fns/startOfToday';
import { useEffect, useState } from 'react';

import BankOutlined from '@ant-design/icons/BankOutlined';

import { IEvent } from '../../domain/events/Event';
import { isEventInThisDay } from '../../domain/events/isEventInThisDay';
import { useEventListQuery } from '../../domain/events/queries';
import { ISiteWithPrices } from '../../domain/sites/Site';
import { DayEventsList } from '../DayEventsList/DayEventsList';
import { WeatherCard } from '../WeatherCard/WeatherCard';

interface ISiteInfoCardProps {
    site: ISiteWithPrices;
    onClose: any;
}

export const SiteInfoCard = ({ site, onClose }: ISiteInfoCardProps) => {
    const [todayEvents, setTodayEvents] = useState<IEvent[]>([]);
    const [tomorrowEvents, setTomorrowEvents] = useState<IEvent[]>([]);

    useEffect(() => {
        console.info('SITE PRICE was changed', site?.prices?.realtime);
    }, [site?.prices?.realtime]);

    const start = startOfToday();
    const end = endOfTomorrow();

    const {isLoading, data} = useEventListQuery({
        pagination: {page: 1, size: 1000}, filter: {siteId: site.id, start, end}
    }, {cacheTime: 60*60*1000, staleTime: 60*60*1000});

    useEffect(() => {
        console.info('events loaded', data);

        if (data) {
            const todayEvents = data.data.filter(event => isEventInThisDay(event, start));
            const tomorrowEvents = data.data.filter(event => isEventInThisDay(event, end));

            setTodayEvents(todayEvents);
            setTomorrowEvents(tomorrowEvents);
        } else {
            setTodayEvents([]);
            setTomorrowEvents([]);
        }
    }, [data]);

    return (
        <div className='site-info-card'>
            <Space direction='vertical' style={{width: '100%'}}>
                <div className='site-info-card-header'>
                    <div>
                        <h2><BankOutlined /> {site.org?.title}</h2>
                        <h1>{site.title}</h1>
                        <div>{site.location.address}</div>
                    </div>

                    <div className='site-info-card-prices'>
                        {site.prices?.realtime? <div>${site.prices?.realtime}</div>: ''}
                    </div>
                </div>
                <WeatherCard location={{lat: site.location.lat, long: site.location.long}} events={[...todayEvents, ...tomorrowEvents]} />
                <DayEventsList title="Today's events" date={start} isLoading={isLoading} events={todayEvents} timezone={site?.location?.timezone} key='today-events'/>
                <DayEventsList title="Tomorrow's events" date={end} isLoading={isLoading} events={tomorrowEvents} timezone={site?.location?.timezone} key='tomorrow-events' />
            </Space>
        </div>
    )
}