import { IOrg } from '../orgs/Org';

export interface NotificationSettings {
    eventCreated: {
        email: boolean;
        sms: boolean;
    }

    preEvent: {
        email: boolean;
        sms: boolean;
    }

    eventStarted: {
        email: boolean;
        sms: boolean;
    }

    eventCompleted: {
        email: boolean;
        sms: boolean;
    }
}

export enum Role {
    ADMIN = 'admin',
    ACCOUNT_MANAGER = 'account-manager',
    CUSTOMER = 'customer',
    OPERATOR = 'operator',
    PROVIDER = 'provider'
}

export interface IUser {
    id: string;
    orgId: string;
    org: IOrg | null;
    role: Role;
    email: string;
    phone: string | null;
    fullname: string;
    notificationSettings: NotificationSettings;
    twoFactor: {
        enabled: boolean
    };
    lastLoggedAt: Date | null;
    createdAt: Date;
    updatedAt: Date;
}

export interface IUserForm {
    id: string | null;
    orgId: string | null;
    role: Role;
    email: string | null;
    password: string | null;
    phone: string | null;
    fullname: string | null;
    notificationSettings: NotificationSettings;
}