import './Login.css';

import Button from 'antd/lib/button';
import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { RequiredSteps } from '../../components/RequiredSteps/RequiredSteps';
import {
    RequiredStep, RequiredStepsError
} from '../../components/RequiredSteps/RequiredStepsError';
import { SigninData } from '../../domain/auth/auth';
import { useAuth } from '../../domain/auth/useAuth';

export const Login = () => {
    const [loading, setLoading] = useState(false);
    const [requiredSteps, setRequiredSteps] = useState<RequiredStep[]>([]);

    const { signin } = useAuth();
    const [form] = Form.useForm();

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Login | Daydream Energy';
    }, []);

    let returnPath = '/';
    const search = new URLSearchParams(location.search);
    const returnPathEncoded = search.get('return_path');
    if (returnPathEncoded) {
        returnPath = decodeURIComponent(returnPath);
    }

    const onFinish = async (values: SigninData) => {
        await doSignin();
    };

    async function doSignin(extra?: {twoFactorToken?: string; captcha?: string}) {
        console.info('doSignin', extra);
        setLoading(true);
        try {
            const email = form.getFieldValue('email');
            const password = form.getFieldValue('password');
            const remember = form.getFieldValue('remember');

            await signin({email, password, remember, ...extra});

            navigate(returnPath, { replace: true });
        } catch (error: any) {
            if (error instanceof RequiredStepsError) {
                console.info('got RequiredStepsError', error);
                setRequiredSteps(error.steps);
            } else {
                setRequiredSteps([]);

                notification.error({
                    key: 'signin-error',
                    message: 'SignIn Error',
                    description: error.message,
                });
            }
        }
        setLoading(false);
    }

    function onRequiredStepsClose() {
        setRequiredSteps([]);
    }

    function onRequiredStepsComplete(args: any) {
        console.info('Login.onRequiredStepsComplete', args);
        setRequiredSteps([]);
        doSignin(args);
    }

    return (
        <div className='login'>
            <img className='logo' src='/logo.png' alt='Daydream Energy logo' />
            <Form
                form={form}
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name='email'
                    rules={[
                        { type: 'email', message: 'The input is not valid E-mail!' },
                        { required: true, message: 'Please enter your Email!' }
                    ]}
                >
                    <Input placeholder='Email' autoFocus size='large' autoComplete='email'/>
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[{ required: true, message: 'Please enter your Password!' }]}
                >
                    <Input type='password' placeholder='Password' size='large' autoComplete='current-password'/>
                </Form.Item>
                <Form.Item>
                    <Form.Item name='remember' valuePropName='checked' noStyle>
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item noStyle>
                    <Button type='primary' htmlType='submit' block loading={loading} disabled={false} size='large'>LOG IN</Button>
                </Form.Item>
                <Button type="link" onClick={() => navigate('/reset-password')} className='extra-action' >I forgot password</Button>
            </Form>
            {requiredSteps.length > 0 && <RequiredSteps steps={requiredSteps} onClose={() => onRequiredStepsClose()} onComplete={onRequiredStepsComplete} />}
        </div>
    );
}