import { useQuery } from 'react-query';

import { API } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IAuditRecord } from './AuditRecord';

export interface IAuditLogQuery {
    entity: string;
    entityId: string;
}

async function getAuditLog({entity, entityId}: IAuditLogQuery): Promise<WithPagination<IAuditRecord>> {
    const searchParams = new URLSearchParams({
        entity: entity,
        entityId: entityId
    });

    return await API.fetch<WithPagination<IAuditRecord>>(`/api/v1/audit?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export function useAuditLogQuery(query: IAuditLogQuery) {
    return useQuery(['audit', query], () => getAuditLog(query));
}