import isValid from 'date-fns/isValid';
import isEqual from 'lodash.isequal';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface IPageState {
    pagination: {
        page: number;
        size: number;
    },
    sort: {
        field: string;
        order: 'asc' | 'desc';
    } | undefined,
    filter: {
        orgId?: string;
        providerId?: string;
        programId?: string;
        type?: string;  // org type
        role?: string;  // user role
        start?: Date;
        end?: Date;
        search?: string;
    } | undefined;
}

function parseSearchParams(searchParams: URLSearchParams): IPageState {
    const pagination: IPageState['pagination'] = {
        page: parseInt(searchParams.get('pagination.page') || '1'),
        size: parseInt(searchParams.get('pagination.size') || '20')
    }

    let sort: IPageState['sort'] | undefined = undefined;
    if (searchParams.has('sort.field')) {
        sort = {
            field: searchParams.get('sort.field')!,
            order: searchParams.get('sort.order') === 'asc' ? 'asc' : 'desc'
        }
    }

    let filter: IPageState['filter'] | undefined = undefined;

    const orgId =  searchParams.get('filter.orgId') || undefined;
    const programId =  searchParams.get('filter.programId') || undefined;
    const providerId =  searchParams.get('filter.providerId') || undefined;
    const type =  searchParams.get('filter.type') || undefined;
    const role =  searchParams.get('filter.role') || undefined;
    const search =  searchParams.get('filter.search') || undefined;

    let start: Date | undefined = undefined;
    if (searchParams.has('filter.start')) {
        let _start = new Date(`${searchParams.get('filter.start')}`);
        start = isValid(_start) ? _start : undefined;
    }

    let end: Date | undefined = undefined;
    if (searchParams.has('filter.end')) {
        let _end = new Date(`${searchParams.get('filter.end')}`);
        end = isValid(_end) ? _end : undefined;
    }

    if (orgId || programId || providerId || type || role || search || start || end) {
        filter = {
            ...orgId && {orgId},
            ...programId && {programId},
            ...providerId && {providerId},
            ...type && {type},
            ...role && {role},
            ...search && {search},
            ...start && {start},
            ...end && {end},
        }
    }

    return {pagination, sort, filter};
}

export const usePageState = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [pageState, setPageState] = useState<IPageState>(parseSearchParams(searchParams));

    useEffect(() => {
        setPageState(parseSearchParams(searchParams));
    }, [searchParams]);

    function setPageStateHandler(state: IPageState, {patch}: {patch: boolean} = {patch: false}) {
        setSearchParams({
            ...(state.pagination && {
                'pagination.page': `${state.pagination.page}`,
                'pagination.size': `${state.pagination.size}`,
            }),
            ...(state.sort && {
                'sort.field': state.sort.field,
                'sort.order': state.sort.order,
            }),
            ...(state.filter && {
                ...state.filter.orgId && {'filter.orgId': state.filter.orgId},
                ...state.filter.programId && {'filter.programId': state.filter.programId},
                ...state.filter.providerId && {'filter.providerId': state.filter.providerId},
                ...state.filter.type && {'filter.type': state.filter.type},
                ...state.filter.role && {'filter.role': state.filter.role},
                ...state.filter.search && {'filter.search': state.filter.search},
                ...state.filter.start && {'filter.start': state.filter.start.toISOString()},
                ...state.filter.end && {'filter.end': state.filter.end.toISOString()},
            })
        }, {replace: true})

        if (patch) {
            const patched = merge(pageState, state)
            console.info('patchPageState=', patched);
            setPageState(patched);
            return;
        }

        if (isEqual(pageState, state)) {
            console.info(`pageState is the same`);
            return;
        }
        
        console.info('setPageState=', state);
        setPageState((prevState) => {
            if (isEqual(prevState, state)) return prevState;
            return state;
        });
    }

    return {
        pageState,
        setPageState: setPageStateHandler
    }
};