import { RequiredStepsError } from '../../components/RequiredSteps/RequiredStepsError';
import { config } from '../../config';
import { Auth } from '../auth/auth';
import { Session } from '../auth/Session';
import { BadRequestError } from './BadRequestError';
import { ForbiddenError } from './ForbiddenError';
import { NetworkError } from './NetworkError';
import { NotFoundError } from './NotFoundError';

export const API = {
    fetch: async <T>(path: string, options: RequestInit = {}): Promise<T> => {
        try {
            const actor = Session.getActor();
            
            if (!options?.headers) {
                options.headers = {};
            }

            if ('Authorization' in options.headers === false) {
                options.headers = {
                    ...options.headers,
                    'Authorization': `Bearer ${actor.accessToken}`
                }
            }
            const response = await fetch(`${config.API_URL}${path}`, options)

            if (!response.ok) {
                await validateFetchResponse(response);
            }

            const isJson = response.headers.get('content-type')?.includes('application/json');

            return isJson ? await response.json() : await response.text();
        } catch (err) {
            console.info('API.fetch error', err);
            if (err instanceof RequiredStepsError || err instanceof BadRequestError || err instanceof NotFoundError || err instanceof NetworkError || err instanceof ForbiddenError) {
                throw err;
            }
            throw new NetworkError('Network error')
        }
    }
}

export interface IListQuery {
    pagination?: {page: number; size: number},
    sort?: {
        field: string;
        order: 'asc' | 'desc';
    },
    filter?: {
        [key: string]: string | number | Date;
    },
    include?: string;
}

export function makeURLSearchParams(query: IListQuery): URLSearchParams {
    const page = query?.pagination?.page || 1;
    const limit = query?.pagination?.size || 20;
    const offset = (page <= 1) ? 0 : (page - 1) * limit;

    let filter: {[key: string]: string} = {};
    if (query.filter) {
        for (let [key, val] of Object.entries<any>(query.filter)) {

            if (typeof val !== 'undefined' && val !== null) {
                if (val instanceof Date ) {
                    filter[key] = val.toISOString();
                } else if (typeof val !== 'string') {
                    filter[key] = String(val);
                } else {
                    filter[key] = val;
                }
            }
        }
    }

    const searchParams = new URLSearchParams({
        offset: `${offset}`,
        limit: `${limit}`,
        sortField: query.sort?.field || 'createdAt',
        sortOrder: query.sort?.order || 'desc',
        ...filter,
        ...(query.include && {include: query.include}),
    });

    return searchParams
}

async function validateFetchResponse(response: Response): Promise<void> {
    console.warn('validateFetchResponse status=', response.status, response.statusText);
    switch (response.status) {
        case 400: 
            const respBody = await response.json();
            console.info('fetch error', respBody);

            if (respBody?.require?.length) {
                throw new RequiredStepsError(respBody?.require);
            }

            let errorMessage: string = respBody?.error.message || 'Bad Request';
            if (respBody?.error?.errors) {
                errorMessage = 'Validation Error: ';

                for (let obj of respBody?.error?.errors) {
                    if (obj.constraints) {
                        const messages: string[] = Object.values(obj.constraints);
                        for (let message of messages) {
                            errorMessage = `${errorMessage} ${message}.`
                        }
                    }
                }
            }

            throw new BadRequestError(errorMessage);
        case 404:
            throw new NotFoundError('Resource not found');
        case 401:
            console.info('got 401 Unauthorized - logout');
            Auth.terminateSession();  
            break;
        case 403:
            throw new ForbiddenError('Forbidden');
        default:
            throw new Error('Unknown error');
    }
}