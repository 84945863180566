import './ProviderSelector.css';

import Select from 'antd/lib/select';
import { useState } from 'react';

import { IOrg } from '../../domain/orgs/Org';
import { useOrgListQuery } from '../../domain/orgs/queries';

export interface IProviderSelectorProps {
    providerId?: string | null;
    disabled?: boolean;
    onChange: (providerId: string | null) => void
}

export const ProviderSelector = (props: IProviderSelectorProps) => {
    const {data, isLoading} = useOrgListQuery({pagination: {page: 1, size: 1000}, filter: {type: 'provider'}});
    const providers = data?.data || [];

    const [id, setId] = useState(() => {
        if (props.providerId) return props.providerId;
        return undefined;
    });

    function onChange(providerId: string) {
        console.info(`ProviderSelector.onChange providerId=`, providerId);
        setId(providerId);
        props.onChange(providerId);
    }

    function makeProviderOptionsElements(providers: IOrg[]) {
        const items = providers.map(provider => {
            return {
                recent: false,
                value: provider.id,
                key: `provider-${provider.id}`,
                label: provider.title
            }
        });

        return items.map(item => makeProviderOptionElement(item));
    }

    function makeProviderOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.label}
            </Select.Option>
        )
    }

    return (
        <Select
            loading={isLoading}
            disabled={props.disabled}
            size='large'
            placeholder='Please select provider'
            value={id}
            onChange={onChange}
            className='provider-selector'
            bordered={true}
            showSearch
            allowClear
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeProviderOptionsElements(providers)}
        </Select>
    )
}