import './DayEventsList.css';

import Card from 'antd/lib/card';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import add from 'date-fns/add';
import format from 'date-fns/format';

import { IEvent } from '../../domain/events/Event';
import { DatetimePopover } from '../DatetimePopover/DatetimePopover';
import { EventSourceTag } from '../EventSourceTag/EventSourceTag';

export interface IDayEventsListProps {
    title: string;
    date: Date;
    events: IEvent[];
    timezone: string;
    isLoading: boolean;
}

export const DayEventsList = ({title, date, events, isLoading, timezone}: IDayEventsListProps) => {

    function renderEvent(event: IEvent) {
        const start = format(new Date(event.start), 'hh:mm a');
        const end = format(add(new Date(event.start), {minutes: event.durationMinutes}), 'hh:mm a');

        return (
            <DatetimePopover timestamp={new Date(event.start).valueOf()} siteTimezone={timezone} key={`event-${event.id}`} >
                <Space direction='horizontal'><Typography.Text key={`event-${event.id}`}>{start} - {end}</Typography.Text> <EventSourceTag source={event.source} /></Space>
            </DatetimePopover>
        )
    }

    return (
        <Card
            title={title}
            extra={format(date, 'LLL dd')}
            size='small'
            loading={isLoading}
            className='day-events-list'
            bodyStyle={{ maxHeight: '200px', overflow: 'auto' }}
        >
            {events.length 
                ? <Space direction="vertical">{events.map(event => renderEvent(event))}</Space>
                : <Typography.Text type='secondary' className='no-events-message'>No events scheduled.</Typography.Text>
            }
        </Card>
    )
}