import './TwoFactorSettings.css';

import { Button, Divider, notification, Space, Typography } from 'antd';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { useEffect, useState } from 'react';

import LaptopOutlined from '@ant-design/icons/lib/icons/LaptopOutlined';
import MobileOutlined from '@ant-design/icons/lib/icons/MobileOutlined';

import { useAuth } from '../../domain/auth/useAuth';
import {
    useUserRevokeTrustedDevicesQuery, useUserSetTwoFactorQuery
} from '../../domain/users/queries';
import { ControlledTooltip } from '../ControlledTooltip/ControlledTooltip';
import { RequiredSteps } from '../RequiredSteps/RequiredSteps';
import { RequiredStep, RequiredStepsError } from '../RequiredSteps/RequiredStepsError';
import { TwoFactorIcon } from '../TwoFactorIcon';

export interface ITwoFactorSettingsProps {}

export function TwoFactorSettings() {
    const {actor, refresh} = useAuth();
    const actorWithoutPhone = !actor.phone;

    const [requiredSteps, setRequiredSteps] = useState<RequiredStep[]>([]);
    const setTwoFactorQuery = useUserSetTwoFactorQuery();
    const revokeTrustedDevicesQuery = useUserRevokeTrustedDevicesQuery();

    useEffect(() => {
        const error = setTwoFactorQuery.error as Error;
        if (error) {
            if (error instanceof RequiredStepsError) {
                console.info('got RequiredStepsError', error);
                setRequiredSteps(error.steps);
            } else {
                notification.error({ key: 'two-factor-set-error', message: error?.message || 'Cannot update 2FA settings!' });
            }
        }
    }, [setTwoFactorQuery.error]);

    useEffect(() => {
        console.info({actor});
    }, [actor]);

    function onEnable() {
        setTwoFactorQuery.mutateAsync({enabled: true});
    }

    function onDisable() {
        setTwoFactorQuery.mutateAsync({enabled: false});
    }

    function onRequiredStepsClose() {
        setRequiredSteps([]);
    }

    async function onRequiredStepsComplete(value: {twoFactorToken: string}) {
        console.info('TwoFactorSettings.onRequiredStepsComplete', value);
        setRequiredSteps([]);
        await setTwoFactorQuery.mutateAsync({enabled: !actor?.twoFactor?.enabled, ...value});
        await refresh();
    }

    async function revokeTrustedDevices() {
        await revokeTrustedDevicesQuery.mutateAsync();
        notification.info({ key: 'revoke-trusted-devices-info', message: 'All trusted devices revoked' });
    }

    return (
        <ControlledTooltip visible={actorWithoutPhone} title='You cannot enable 2FA. Please update your phone first'>
        <Card bordered={true} className='two-factor-settings' bodyStyle={{padding: '12px'}}>
            <Card.Meta
                avatar={<TwoFactorIcon style={{ fontSize: '28px', paddingRight: '0px'}}/>}
                title='Two Factor Authentication'
            />

            <Row gutter={8} align="middle">
                <Col span={5}>
                    {
                    actor?.twoFactor?.enabled
                        ? <Button onClick={onDisable} disabled={actorWithoutPhone} loading={setTwoFactorQuery.isLoading} style={{width: '90px'}} type='primary'>Turn OFF</Button>
                        : <Button onClick={onEnable} disabled={actorWithoutPhone} loading={setTwoFactorQuery.isLoading} style={{width: '90px'}}>Turn ON</Button>
                    }
                </Col>
                <Col span={19}>
                    With two-factor authentication, you add an extra layer of security to your account in case your password is stolen. <br/>
                    After you set up two-factor authentication, we will require a code from SMS during sign in.
                </Col>
            </Row>
            
            {actor?.twoFactor?.enabled &&
            <>
            <Divider />
            <Row gutter={8} align="middle">
                <Col span={5} style={{padding: '0 20px'}}>
                    <Space>
                    <LaptopOutlined style={{ fontSize: '24px', color: '#000'}} />
                    <MobileOutlined style={{ fontSize: '24px', color: '#000'}} />
                    </Space>
                </Col>
                <Col span={19}>
                    <Typography.Title level={5} >Devices you trust</Typography.Title>
                    <Space direction='vertical'>
                        <Typography.Text>
                        Revoke trusted status from your devices that skip two-factor authentication.
                        </Typography.Text>
                        <Button onClick={revokeTrustedDevices} loading={revokeTrustedDevicesQuery.isLoading} >Revoke All</Button>
                    </Space>
                </Col>
            </Row>
            </>
            }
        </Card>
        {requiredSteps.length > 0 && <RequiredSteps steps={requiredSteps} onClose={() => onRequiredStepsClose()} onComplete={onRequiredStepsComplete} />}
        </ControlledTooltip>
    )
}