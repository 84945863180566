import './PageHeader.css';

import { Badge } from 'antd';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import React from 'react';

interface IPageHeader {
    pageTitle: string | React.ReactNode,
    actions: React.ReactNode,
    extra?: React.ReactNode
}

export const PageHeader = ({ pageTitle, actions, extra }: IPageHeader) => {
    return (
        <Row justify='space-between' gutter={[16, 16]} style={{ paddingBottom: 24 }} align='middle'>
            <Col className='page-header' key='page-header-title-col'>
                <Typography.Title level={4} style={{ margin: '0 8px 0 0' }}>{pageTitle}</Typography.Title>
                {extra}
            </Col>
            <Col key='page-header-actions-col'>
                <Space wrap>
                    {actions}
                </Space>
            </Col>
        </Row>
    )
}