import { Modal, notification } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { useOrg } from '../../components/OrgContext/OrgContext';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { useAuth } from '../../domain/auth/useAuth';
import { SIGNAL_LEVEL_OPTIONS } from '../../domain/events/Event';
import {
    IPriceResponseTrigger, IPriceResponseTriggerForm
} from '../../domain/price-response-trigger/PriceResponseTrigger';
import {
    exportPriceResponseTriggers, usePriceResponseTriggerBulkDeleteQuery,
    usePriceResponseTriggerDeleteQuery, usePriceResponseTriggerListQuery
} from '../../domain/price-response-trigger/queries';
import { formatCurrency } from '../../domain/shared/formatCurrency';
import { usePageState } from '../../domain/shared/usePageState';
import { PriceResponseTriggerForm, PriceResponseTriggerFormData } from './PriceResponseTriggerForm';

export const PriceResponseTriggers = () => {
    const {actor} = useAuth();
    const {orgId} = useOrg();
    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [priceResponseTrigger, setPriceResponseTrigger] = useState<IPriceResponseTriggerForm | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    
    const {data, isError, error, isLoading} = usePriceResponseTriggerListQuery({...pageState, include: 'org'});
    const priceResponseTriggerDeleteQuery = usePriceResponseTriggerDeleteQuery();
    const priceResponseTriggerBulkDeleteQuery = usePriceResponseTriggerBulkDeleteQuery();

    useEffect(() => {
        document.title = 'Price Response Triggers | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    if (isError) {
        notification.error({
            key: 'fetch-price-response-triggers-list-error',
            message: (error as Error)?.message || 'Cannot fetch price response triggers!'
        });
    }
    
    function onPriceResponseTriggerFormClose() {
        setPriceResponseTrigger(null);
    }

    function showNewPriceResponseTriggerForm() {
        setPriceResponseTrigger(PriceResponseTriggerFormData.fromDefault(orgId));
    }
    
    function showPriceResponseTriggerForm(trigger: IPriceResponseTrigger) {
        setPriceResponseTrigger(PriceResponseTriggerFormData.fromPriceResponseTrigger(trigger));
    }

    function deletePriceResponseTriggerWithConfirmation(trigger: IPriceResponseTrigger) {
        Modal.confirm({
            title: 'DELETE PRICE RESPONSE TRIGGER',
            icon: <ExclamationCircleOutlined />,
            content: `Trigger \n"${trigger.state} - $${trigger.price}" will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await priceResponseTriggerDeleteQuery.mutateAsync(trigger);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function bulkDeletePriceResponseTriggerWithConfirmation(ids: any[]) {
        console.info('bulkDeletePriceResponseTriggerWithConfirmation Ids', ids);
        Modal.confirm({
            title: 'DELETE PRICE RESPONSE TRIGGERS',
            icon: <ExclamationCircleOutlined />,
            content: `${ids.length} triggers will be deleted. Are you sure?`,
            okText: ids.length === 1 ? `DELETE TRIGGER` : `DELETE ${ids.length} TRIGGERS`,
            async onOk() {
                await priceResponseTriggerBulkDeleteQuery.mutateAsync(selectedRowKeys as string[]);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function onSelectChange(newSelectedRowKeys: React.Key[]) {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IPriceResponseTrigger> | SorterResult<IPriceResponseTrigger>[], extra: any) {
        const tableState = tableParamsToPageState<IPriceResponseTrigger>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'PriceResponseTrigger')) {
        actions.push({title: 'Edit', onClick: (record: IPriceResponseTrigger) => showPriceResponseTriggerForm(record)})
    }

    if (actor.can('delete', 'PriceResponseTrigger')) {
        actions.push({title: 'Delete', onClick: (record: IPriceResponseTrigger) => deletePriceResponseTriggerWithConfirmation(record)})
    }

    const columns: ColumnsType<IPriceResponseTrigger> = [
        {
            title: 'Org',
            dataIndex: 'orgId',
            render: (text, record) => record?.org?.title
        },
        {
            title: 'State',
            dataIndex: 'state',
            render: (text, record) => text
        },
        {
            title: 'Price $/MWh',
            dataIndex: 'price',
            ...makeSorter(pageState.sort, 'price'),
            render: (text, record) => formatCurrency(text)
        },
        {
            title: 'Event Duration',
            dataIndex: 'eventDurationMinutes',
            render: (text, record) => `${text}m`
        },
        {
            title: 'Event Signal',
            dataIndex: 'eventSignal',
            render: text => SIGNAL_LEVEL_OPTIONS.find(signal => +text === signal.value)?.label,
        },
        {
            title: 'Event Trigger Time',
            render: (text, record) => {
                if (record.startTime === '00:00:00' && record.endTime === '23:59:59') return <>whole day</>
                return <>{record.startTime} - {record.endTime}</>;
            },
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    const rowSelection: TableRowSelection<IPriceResponseTrigger> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    return (
        <>
            <PageHeader
                pageTitle={<>Price Response Triggers <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    selectedRowKeys.length > 0 && <Button onClick={() => bulkDeletePriceResponseTriggerWithConfirmation(selectedRowKeys)} size='large' key='price-response-trigger-bulk-remove' type='primary' icon={<DeleteOutlined />}>Delete Selected</Button>,
                    <ExportButton fn={() => exportPriceResponseTriggers({include: 'org'})} key='price-response-trigger-export'>Export</ExportButton>,
                    actor.can('create', 'PriceResponseTrigger') && <Button size='large' key='new-price-response-trigger' type='primary' onClick={showNewPriceResponseTriggerForm} icon={<PlusCircleOutlined />}>New Trigger</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                rowSelection={rowSelection}
                rowClassName={(record, index) => {return record.enabled === false ? 'table-row-disabled' : ''}}
                onChange={onTableChange}
            />
            }
            {priceResponseTrigger && <PriceResponseTriggerForm priceResponseTrigger={priceResponseTrigger} onClose={onPriceResponseTriggerFormClose} />}
        </>
    )
}