import format from 'date-fns/format';
import { saveAs } from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { ISite, ISiteForm } from './Site';

export interface ISiteListQuery extends IListQuery {
    filter?: {
        orgId?: string;
        programId?: string;
        search?: string;
    }
}

async function getSites(query: ISiteListQuery): Promise<WithPagination<ISite>> {
    const searchParams = makeURLSearchParams(query);

    return await API.fetch<WithPagination<ISite>>(`/api/v1/sites?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createSite(site: ISiteForm) {
    const data: any = {
        orgId: site.orgId,
        providerId: site.providerId,
        title: site.title,
        location: site.location,
        serviceAccountNumber: site.serviceAccountNumber,
        siteNumber: site.siteNumber,
        estimatedKw: site.estimatedKw,
        utilityId: site.utilityId,
        eventOffset: site.eventOffset,
        eventMaxDuration: site.eventMaxDuration,
        priceResponseEnrolled: site.priceResponseEnrolled,
        peakSaverEnrolled: site.peakSaverEnrolled,
        ...(site.programIds && {programIds: site.programIds})
    };

    return await API.fetch<ISite>(`/api/v1/sites`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateSite(site: ISiteForm) {
    const data: any = {
        orgId: site.orgId,
        providerId: site.providerId,
        title: site.title,
        location: site.location,
        serviceAccountNumber: site.serviceAccountNumber,
        siteNumber: site.siteNumber,
        estimatedKw: site.estimatedKw,
        utilityId: site.utilityId,
        eventOffset: site.eventOffset,
        eventMaxDuration: site.eventMaxDuration,
        priceResponseEnrolled: site.priceResponseEnrolled,
        peakSaverEnrolled: site.peakSaverEnrolled,
        ...(site.partner?.id && { partner: {id: site.partner.id} }),
        ...(site.programIds && { programIds: site.programIds })
    };

    return await API.fetch<ISite>(`/api/v1/sites/${site.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

export interface IBatchSiteForm {
    ids: string[];

    update: {
        providerId: string | null | undefined;
        utility: string | null | undefined;
        eventOffset: number | null | undefined;
        eventMaxDuration: number | null | undefined;
    }
}

async function batchUpdateSite(dto: IBatchSiteForm) {
    const {ids, update} = dto;
    const data: any = {
        ids: ids,
        update: {
            ...(update.providerId !== undefined && {providerId: update.providerId}),
            ...(update.utility !== undefined && {utility: update.utility}),
            ...(update.eventOffset !== undefined && {eventOffset: update.eventOffset}),
            ...(update.eventMaxDuration !== undefined && {eventMaxDuration: update.eventMaxDuration}),
        }
    };

    await API.fetch(`/api/v1/sites/batch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveSite(site: ISiteForm) {
    return (site.id) ? await updateSite(site) : await createSite(site);
}

async function deleteSite(site: ISite) {
    await API.fetch(`/api/v1/sites/${site.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function bulkDeleteSites(ids: string[]) {
    await API.fetch(`/api/v1/sites/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            remove: true
        })
    });
}

async function bulkUpdateSites(ids: string[], update: any) {
    await API.fetch(`/api/v1/sites/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            update: update
        })
    });
}

export async function exportSites(query: ISiteListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv',
        ...(query.filter?.orgId && {orgId: query.filter?.orgId}),
        ...(query.include && {include: query.include}),
    });
    const result = await API.fetch<string>(`/api/v1/sites?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `sites--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function useSiteListQuery(query: ISiteListQuery) {
    return useQuery(['site', query], () => getSites(query));
}

export function useSiteCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((site: ISiteForm) => createSite(site), {
        onSuccess() {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((site: ISiteForm) => updateSite(site), {
        onSuccess(result, variables, context: any) {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((site: ISiteForm) => saveSite(site), {
        onSuccess() {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteBatchUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((dto: IBatchSiteForm) => batchUpdateSite(dto), {
        onSuccess(result, variables, context: any) {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((site: ISite) => deleteSite(site), {
        onSuccess() {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteBulkDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((ids: string[]) => bulkDeleteSites(ids), {
        onSuccess() {
            return queryClient.invalidateQueries(['site'])
        }
    });
}

export function useSiteBulkUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation(({ids, update}: {ids: string[]; update: any}) => bulkUpdateSites(ids, update), {
        onSuccess() {
            return queryClient.invalidateQueries(['site'])
        }
    });
}