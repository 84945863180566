import Select from 'antd/lib/select';
import eachMonthOfInterval from 'date-fns/eachMonthOfInterval';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import startOfMonth from 'date-fns/startOfMonth';
import sub from 'date-fns/sub';

function makeDateSelectorOptions() {
    const options: { label: string; value: any }[] = [];

    const currentMonth = startOfMonth(new Date());
    const yearAgo = sub(currentMonth, { months: 11 }); // 1 year back
    const monthList = eachMonthOfInterval({ start: yearAgo, end: currentMonth });

    for (let i = monthList.length - 1; i >= 0; i--) {
        const month = monthList[i];
        const label = format(month, 'MMMM yyyy');
        const value = format(month, 'yyyy-MM-dd');
        options.push({ label, value });
    }

    return options;
}

interface IEventsDateRange {
    start: Date;
    end: Date;
}

interface IEventsDateRangeSelectorProps {
    value: IEventsDateRange | null;
    onChange: (range: IEventsDateRange) => void
}

export const getCurrentMonthDateRange = () => {
    const now = new Date();
    const start = startOfMonth(now);
    const end = endOfMonth(now);

    return { start, end };
}
export const EventsDateRangeSelector = ({ onChange, value }: IEventsDateRangeSelectorProps) => {
    if (!value) {
        value = getCurrentMonthDateRange();
    }

    const options = makeDateSelectorOptions();

    const onDateRangeChange = (value: string) => {
        console.info('onDateRangeChange', value);
        const start = startOfMonth(new Date(value));
        const end = endOfMonth(start);
        onChange({start, end});
    }
    return (
        <Select
            size='large'
            placeholder='Please select month'
            value={format(value.start, 'yyyy-MM-dd')}
            options={options}
            style={{ width: '200px' }}
            onChange={onDateRangeChange}
        />
    )
}