import InputNumber from 'antd/lib/input-number';
import { useState } from 'react';

interface IHoursInputProps {
    minutes: number | null;
    disabled?: boolean;
    onChange: (minutes: number | null) => void;
}

export const HoursInput = (props: IHoursInputProps) => {
    const [minutes, setMinutes] = useState<number | null>(props.minutes);
    const hours = !minutes ? 0 : +(minutes / 60).toFixed(2);

    const disabled = props.disabled || false;

    const allowOnlyDigits = (event: any) => {
        const charCode = (typeof event.which == 'undefined') ? event.keyCode : event.which;
        const charStr = String.fromCharCode(charCode);
        if (!charStr.match(/^\d+$/)) {
            event.preventDefault();
        }
    }

    function onChange(hours: number) {
        const minutes = hours * 60;
        setMinutes(minutes);
        props.onChange(minutes);
    }

    return (
        <InputNumber 
            value={hours}
            onChange={onChange}
            type='number' 
            min={0} max={2} step={0.25} 
            stringMode={false} 
            size='large' 
            onKeyPress={allowOnlyDigits} 
            addonAfter={'hours'}
            disabled={disabled}
        />
    )
}