import './EventsHistory.css';

import Table, { TablePaginationConfig } from 'antd/lib/table';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';

import { makeSorter } from '../../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../../components/CustomDataTable/toTablePagination';
import { DatetimePopover } from '../../../components/DatetimePopover/DatetimePopover';
import { EventSourceTag } from '../../../components/EventSourceTag/EventSourceTag';
import { LocationAddress } from '../../../components/LocationAddress/LocationAddress';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ProgramSelector } from '../../../components/ProgramSelector/ProgramSelector';
import { ProgramTag } from '../../../components/ProgramTag/ProgramTag';
import { Search } from '../../../components/Search/Search';
import { IEvent } from '../../../domain/events/Event';
import { useEventListQuery } from '../../../domain/events/queries';
import { IPageState, usePageState } from '../../../domain/shared/usePageState';
import { EventDeliveryStatus } from '../EventDeliveryStatus/EventDeliveryStatus';
import { EventsDateRangeSelector, getCurrentMonthDateRange } from '../EventsDateRangeSelector';
import { EventsViewSwitch } from '../EventsViewSwitch';

export const EventsHistory = () => {
    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});

    let eventsListQueryParams = {...pageState, include: 'site,org,delivery-status'};
    if (!eventsListQueryParams.filter?.start || !eventsListQueryParams.filter?.end) {
        const {start, end} = getCurrentMonthDateRange();
        eventsListQueryParams = merge(eventsListQueryParams, {filter: {start, end}})
    }

    const {data, isLoading} = useEventListQuery(eventsListQueryParams);

    useEffect(() => {
        document.title = 'Events History | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    function getDateRange(pageState: IPageState) {
        if (pageState.filter?.start && pageState.filter?.end) {
            return {start: pageState.filter?.start, end: pageState.filter?.end}
        }

        return getCurrentMonthDateRange();
    }

    function onDateRangeChange(value: {start: Date; end: Date}) {
        console.info('onDateRangeChange', value);
        setPageState(merge(pageState, {filter: {start: value.start, end: value.end}, pagination: {page: 1}}));
    }

    function onProgramChange(value: string | null) {
        const programId = value || null;
        setPageState(merge(pageState, {filter: {programId: programId}, pagination: {page: 1}}));
    }

    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IEvent> | SorterResult<IEvent>[], extra: any) {
        const tableState = tableParamsToPageState<IEvent>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
    }

    const columns: ColumnsType<IEvent> = [
        {
            title: 'Org',
            width: 100,
            render: (text, record) => record?.site?.org?.title!
        },
        {
            title: 'Site',
            width: 100,
            render: (text, record) => record.site?.title
        },
        {
            title: 'Address',
            width: 200,
            render: (text, record) => <LocationAddress location={record.site?.location!} />
        },
        {
            title: 'Programs',
            width: 150,
            render: (text, record) => {
                const components: any[] = [];
                record?.site?.programs?.forEach((p, i) => {
                    components.push(<ProgramTag program={p} key={`program-tag-${p.id}`} />)
                })

                return components
            }
        },
        {
            title: 'Source',
            width: 150,
            render: (text, record) => <EventSourceTag source={record.source} />
        },
        {
            title: 'Start',
            width: 100,
            render: (text, record) => <DatetimePopover timestamp={new Date(record.start).valueOf()} siteTimezone={record?.site?.location?.timezone} />
        },
        {
            title: 'Duration (minutes)',
            width: 85,
            render: (text, record) => record.durationMinutes
        },
        {
            title: 'Delivery Status',
            sorter: false,
            width: 200,
            render: (text, record) => <EventDeliveryStatus event={record} />
        }
    ];

    return (
        <div className='page-events-history'>
            <PageHeader
                pageTitle='Events'
                extra={[
                    <EventsViewSwitch view='history' key='events-view-switch' />
                ]}
                actions={[
                    <EventsDateRangeSelector onChange={onDateRangeChange} value={getDateRange(pageState)} key='events-date-range-selector' />,
                    <ProgramSelector programId={pageState.filter?.programId} onChange={onProgramChange} key='events-program-selector'/>,
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='events-search' />
                ]}
            />
            <div className='events-history'>
                <Table
                    size='middle'
                    rowKey='id'
                    sticky
                    columns={columns}
                    dataSource={tableData.data}
                    loading={isLoading}
                    pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                    onChange={onTableChange}
                />
            </div>
        </div>
    )
}