import './EventForm.css';

import { Alert, Descriptions, Divider, Space } from 'antd';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Row from 'antd/lib/row';
import Select from 'antd/lib/select';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import add from 'date-fns/add';
import isAfter from 'date-fns/isAfter';
import { useState } from 'react';

import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';

import DatePicker from '../../../components/DatePicker';
import { EventSourceTag } from '../../../components/EventSourceTag/EventSourceTag';
import { HoursInput } from '../../../components/HoursInput/HoursInput';
import {
    IEvent, PeakSaverEventSource, PriceResponseEventSource, SIGNAL_LEVEL_OPTIONS, SignalType,
    UserEventSource
} from '../../../domain/events/Event';
import { IEventForm } from '../../../domain/events/EventForm';
import {
    useEventBatchCancelQuery, useEventBatchUpdateQuery, useEventCancelQuery, useEventCreateQuery,
    useEventUpdateQuery
} from '../../../domain/events/queries';
import { EventResourcesList } from '../EventResourcesList/EventResourcesList';
import { ICalendarResource } from '../EventsCalendar/CalendarResource';
import { CancelEventButton } from './CancelEventButton';
import { UpdateEventButton } from './UpdateEventButton';

interface IEventFormProps {
    resources: ICalendarResource[];
    event: IEventForm;
    onClose: (event: IEventForm | null) => any;
}

export function EventForm({ resources, event, onClose }: IEventFormProps) {
    const [form] = Form.useForm<IEventForm>();
    const [loading, setLoading] = useState<boolean>(false);

    console.info(`form event=`, event);

    const eventCancelQuery = useEventCancelQuery();
    const eventBatchCancelQuery = useEventBatchCancelQuery();
    const eventCreateQuery = useEventCreateQuery();
    const eventUpdateQuery = useEventUpdateQuery();
    const eventBatchUpdateQuery = useEventBatchUpdateQuery();

    const isNew = event.id === null;
    const isCompleted = (!isNew && isAfter(new Date(), add(event.start!, {minutes: event.durationMinutes})));
    const isCanceled = (!isNew && event.canceled);

    async function onFormFinish(event: IEventForm) {
        console.info(`onFormFinish=`, event);
        try {
            setLoading(true);
            const sites = resources.map(r => r.id);
            await eventCreateQuery.mutateAsync({sites, event});

            notification.info({ key: 'event-save-info', message: 'Event saved' });
            onClose(event);
        } catch (err: any) {
            notification.error({ key: 'event-save-error', message: err.message || 'Cannot save event!' });
        }
        setLoading(false);
    }

    async function onCancelHandler(batch?: string) {
        const event = form.getFieldsValue(true);
        if (batch) {
            try {
                await eventBatchCancelQuery.mutateAsync(event.batch);
                onClose(event);
            } catch (err: any) {
                notification.error({ key: 'event-batch-cancel-error', message: err.message || 'Cannot cancel events!' });
            }
            return;
        }

        try {
            await eventCancelQuery.mutateAsync(event.id);
            onClose(event);
        } catch (err: any) {
            notification.error({ key: 'event-cancel-error', message: err.message || 'Cannot cancel event!' });
        }
    }

    async function onUpdateHandler(batch?: string) {
        const event = form.getFieldsValue(true);
        if (batch) {
            try {
                await eventBatchUpdateQuery.mutateAsync(event);
                onClose(event);
            } catch (err: any) {
                notification.error({ key: 'event-batch-update-error', message: err.message || 'Cannot update events!' });
            }
            return;
        }

        try {
            await eventUpdateQuery.mutateAsync(event);
            onClose(event);
        } catch (err: any) {
            notification.error({ key: 'event-update-error', message: err.message || 'Cannot update event!' });
        }
    }

    const createTitle = () => {
        return (resources.length > 1) ? `Create ${resources.length} events` : `Create event`;
    }

    return (
        <Modal
            title={isNew ? 'Create events' : 'Edit event'}
            className='event-form'
            width={650}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={
            <Space>
                <Button onClick={() => onClose(null)} key='event-modal-cancel' disabled={loading}>Close</Button>
                {isNew 
                    ? ''
                    : <CancelEventButton key='event-modal-delete' event={event} onClick={onCancelHandler} disabled={isCanceled || isCompleted} />
                }
                {isNew 
                    ? <Button onClick={form.submit} key='event-modal-submit' type='primary' loading={loading}>{createTitle()}</Button>
                    : <UpdateEventButton key='event-modal-update' event={event} onClick={onUpdateHandler} disabled={isCanceled || isCompleted} />
                }
            </Space>
            }
        >
            <Space direction='vertical' style={{ display: 'flex' }}>
                {isCompleted &&
                    <Alert message="Completed event cannot be updated" type="info" showIcon />
                }
                {isCanceled &&
                    <Alert message="Canceled event cannot be updated" type="info" showIcon />
                }
                {!isNew && event.source && 
                    <Descriptions bordered size='small'>
                        <Descriptions.Item label="Event source:"><EventSourceTag source={event.source} /></Descriptions.Item>
                    </Descriptions>
                }
                <EventResourcesList resources={resources} />
            </Space>
            <Divider />
            <Form
                form={form}
                name='event-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={event}
            >
                <Form.Item name='id' hidden><Input /></Form.Item>
                <Row gutter={8}>
                    <Col span={6}>
                        <Form.Item
                            name='preDurationMinutes'
                            label={<Typography.Text strong>Pre duration</Typography.Text>}
                            hasFeedback
                        >
                            <HoursInput minutes={event.preDurationMinutes} onChange={() => {}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="startLocal"
                            label={<Typography.Text strong>Start</Typography.Text>}
                            tooltip={
                                <span>It is a local time for each site</span>
                            }
                            required
                            rules={[{ required: true, message: 'Please select date!' }]}
                        >
                            <DatePicker format='YYYY-MM-DD HH:mm' showTime={{ format: 'HH:mm' }} size='large' style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            name='durationMinutes'
                            label={<Typography.Text strong>Duration</Typography.Text>}
                            hasFeedback
                        >
                            <HoursInput minutes={event.durationMinutes} onChange={() => {}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    label={<Typography.Text strong>OADR Signal Level</Typography.Text>}
                    name="signal"
                >
                    <Select placeholder="Please select signal level" options={SIGNAL_LEVEL_OPTIONS} size='large' />
                </Form.Item>
                <Form.Item
                    name="test"
                    label={<Typography.Text strong>Test Event</Typography.Text>}
                    valuePropName="checked"
                >
                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export class EventFormData implements IEventForm {
    constructor(
        public id: string | null, 
        public start: Date | null, 
        public startLocal: Date | null, 
        public preDurationMinutes: number, 
        public durationMinutes: number,
        public signal: SignalType, 
        public test: boolean, 
        public canceled: boolean, 
        public source: UserEventSource | PriceResponseEventSource | PeakSaverEventSource | null, 
        public batch: string | null, 
    ) {}

    static fromEvent(event: IEvent) {
        return new EventFormData(
            event.id,
            new Date(event.start),
            new Date(event.startLocal),
            event.preDurationMinutes,
            event.durationMinutes, 
            event.signal,
            event.test,
            event.cancelled,
            event.source,
            event.batch
        );
    }

    static fromDefault() {
        return new EventFormData(
            null,
            null,
            null,
            0,
            60,
            SignalType.DEFAULT,
            false,
            false,
            null,
            null
        );
    }
}