import Col from 'antd/lib/col';
import Empty from 'antd/lib/empty';
import Row from 'antd/lib/row';
import { Card, Tooltip } from 'antd';
import { useWeatherForecastQuery } from './queries';
import { forecastDrEventChance } from './DREventChance';
import { IEvent } from '../../domain/events/Event';
import './WeatherCard.css';
import './icons.css';

export interface IWeatherCardProps {
    location: {lat: number, long: number};
    events: IEvent[] | undefined;
}

export const WeatherCard = ({location, events}: IWeatherCardProps) => {

    const {data, isLoading} = useWeatherForecastQuery(location);

    const forecast = data ? forecastDrEventChance(data, events) : undefined;

    if (isLoading === false && !forecast) {
        return (
            <div className='weather-card weather-card-no-data'>
                <Empty
                    description={'No weather for this place'}
                    imageStyle={{
                        height: '60px'
                    }}
                />
            </div>
        )
    }

    return (
        <Card loading={isLoading} bordered={false} className='weather-card'>
            <Row gutter={8}>
                {forecast && forecast.map((day) => (
                    <Col className={`weather-card-day dr-chance-${day.drChance.code}`} key={day.dayName}>
                        <div >
                            <h2>{day.dayName}</h2>
                            <h1>{day.temperature}°</h1>
                            <div className='content'>
                                <span className='icon-wrap icon'>
                                    <i className={`wi ${day.icon}`}></i>
                                </span>
                                <Tooltip title={day.drChance.message}>
                                    <div className='description'>{day.description}</div>
                                </Tooltip>
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
        </Card>
    )
}

