import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isBefore from 'date-fns/isBefore';
import isAfter from 'date-fns/isAfter';
import add from 'date-fns/add';
import { IEvent } from './Event';

export function isEventInThisDay(event: IEvent, day: Date): boolean {
    const eventStart = new Date(event.start);
    const eventEnd = add(new Date(event.start), {minutes: event.durationMinutes});

    const todayStart = startOfDay(day);
    const todayEnd = endOfDay(day);
    return isBefore(eventStart, todayEnd) && isAfter(eventEnd, todayStart);

}