export type TwoFactorRequiredStep = { type: '2fa', tokenId: string };
export type CaptchaRequiredStep = { type: 'captcha' };

export type RequiredStep = TwoFactorRequiredStep | CaptchaRequiredStep;

export class RequiredStepsError extends Error {
    steps: RequiredStep[] = [];

    constructor(steps: RequiredStep[]) {
        super();
        this.name = 'RequiredStepsError';
        this.steps = steps;
    }
}