import { Tag } from 'antd';

import { EventSource, EventSourceType } from '../../domain/events/Event';
import { IProgram } from '../../domain/programs/Program';
import { PeakSaverTag } from '../PeakSaverTag/PeakSaverTag';
import { PriceResponseTag } from '../PriceResponseTag/PriceResponseTag';
import { ProgramTag } from '../ProgramTag/ProgramTag';

export interface IEventSourceTagProps {
    source: EventSource;
}

export const EventSourceTag = ({ source }: IEventSourceTagProps) => {

    if (source?.type === EventSourceType.USER && source.program) {
        const program = { id: null, title: source.program } as any as IProgram;
        return <ProgramTag program={program} />
    }

    if (source?.type === EventSourceType.PRICE_RESPONSE) {
        return <PriceResponseTag />
    }

    if (source?.type === EventSourceType.PEAK_SAVER) {
        return <PeakSaverTag />
    }

    return <Tag color='orange'>API</Tag>
}