import Collapse from 'antd/lib/collapse';
import List from 'antd/lib/list';
import Typography from 'antd/lib/typography';

import CaretRightOutlined from '@ant-design/icons/CaretRightOutlined';

import { ICalendarResource } from '../EventsCalendar/CalendarResource';

export interface IEventResourcesListProps {
    resources: ICalendarResource[];
}

export function EventResourcesList({resources}: IEventResourcesListProps) {

    const renderItem = (resource: ICalendarResource) => {
        return (
        <List.Item key={`event-resource-${resource.id}`}>
            <Typography.Text ellipsis>{`${resource.org} / ${resource.site}`}</Typography.Text>
        </List.Item>
        )
    }
    return (
        <Collapse
            // accordion
            className='event-resources-list'
            bordered={false}
            defaultActiveKey={[]}
            expandIconPosition='end'
            expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
            <Collapse.Panel header={<div>Selected {resources.length} site{resources.length > 1 && 's'}</div>} key="">
                <List
                    style={{
                        maxHeight: '400px',
                        overflow: 'auto'
                    }}
                    size="small"
                    bordered={false}
                    dataSource={resources}
                    renderItem={item => renderItem(item)}
                />
            </Collapse.Panel>
        </Collapse>
    )
}