import { Modal, notification, Tag, Tooltip } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import {
    ClearPageFilterButton
} from '../../components/ClearPageFilterButton/ClearPageFilterButton';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { DatetimePopover } from '../../components/DatetimePopover/DatetimePopover';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { useOrg } from '../../components/OrgContext/OrgContext';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Search } from '../../components/Search/Search';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { TwoFactorIcon } from '../../components/TwoFactorIcon';
import { useAuth } from '../../domain/auth/useAuth';
import { IPageState, usePageState } from '../../domain/shared/usePageState';
import {
    exportUsers, useUserBulkDeleteQuery, useUserDeleteQuery, useUserListQuery
} from '../../domain/users/queries';
import { IUser, IUserForm, Role } from '../../domain/users/User';
import { UserBulkForm } from './UserBulkForm';
import { UserForm, UserFormData } from './UserForm';

export const Users = () => {
    const {actor} = useAuth();
    const {orgId} = useOrg();
    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [user, setUser] = useState<IUserForm | null>(null);
    const [bulkFormVisible, setBulkFormVisible] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    
    const {data, isError, error, isLoading} = useUserListQuery({...pageState, include: 'org'});
    const userDeleteQuery = useUserDeleteQuery();
    const userBulkDeleteQuery = useUserBulkDeleteQuery();

    useEffect(() => {
        document.title = 'Users | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    if (isError) {
        notification.error({
            key: 'fetch-user-list-error',
            message: (error as Error)?.message || 'Cannot fetch users!'
        });
    }

    function handleSearch(value: string) {
        const state = {...pageState};
        if (!state.filter) state.filter = {};
        state.filter.search = value;

        setPageState({...state});
        setSelectedRowKeys([]);
    }
    
    function onUserFormClose() {
        setUser(null);
    }

    function showNewUserForm() {
        setUser(UserFormData.fromDefault(orgId));
    }
    
    function showUserForm(user: IUser) {
        setUser(UserFormData.fromUser(user));
    }

    function onUserBulkFormClose() {
        setBulkFormVisible(false);
        setSelectedRowKeys([]);
    }

    function deleteUserWithConfirmation(user: IUser) {
        Modal.confirm({
            title: 'DELETE USER',
            icon: <ExclamationCircleOutlined />,
            content: `User \n"${user.email || ''}" will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await userDeleteQuery.mutateAsync(user);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function bulkDeleteUserWithConfirmation(ids: any[]) {
        console.info('bulkDeleteUserWithConfirmation Ids', ids);
        Modal.confirm({
            title: 'DELETE USERS',
            icon: <ExclamationCircleOutlined />,
            content: `${ids.length} users will be deleted. Are you sure?`,
            okText: ids.length === 1 ? `DELETE USER` : `DELETE ${ids.length} USERS`,
            async onOk() {
                await userBulkDeleteQuery.mutateAsync(selectedRowKeys as string[]);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function showUserBulkForm(ids: any[]) {
        setBulkFormVisible(true);
    }

    function onSelectChange(newSelectedRowKeys: React.Key[]) {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IUser> | SorterResult<IUser>[], extra: any) {
        const tableState = tableParamsToPageState<IUser>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });

        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'User')) {
        actions.push({title: 'Edit', onClick: (record: IUser) => showUserForm(record)})
    }

    if (actor.can('delete', 'User')) {
        actions.push({title: 'Delete', onClick: (record: IUser) => deleteUserWithConfirmation(record)})
    }

    const roleColors: {[role: string]: string} = {
        [Role.ADMIN]: 'red',
        [Role.ACCOUNT_MANAGER]: 'volcano',
        [Role.OPERATOR]: 'cyan',
        [Role.CUSTOMER]: 'blue',
        [Role.OPERATOR]: 'geekblue',
    };

    const makeRoleFilter = (filter: IPageState['filter']) => {
        if (actor.role === Role.OPERATOR) return {};
        if (actor.role === Role.CUSTOMER) return {};
        if (actor.role === Role.PROVIDER) return {};
        
        let ROLES: any[] = [];
        
        if (actor.role === Role.ACCOUNT_MANAGER) {
            ROLES = [
                { text: 'Provider', value: Role.PROVIDER },
                { text: 'Customer', value: Role.CUSTOMER },
                { text: 'Operator', value: Role.OPERATOR },
            ];
        }

        if (actor.role === Role.ADMIN) {
            ROLES = [
                { text: 'Admin', value: Role.ADMIN },
                { text: 'Account Manager', value: Role.ACCOUNT_MANAGER },
                { text: 'Provider', value: Role.PROVIDER },
                { text: 'Customer', value: Role.CUSTOMER },
                { text: 'Operator', value: Role.OPERATOR },
            ];
        }

        return {
            filterMultiple: false,
            filters: ROLES,
            filteredValue: filter?.role ? [filter?.role] : null,
        }
    }

    const columns: ColumnsType<IUser> = [
        {
            title: 'Org',
            dataIndex: 'orgId',
            render: (text, record) => record?.org?.title
        },
        {
            title: 'Full Name',
            dataIndex: 'fullname',
            ...makeSorter(pageState.sort, 'fullname'),
            render: (text, record) => text
        },
        {
            title: 'Email',
            dataIndex: 'email',
            ...makeSorter(pageState.sort, 'email'),
            render: (text, record) => text
        },
        {
            title: 'Phone',
            dataIndex: 'phone',
            ...makeSorter(pageState.sort, 'phone'),
            render: (text, record) => text
        },
        {
            title: '',
            width: 50,
            render: (text, record) => record.twoFactor.enabled && <Tooltip title='Two Factor Authentication enabled'><strong>2FA</strong></Tooltip>
        },
        {
            title: 'Role',
            dataIndex: 'role',
            ...makeRoleFilter(pageState.filter),
            render: (text, record) => {
                return (
                    <Tag color={roleColors[record.role]} key={`role-${record.role}`}>
                        {record.role.toUpperCase()}
                    </Tag>
                );
            }
        },
        {
            title: 'Last Login',
            width: '180px',
            dataIndex: 'lastLoggedAt',
            ...makeSorter(pageState.sort, 'lastLoggedAt'),
            render: (text, record) => {
                if (!record.lastLoggedAt) return <></>;
                return <DatetimePopover timestamp={new Date(record.lastLoggedAt).valueOf()} />
            }
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    const rowSelection: TableRowSelection<IUser> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    return (
        <>
            <PageHeader
                pageTitle={<>Users <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    <ClearPageFilterButton key='clear-page-filter' />,
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='user-search' />,
                    selectedRowKeys.length > 0 && <Button onClick={() => showUserBulkForm(selectedRowKeys)} size='large' key='user-bulk-edit' type='default' icon={<EditOutlined />}>Edit Selected</Button>,
                    selectedRowKeys.length > 0 && <Button onClick={() => bulkDeleteUserWithConfirmation(selectedRowKeys)} size='large' key='user-bulk-remove' type='primary' icon={<DeleteOutlined />}>Delete Selected</Button>,
                    <ExportButton fn={() => exportUsers({include: 'org'})} key='user-export'>Export</ExportButton>,
                    actor.can('create', 'User') && <Button size='large' key='new-user' type='primary' onClick={showNewUserForm} icon={<PlusCircleOutlined />}>New User</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                rowSelection={rowSelection}
                onChange={onTableChange}
            />
            }
            {user && <UserForm user={user} onClose={onUserFormClose}></UserForm>}
            {bulkFormVisible && <UserBulkForm ids={selectedRowKeys as string[]} onClose={onUserBulkFormClose}></UserBulkForm>}
        </>
    )
}