import { ClustererOptions, ClusterIconStyle } from '@react-google-maps/marker-clusterer';
import clusterGreenImg from './images/cluster_green.png';
import clusterYellowImg from './images/cluster_yellow.png';
import clusterRedImg from './images/cluster_red.png';
import clusterPurpleImg from './images/cluster_purple.png';
import clusterOrangeImg from './images/cluster_orange.png';
import clusterLightBlueImg from './images/cluster_light_blue.png';
import clusterDarkRedImg from './images/cluster_dark_red.png';
import clusterDarkBlueImg from './images/cluster_dark_blue.png';
import { ISiteWithPrices } from '../../../domain/sites/Site';
import { colorByPrice, styleIndexByPrice } from './priceRange';

const markerClustererCommonStyle = {
    height: 30,
    width: 30,
    textColor: 'white',
};

export const markerClustererStyles: ClusterIconStyle[] = [
    {
        url: clusterPurpleImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterDarkBlueImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterLightBlueImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterGreenImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterYellowImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterOrangeImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterRedImg,
        ...markerClustererCommonStyle,
    },
    {
        url: clusterDarkRedImg,
        ...markerClustererCommonStyle,
    },
];

export const markerClustererOptions: ClustererOptions = {
    gridSize: 90,
    styles: markerClustererStyles,
    maxZoom: 15,
    zoomOnClick: true,
    minimumClusterSize: 5,
    averageCenter: true,
}

export const calculateMarkerStyle = (price: number) => {
    return colorByPrice(price);
};

const calculateClusterStyle = (price: number) => {
    return styleIndexByPrice(price) || 1;
};

export function markerClustererCalculator(markers: any[], priceMap: Map<string, number>) {
    // console.info('markerClustererCalculator', markers, priceMap);
    if (!markers.length) {
        return {
            index: 1,
            text: '0',
            title: '',
        };
    }

    const totalPrice = markers.reduce((prevValue: number, curItem: any) => {
        const key = `${curItem.title}:${curItem.position.lat()}:${curItem.position.lng()}`
        const price = priceMap.get(key) || 0;
        return prevValue + price;
    }, 0);

    const avg = totalPrice / markers.length;
    const clusterStyle = calculateClusterStyle(avg);

    return {
        index: clusterStyle,
        text: `${markers.length}`,
        title: '',
    };
};


export function makePriceMap(sites: ISiteWithPrices[]): Map<string, number> {
    const priceMap = new Map<string, number>();
    
    sites.forEach(site => priceMap.set(`${site.title}:${site.location.lat}:${site.location.long}`, site.prices?.realtime || 0));

    return priceMap;
}