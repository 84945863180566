import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';

import FilterOutlined from '@ant-design/icons/lib/icons/FilterOutlined';

import { usePageState } from '../../domain/shared/usePageState';

export const ClearPageFilterButton = () => {
    const {pageState, setPageState} = usePageState();

    function clearPageFilter() {
        /** remove everything in filter, except orgId */
        const filter = pageState.filter?.orgId 
            ? {orgId: pageState.filter?.orgId}
            : undefined;
        setPageState({...pageState, filter});
    }

    function hasFilter() {
        const filter = {...pageState.filter};
        return Object.keys(filter).some((key)=> key !== 'orgId');
    }
    
    if (!hasFilter()) return <></>

    return (
        <Tooltip title={'Reset Filter'}>
            <Button type='ghost' size='large' icon={<FilterOutlined />} onClick={clearPageFilter} />
        </Tooltip>
    )
}