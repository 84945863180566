import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography/Typography';

import DollarOutlined from '@ant-design/icons/DollarOutlined';

export const PriceResponseTag = () => {
    return (
        <Typography className='ellipsis' key='price-response-tag'>
            <Tag color={'pink'} icon={<DollarOutlined />}>Price Response</Tag>
        </Typography>
    )
}