import Input from 'antd/lib/input';
import React, { MutableRefObject, useRef, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

import { config } from '../../config';
import { ILocation, parseGooglePlace } from '../../domain/sites/Location';

interface ILocationInputProps {
    location: ILocation | null;
    onChange: (location: ILocation | null) => void;
}

export const LocationInput = (props: ILocationInputProps) => {
    const [location, setLocation] = useState<Partial<ILocation> | null>(props.location);

    const antInputRef: MutableRefObject<any> = useRef(null);
    const { ref: antRef }: any = usePlacesWidget({
        apiKey: config.GOOGLE_MAP_API,
        language: 'en',
        options: {
            types: ['establishment', 'geocode']
        },
        onPlaceSelected: async (place: google.maps.places.PlaceResult) => {
            console.info('google place: ', place);

            const location = await parseGooglePlace(place);
            console.info('location: ', location);
            setLocation(location);

            props.onChange(location);
        },
    });

    function onChange(value: React.ChangeEvent<HTMLInputElement>) {
        // console.info(`LocationInput.onChange`, value.target.value);
        setLocation({address: value.target.value});
    }
    
    return (
        <Input
            value={location?.address}
            placeholder='Address'
            size='large'
            onChange={onChange}
            ref={(c) => {
                antInputRef.current = c;
                if (c) antRef.current = c.input;
            }}
        />
    )
}