import { Modal, notification } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';

import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import {
    ClearPageFilterButton
} from '../../components/ClearPageFilterButton/ClearPageFilterButton';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Search } from '../../components/Search/Search';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { WarningIcon } from '../../components/WarningIcon/WarningIcon';
import { useAuth } from '../../domain/auth/useAuth';
import { IOrg, IOrgForm, OrgType } from '../../domain/orgs/Org';
import { exportOrgs, useOrgDeleteQuery, useOrgListQuery } from '../../domain/orgs/queries';
import { IPageState, usePageState } from '../../domain/shared/usePageState';
import { OrgForm, OrgFormData } from './OrgForm';

export const Orgs = () => {
    const {actor} = useAuth();

    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});

    const [org, setOrg] = useState<IOrgForm | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        document.title = 'Organizations | Daydream Energy';
    }, []);

    const {data, isError, error, isLoading} = useOrgListQuery({...pageState, include: 'sites-count'});
    const deleteOrgQuery = useOrgDeleteQuery();

    if (isError) {
        notification.error({
            key: 'fetch-org-list-error',
            message: (error as Error)?.message || 'Cannot fetch orgs!'
        });
    }

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
        setSelectedRowKeys([]);
    }

    function onOrgFormClose() {
        setOrg(null);
    }

    function showNewOrgForm() {
        setOrg(OrgFormData.fromDefault());
    }

    function showOrgForm(org: IOrg) {
        setOrg(OrgFormData.fromOrg(org));
    }

    function deleteOrgWithConfirmation(org: IOrg) {
        Modal.confirm({
            title: 'DELETE ORG',
            icon: <ExclamationCircleOutlined />,
            content: `Organization \n"${org.title || ''}" and its all related data will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await deleteOrgQuery.mutateAsync(org);
            },
            onCancel() {},
        });
    }

    // function onSelectChange(newSelectedRowKeys: React.Key[]) {
    //     setSelectedRowKeys(newSelectedRowKeys);
    // }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IOrg> | SorterResult<IOrg>[], extra: TableCurrentDataSource<IOrg>) {
        const tableState = tableParamsToPageState<IOrg>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'Org')) {
        actions.push({title: 'Edit', onClick: (record: IOrg) => showOrgForm(record)})
    }

    if (actor.can('delete', 'Org')) {
        actions.push({title: 'Delete', onClick: (record: IOrg) => deleteOrgWithConfirmation(record)})
    }

    const makeOrgTypeFilter = (filter: IPageState['filter']) => {
        const ORG_TYPES = [
            { text: 'Generic', value: OrgType.GENERIC },
            { text: 'Control Provider', value: OrgType.PROVIDER },
        ];

        return {
            filterMultiple: false,
            filters: ORG_TYPES,
            filteredValue: filter?.type ? [filter?.type] : null,
        }
    }

    const columns: ColumnsType<IOrg> = [
        {
            title: 'Title',
            dataIndex: 'title',
            ...makeSorter<IOrg>(pageState.sort, 'title'),
            render: (text, record) => {
                if (!record.emerson?.error) return <span>{record.title}</span>;
                const warningMessage = `Emerson Integration Error. ${record.emerson?.error}`;
                return <span>{record.title} <WarningIcon title={warningMessage} /></span>
            }
        },
        {
            title: 'Type',
            dataIndex: 'type',
            width: 150,
            ...makeOrgTypeFilter(pageState.filter),
            render: (text) => text.toLowerCase()
        },
        {
            title: 'Sites',
            dataIndex: 'sitesCount',
            width: 120,
            align: 'right',
            render: (text) => text
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    // const rowSelection: TableRowSelection<IOrg> = {
    //     selectedRowKeys,
    //     onChange: onSelectChange,
    //     selections: [
    //         Table.SELECTION_ALL,
    //         Table.SELECTION_INVERT,
    //         Table.SELECTION_NONE,
    //     ],
    // };
    
    return (
        <>
            <PageHeader
                pageTitle={<>Organizations <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    <ClearPageFilterButton />,
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='org-search' />,
                    <ExportButton fn={() => exportOrgs({pagination: {page: 1, size: 1000}})} key='org-export'>Export</ExportButton>,
                    actor.can('create', 'Org') && <Button size='large' key='new-org' type='primary' onClick={showNewOrgForm} icon={<PlusCircleOutlined />}>New Org</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                // rowSelection={rowSelection}
                onChange={onTableChange}
            />
            }
            {org && <OrgForm org={org} onClose={onOrgFormClose}></OrgForm>}
        </>
    )
}