import { useState } from 'react';
import Input from 'antd/lib/input'
import Tooltip from 'antd/lib/tooltip'
import isEmpty from 'lodash/isEmpty'

interface ISearchProps {
    defaultValue?: string;
    onSearch: (value: string) => void;
}

const MIN_ALLOWED_SEARCH_LENGTH = 3;

export const Search = ({ defaultValue = '', onSearch }: ISearchProps) => {
    const [showTooltip, setShowTooltip] = useState(false);

    const handleSearch = (value: string, event: any) => {
        value = value.trim()
        const isSearchEmpty = isEmpty(value);
        const isSearchLengthEnough = value.length >= MIN_ALLOWED_SEARCH_LENGTH;
    
        if (isSearchEmpty || isSearchLengthEnough) {
            onSearch(value);
        }
        event.target.blur();
    }

    return (
        <Tooltip
            title='Search should be at least 3 char'
            visible={showTooltip && defaultValue.length < 3}
        >
            <Input.Search
                allowClear
                defaultValue={defaultValue}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false)}
                size='large'
                style={{ width: '200px' }}
                onSearch={handleSearch}
            />
        </Tooltip>
    )
};