import { Modal, notification } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';

import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Search } from '../../components/Search/Search';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { useAuth } from '../../domain/auth/useAuth';
import { usePageState } from '../../domain/shared/usePageState';
import {
    exportUtilities, useUtilityDeleteQuery, useUtilityListQuery
} from '../../domain/utilities/queries';
import { IUtility, IUtilityForm } from '../../domain/utilities/Utility';
import { UtilityForm, UtilityFormData } from './UtilityForm';

export const Utilities = () => {
    const {actor} = useAuth();

    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [utility, setUtility] = useState<IUtilityForm | null>(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    
    const {data, isError, error, isLoading} = useUtilityListQuery({...pageState});
    const utilityDeleteQuery = useUtilityDeleteQuery();

    if (isError) {
        notification.error({
            key: 'fetch-utility-list-error',
            message: (error as Error)?.message || 'Cannot fetch utilities!'
        });
    }
    
    useEffect(() => {
        document.title = 'Utilities | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);


    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
        setSelectedRowKeys([]);
    }

    function onUtilityFormClose() {
        setUtility(null);
    }

    function showNewUtilityForm() {
        setUtility(UtilityFormData.fromDefault());
    }
    
    function showUtilityForm(utility: IUtility) {
        setUtility(UtilityFormData.fromUtility(utility));
    }
    
    function deleteUtilityWithConfirmation(program: IUtility) {
        Modal.confirm({
            title: 'DELETE UTILITY',
            icon: <ExclamationCircleOutlined />,
            content: `Utility \n"${program.title || ''}" will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await utilityDeleteQuery.mutateAsync(program);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function onSelectChange(newSelectedRowKeys: React.Key[]) {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IUtility> | SorterResult<IUtility>[], extra: any) {
        const tableState = tableParamsToPageState<IUtility>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'Utility')) {
        actions.push({title: 'Edit', onClick: (record: IUtility) => showUtilityForm(record)})
    }

    if (actor.can('delete', 'Utility')) {
        actions.push({title: 'Delete', onClick: (record: IUtility) => deleteUtilityWithConfirmation(record)})
    }

    const columns: ColumnsType<IUtility> = [
        {
            title: 'Title',
            dataIndex: 'title',
            ellipsis: true,
            ...makeSorter(pageState.sort, 'title'),
            render: (text, record) => text
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    const rowSelection: TableRowSelection<IUtility> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };
    
    return (
        <>
            <PageHeader
                pageTitle={<>Utilities <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='utility-search' />,
                    <ExportButton fn={() => exportUtilities({pagination: {page: 1, size: 1000}})} key='utility-export'>Export</ExportButton>,
                    actor.can('create', 'Utility') && <Button size='large' key='new-utility' type='primary' onClick={showNewUtilityForm} icon={<PlusCircleOutlined />}>New Utility</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                // rowSelection={rowSelection}
                onChange={onTableChange}
            />
            }
            {utility && <UtilityForm utility={utility} onClose={onUtilityFormClose} />}
        </>
    )
}