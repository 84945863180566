import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { config } from '../../config';

export interface ICaptchaStepProps {
    onClose: () => void;
    onComplete: (args: any) => void;
}

export function CaptchaStep(props: ICaptchaStepProps) {
    const [captcha, setCaptcha] = useState<string | null>(null);
    const recaptchaRef = useRef<ReCAPTCHA>(null);

    // useEffect(() => {
    //     recaptchaRef.current?.reset();
    // }, []);

    function onChange(value: string | null) {
        console.info('CaptchaStep.onChange', value);
        setCaptcha(value);
        if (value) {
            props.onComplete({captcha: value});
            // recaptchaRef.current?.reset();
        }
    }

    function onExpired() {
        console.info('CaptchaStep.onExpired');
        setCaptcha(null);
        recaptchaRef.current?.reset();
    }

    function onErrored(arg?: any) {
        console.info('CaptchaStep.onErrored', arg);
        // setCaptcha(null);
        // recaptchaRef.current?.reset();
    }
    
    return (
        <ReCAPTCHA
            style={{width: '304px', margin: '0 auto'}}
            ref={recaptchaRef}
            sitekey={config.RECAPTCHA_SITE_KEY}
            onChange={onChange}
            onExpired={onExpired}
            onErrored={onErrored}
        />
    )
}