import format from 'date-fns/format';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IProgram, IProgramForm } from './Program';

export interface IProgramListQuery extends IListQuery {
    filter?: {
        search?: string;
    }
}

async function getPrograms(query: IProgramListQuery): Promise<WithPagination<IProgram>> {
    // const page = query?.pagination?.page ? query?.pagination.page :  1;
    // const pageSize = query?.pagination?.size ? query?.pagination.size : 20;
    // const offset = (page <= 1) ? 0 : (page - 1) * pageSize;

    // const searchParams = new URLSearchParams({
    //     offset: `${offset}`,
    //     limit: `${pageSize}`,
    //     sortField: query.sort?.field || 'createdAt',
    //     sortOrder: query.sort?.order || 'desc',
    //     ...(query.filter?.search && {search: query.filter.search}),
    // });
    const searchParams = makeURLSearchParams(query);

    return await API.fetch<WithPagination<IProgram>>(`/api/v1/programs?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createProgram(program: IProgramForm) {
    const data: any = {
        title: program.title,
        utility: program.utility
    };

    return await API.fetch<IProgram>(`/api/v1/programs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateProgram(program: IProgramForm) {
    const data: any = {
        title: program.title,
        utility: program.utility
    };

    return await API.fetch<IProgram>(`/api/v1/programs/${program.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveProgram(program: IProgramForm) {
    return (program.id) ? await updateProgram(program) : await createProgram(program);
}

async function deleteProgram(program: IProgram) {
    await API.fetch(`/api/v1/programs/${program.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function bulkDeletePrograms(ids: string[]) {
    await API.fetch(`/api/v1/programs/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            remove: true
        })
    });
}

export async function exportPrograms(query: IProgramListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv'
    });
    const result = await API.fetch<string>(`/api/v1/programs?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `programs--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function useProgramListQuery(query: IProgramListQuery) {
    return useQuery(['program', query], () => getPrograms(query), { keepPreviousData: false });
}

export function useProgramCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((program: IProgramForm) => createProgram(program), {
        onSuccess() {
            return queryClient.invalidateQueries(['program'])
        }
    });
}

export function useProgramUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((program: IProgramForm) => updateProgram(program), {
        onSuccess() {
            return queryClient.invalidateQueries(['program'])
        }
    });
}

export function useProgramSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((program: IProgramForm) => saveProgram(program), {
        onSuccess() {
            return queryClient.invalidateQueries(['program'])
        }
    });
}

export function useProgramDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((program: IProgram) => deleteProgram(program), {
        onSuccess() {
            queryClient.invalidateQueries(['program'])
        }
    });
}

export function useProgramBulkDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((ids: string[]) => bulkDeletePrograms(ids), {
        onSuccess() {
            return queryClient.invalidateQueries(['program'])
        }
    });
}