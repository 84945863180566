import './PageMenu.css';

import Menu from 'antd/lib/menu';
import { Link, useLocation } from 'react-router-dom';

import BankOutlined from '@ant-design/icons/BankOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import HomeOutlined from '@ant-design/icons/HomeOutlined';
import BulbOutlined from '@ant-design/icons/lib/icons/BulbOutlined';
import EnvironmentOutlined from '@ant-design/icons/lib/icons/EnvironmentOutlined';
import ProfileOutlined from '@ant-design/icons/ProfileOutlined';
import UserOutlined from '@ant-design/icons/UserOutlined';

import { useOrg } from '../../components/OrgContext/OrgContext';
import { useAuth } from '../../domain/auth/useAuth';

export const PageMenu = () => {
    const location = useLocation();
    const {orgId} = useOrg();
    const {actor} = useAuth();
    const orgQuery = orgId ? `?orgId=${orgId}` : '';

    const menuItems = [
        {key: '/', className: 'navigation-item', icon: <EnvironmentOutlined />, label: <Link to={`/${orgQuery}`}>Dashboard</Link>},

        ...(actor.can('read', 'Org') 
            ? [{key: '/orgs', className: 'navigation-item', icon: <BankOutlined />, label: <Link to={`/orgs`}>Organizations</Link>}] 
            : []),

        ...(actor.can('read', 'Site') 
            ? [{key: '/sites', className: 'navigation-item', icon: <HomeOutlined  />, label: <Link to={`/sites${orgQuery}`}>Sites</Link>}] 
            : []),

        ...(actor.can('create', 'Program') 
            ? [{key: '/programs', className: 'navigation-item', icon: <ProfileOutlined />, label: <Link to={`/programs`}>Programs</Link>}] 
            : []),
        
        ...(actor.can('create', 'Utility') 
            ? [{key: '/utilities', className: 'navigation-item', icon: <ProfileOutlined />, label: <Link to={`/utilities`}>Utilities</Link>}] 
            : []),

        ...(actor.can('create', 'User') 
            ? [{key: '/users', className: 'navigation-item', icon: <UserOutlined />, label: <Link to={`/users${orgQuery}`}>Users</Link>}] 
            : []),

        ...(actor.can('read', 'PriceResponseTrigger') 
            ? [{key: '/price-response', className: 'navigation-item', icon: <DollarOutlined />, label: <Link to={`/price-response${orgQuery}`}>Price Response</Link>}] 
            : []),

        ...(actor.can('read', 'Event') 
            ? [{key: '/events/calendar', className: 'navigation-item', icon: <BulbOutlined />, label: <Link to={`/events/calendar${orgQuery}`}>Events</Link>},] 
            : []),
    ];

    return (
        <Menu mode='horizontal' selectedKeys={[location.pathname]} className='page-menu' items={menuItems} />
    )
}