import { useQuery } from 'react-query';
import { config } from '../../config';

interface IOpenWeatherIcon {
    id: number;
    main: string;
    description: string;
    icon: string;
}

interface IOpenWeatherMinutely {
    dt: number;
    precipitation: number;
}

interface IOpenWeatherItem {
    dt: number;
    sunrise: number;
    sunset: number;
    temp: number;
    feels_like: number;
    pressure: number;
    humidity: number;
    dew_point: number;
    uvi: number;
    clouds: number;
    visibility: number;
    wind_speed: number;
    wind_deg: number;
    wind_gust: number;
    weather: IOpenWeatherIcon[];
    pop?: number;
}

interface IOpenWeatherItemDaily {
    dt: number;
    sunrise: number;
    sunset: number;
    moonrise: number;
    moonset: number;
    moon_phase: number;
    temp: {
        day: number;
        min: number;
        max: number;
        night: number;
        eve: number;
        morn: number;
    };
    feels_like: {
        day: number;
        night: number;
        eve: number;
        morn: number;
    };
    pressure: number;
    humidity: number;
    dew_point: number;
    wind_speed: number;
    wind_deg: number;
    wind_gust: number;
    weather: IOpenWeatherIcon[];
    clouds: number;
    pop: number;
    uvi: number;
}

interface IOpenWeatherResponse {
    lat: number;
    lon: number;
    timezone: string;
    timezone_offset: number;
    current: IOpenWeatherItem | null;
    minutely: IOpenWeatherMinutely[] | null;
    hourly: IOpenWeatherItem[] | null;
    daily: IOpenWeatherItemDaily[];
    alerts: IOpenWeatherAlert[] | null
}

interface IOpenWeatherAlert {
    sender_name: string;
    event: string;
    start: number;
    end: number;
    description: string;
    tags: string[];
}

export interface IWeatherForecastDaily {
    dayName: string;
    temperature: number; // celsius
    description: string;
    icon?: string;
};

const weatherIconMap = new Map([
    ['01d', 'wi-day-sunny'],
    ['02d', 'wi-day-cloudy'],
    ['03d', 'wi-cloud'],
    ['04d', 'wi-cloudy'],
    ['09d', 'wi-showers'],
    ['10d', 'wi-day-rain-mix'],
    ['11d', 'wi-thunderstorm'],
    ['13d', 'wi-snowflake-cold'],
    ['50d', 'wi-fog'],
    ['01n', 'wi-night-clear'],
    ['02n', 'wi-night-alt-cloudy'],
    ['03n', 'wi-cloud'],
    ['04n', 'wi-cloudy'],
    ['09n', 'wi-showers'],
    ['10n', 'wi-night-rain-mix'],
    ['11n', 'wi-thunderstorm'],
    ['13n', 'wi-snowflake-cold'],
    ['50n', 'wi-fog'],
    ['11d', 'wi-thunderstorm'],
    ['09d', 'wi-showers'],
    ['10d', 'wi-day-rain-mix'],
    ['13d', 'wi-snowflake-cold'],
    ['09d', 'wi-showers'],
    ['13d', 'wi-snowflake-cold'],
    ['50d', 'wi-fog'],
    ['01d', 'wi-day-sunny'],
    ['01n', 'wi-night-clear'],
    ['02d', 'wi-day-cloudy'],
    ['02n', 'wi-night-alt-cloudy'],
    ['03d', 'wi-cloud'],
    ['04d', 'wi-cloudy'],
    ['04n', 'wi-cloudy'],
]);

interface ICoord {
    lat: number;
    long: number;
}

const MAX_FORECAST_DAYS = 5;

async function getWeatherForecast({lat, long}: ICoord): Promise<IWeatherForecastDaily[]> {
    try {
        const searchParams = new URLSearchParams({
            lat: `${lat}`,
            lon: `${long}`,
            units: 'metric',
            exclude: 'alerts,hourly,minutely,current',
            appid: config.WEATHER_API_KEY
        });

        const response = await fetch(`https://api.openweathermap.org/data/2.5/onecall?${searchParams}`);

        if (response.status !== 200) {
            throw new Error('Cannot get weather forecast!');
        }

        const body: IOpenWeatherResponse = await response.json();

        const forecast = body.daily.map((day) => transform(day));

        return forecast.slice(0, Math.min(forecast.length, MAX_FORECAST_DAYS));
    } catch (err) {
        console.error('getForecast', err);
        throw err;
    }
}

function transform(day: IOpenWeatherItemDaily): IWeatherForecastDaily {
    const shortDayName = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat', 'Today'];

    const date = new Date(day['dt'] * 1000).getDay();
    const dayName = shortDayName[date];
    const temperature = Math.round(day.temp.max);
    const description = day.weather[0]['description'];

    const icon = weatherIconMap.get(day.weather[0]['icon']);

    return {
        dayName,
        temperature,
        description,
        icon
    };
}

export function useWeatherForecastQuery(coord: ICoord) {
    return useQuery(['weather-forecast', coord], () => getWeatherForecast(coord), {cacheTime: 60*60*1000, staleTime: 60*60*1000});
}