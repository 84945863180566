import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import Result from 'antd/lib/result';
import Typography from 'antd/lib/typography';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Auth } from '../../domain/auth/auth';

export const ResetPasswordComplete = () => {
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState<boolean>(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    const { token } = useParams<{ token: string }>();
    
    useEffect(() => {
        document.title = 'Reset Password Complete | Daydream Energy';
    }, []);

    const onFinish = async ({password}: {password: string, confirmPassword: string}) => {
        setLoading(true);
        try {
            console.info('onFinish', password);
            const data = {password, token};
            await Auth.resetPasswordComplete(data);
            setIsDone(true);
        } catch (error: any) {
            notification.error({
                key: 'reset-password-complete-error',
                message: 'Set New Password Error',
                description: error.message,
            });
        }
        setLoading(false);
    };

    const confirmPasswordValidator = (_: any, value: string) => {
        const password = form.getFieldValue('password');
        if (!password || password === value) {
            return Promise.resolve();
        } else {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
    };

    return (
        <div className='reset-password-complete'>
            <img className='logo' src='/logo.png' alt='Daydream Energy logo' />
            {isDone ? (
                <Result
                    status="success"
                    title="Password Changed!"
                    subTitle="Your password has been changed successfully."
                    extra={
                        <Button type="link" onClick={() => navigate('/login')}>Return to Login</Button>
                    }
                />
            ) : (
                <>
                    <Typography.Title level={2}>Set New Password</Typography.Title>
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item noStyle>
                            <h3>Please enter your new password, twice.</h3>
                            <br/>
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})).*$/,
                                    message:
                                        'Password must have at least 8 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                                },
                                { required: true, message: 'Please input your password!' },
                            ]}
                        >
                            <Input type="password" placeholder="Password" />
                        </Form.Item>
                        <Form.Item
                            name="confirmPassword"
                            rules={[
                                {
                                    pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})).*$/,
                                    message:
                                        'Password must have at least 8 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&',
                                },
                                { required: true, message: 'Please confirm your password!' },
                                { validator: confirmPasswordValidator },
                            ]}
                            dependencies={['password']}
                        >
                            <Input type="password" placeholder="Confirm Password" />
                        </Form.Item>
                        <Form.Item noStyle>
                            <Button loading={loading} block type="primary" htmlType="submit" size='large'>SUBMIT</Button>
                        </Form.Item>
                    </Form>
                    <Button type="link" onClick={() => navigate('/login')} className='extra-action'>Return to Login</Button>
                </>
            )}
        </div>
    )
}