import endOfDay from 'date-fns/endOfDay'
import endOfWeek from 'date-fns/endOfWeek'

export const prepareCalendarDates = (calendarView: string, start: Date, end: Date) => {
    switch (calendarView) {
        case 'resourceTimelineDay':
            return { start, end: endOfDay(start) }
        case 'resourceTimelineWeek':
            return { start, end: endOfWeek(start) }
        case 'dayGridMonth':
            return { start, end: endOfDay(end) }
        default:
            return { start, end: end }
    }
}