import './SiteForm.css';

import Button from 'antd/lib/button';
import Card from 'antd/lib/card';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import BulbOutlined from '@ant-design/icons/lib/icons/BulbOutlined';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import ScheduleOutlined from '@ant-design/icons/lib/icons/ScheduleOutlined';
import ToolOutlined from '@ant-design/icons/lib/icons/ToolOutlined';

import { AuditLog } from '../../../components/AuditLog/AuditLog';
import { HoursInput } from '../../../components/HoursInput/HoursInput';
import { LocationInput } from '../../../components/LocationInput/LocationInput';
import { OrgSelector } from '../../../components/OrgSelector/OrgSelector';
import { ProgramEnrollment } from '../../../components/ProgramEnrollment/ProgramEnrollment';
import { ProviderSelector } from '../../../components/ProviderSelector/ProviderSelector';
import { UtilitySelector } from '../../../components/UtilitySelector/UtilitySelector';
import { useAuth } from '../../../domain/auth/useAuth';
import { ILocation } from '../../../domain/sites/Location';
import { Partner } from '../../../domain/sites/Partner';
import { useSiteSaveQuery } from '../../../domain/sites/queries';
import { ISite, ISiteForm } from '../../../domain/sites/Site';
import { PartnerInput } from '../PartnerInput';

interface ISiteFormProps {
    site: ISiteForm;
    onClose: (site: ISiteForm | null) => any;
}

export function SiteForm({ site, onClose }: ISiteFormProps) {
    const [form] = Form.useForm<ISiteForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const {actor} = useAuth()!;
    const siteSaveQuery = useSiteSaveQuery();

    const isNew = site.id === null;

    async function onFormFinish(site: ISiteForm) {
        console.info(`onFormFinish=`, site);
        try {
            setLoading(true);
            if (!site.providerId) {
                site.providerId = null;
            }
            await siteSaveQuery.mutateAsync(site);

            notification.info({ key: 'site-save-info', message: 'Site saved' });
            onClose(site);
        } catch (err: any) {
            notification.error({ key: 'site-save-error', message: err.message || 'Cannot save site!' });
        }
        setLoading(false);
    }

    function onOrgChange(orgId: string | null) {
        console.info(`onOrgChange`, orgId);
        form.setFieldsValue({ orgId: orgId! });
    }

    function onProviderChange(providerId: string | null) {
        console.info(`onProviderChange`, providerId);
        form.setFieldsValue({ providerId: providerId! });
    }

    function onProgramChange(programIds: string[]) {
        console.info(`onProgramChange`, programIds);
        form.setFieldsValue({ programIds: programIds! });
    }

    function onLocationChange(location: ILocation | null) {
        console.info(`onLocationChange`, location);
        form.setFieldsValue({ location: location! });
    }

    function onPartnerChange(id: string | null) {
        console.info(`onPartnerChange`, id);
        form.setFieldsValue({ partner: id ? {id} : null });
    }

    function onUtilityChange(id: string | null) {
        console.info(`onUtilityChange`, id);
        form.setFieldsValue({ utilityId: id });
    }

    function locationValidator({ getFieldValue }: { getFieldValue: Function }) {
        return {
            validator(_: any, value: any) {
                const location = getFieldValue('location');
                console.info('locationValidator', location);

                if (!location) return Promise.reject(new Error('Address is not correct!'));
                return Promise.resolve();
            }
        }
    }

    return (
        <Modal
            title={isNew ? 'Create site' : 'Edit site'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='site-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='site-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='site-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={site}
                className='site-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>
                {
                actor?.isAdminGroup() && 
                <Form.Item
                    name='orgId'
                    shouldUpdate
                    label={<Typography.Text strong>Organization</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please select organization' }
                    ]}>
                    <OrgSelector orgId={site.orgId} onChange={onOrgChange}/>
                </Form.Item>
                }
                <Form.Item
                    name='title'
                    label={<Typography.Text strong>Title</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter title!' },
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='Site title' size='large' />
                </Form.Item>
                <Form.Item
                    name='location'
                    // shouldUpdate
                    label={<Typography.Text strong>Address</Typography.Text>}
                    hasFeedback
                    required
                    rules={[
                        locationValidator
                    ]}>
                    <LocationInput location={site.location || null} onChange={onLocationChange} />
                </Form.Item>
                <Form.Item
                    name='utilityId'
                    label={<Typography.Text strong>Utility</Typography.Text>}
                    hasFeedback
                >
                    <UtilitySelector utilityId={site.utilityId} onChange={onUtilityChange}/>
                </Form.Item>
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name='siteNumber'
                            label={<Typography.Text strong>Site Number</Typography.Text>}
                            hasFeedback
                            rules={[
                                { max: 100, message: 'Number of characters should be less than 100' }
                            ]}>
                            <Input placeholder='Site Number' size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name='serviceAccountNumber'
                            label={<Typography.Text strong>Service Account Number</Typography.Text>}
                            hasFeedback
                            rules={[
                                { max: 100, message: 'Number of characters should be less than 100' }
                            ]}>
                            <Input placeholder='Service Account Number' size='large' />
                        </Form.Item>
                    </Col>
                </Row>
                {
                actor?.isAdminGroup() && 
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item
                            name='estimatedKw'
                            label={<Typography.Text strong>Estimated Energy Usage</Typography.Text>}
                            tooltip={
                                <span>Estimated energy usage in kilowatts of the site</span>
                            }
                            hasFeedback
                            rules={[]}>
                            <InputNumber type='number' min={0} size='large' addonAfter={'KW'} width='100%'/>
                        </Form.Item>
                    </Col>

                </Row>
                }
                <Space direction='vertical' style={{width: '100%'}}>
                    <Card bordered={true} className='settings-card'>
                        <Card.Meta
                            avatar={<ToolOutlined />}
                            title="Controls"
                            description="&nbsp;"
                        />
                        <Form.Item
                            name='providerId'
                            shouldUpdate
                            label={<Typography.Text strong>Provider</Typography.Text>}
                            hasFeedback
                        >
                            <ProviderSelector providerId={site.providerId} onChange={onProviderChange}/>
                        </Form.Item>
                        <Form.Item
                            name='partner'
                            label={<Typography.Text strong>Partner Id</Typography.Text>}
                            hasFeedback
                        >
                            <PartnerInput partner={site.partner} onChange={onPartnerChange} />
                        </Form.Item>
                    </Card>

                    <Card bordered={true} className='settings-card'>
                        <Card.Meta
                            avatar={<ScheduleOutlined />}
                            title="Enrollment"
                            description="&nbsp;"
                        />
                        <Form.Item
                            name='programIds'
                            shouldUpdate
                            label={<Typography.Text strong>Programs</Typography.Text>}
                            hasFeedback
                        >
                            <ProgramEnrollment programIds={site.programIds || []} onChange={onProgramChange}/>
                        </Form.Item>
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name="priceResponseEnrolled"
                                    label={<Typography.Text strong>Price Response</Typography.Text>}
                                    tooltip={
                                        <span>By enrolling in 'Price Response' we will automatically create events when the price becomes higher and you will receive a notification.</span>
                                    }
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="peakSaverEnrolled"
                                    label={<Typography.Text strong>Peak Saver</Typography.Text>}
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card bordered={true} className='settings-card'>
                        <Card.Meta
                            avatar={<BulbOutlined />}
                            title="Event"
                            description="&nbsp;"
                        />
                        <Row gutter={8}>
                            <Col span={12}>
                                <Form.Item
                                    name='eventOffset'
                                    label={<Typography.Text strong>Offset</Typography.Text>}
                                    tooltip={
                                        <span>Allow an offset to be applied to the event time to start earlier than scheduled.<br/>Allowed interval is 0-30 min</span>
                                    }
                                    hasFeedback
                                    rules={[]}>
                                    <InputNumber type='number' min={0} max={30} step={5} size='large' addonAfter={'minutes'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name='eventMaxDuration'
                                    label={<Typography.Text strong>Max duration</Typography.Text>}
                                    tooltip={
                                        <span>Allow a maximum duration to be applied to the event time.<br/>Allowed interval is 0-2 hours.<br/>0 means that the max duration limit is not set.</span>
                                    }
                                    hasFeedback
                                >
                                    <HoursInput minutes={site.eventMaxDuration} onChange={() => {}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Space>
                
            </Form>
            <p></p>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='site' entityId={site.id!} /> : '' }
        </Modal>
    )
}


export class SiteFormData implements ISiteForm {
    constructor(
        public id: string | null, 
        public orgId: string | null, 
        public providerId: string | null, 
        public title: string | null,
        public location: ILocation | null, 
        public serviceAccountNumber: string | null, 
        public siteNumber: string | null, 
        public estimatedKw: number | null, 
        public utilityId: string | null,
        public eventOffset: number | null,
        public eventMaxDuration: number | null,
        public programIds: string[] | null,
        public partner: Partner | null,
        public priceResponseEnrolled: boolean,
        public peakSaverEnrolled: boolean
        ) {

    }

    static fromSite(site: ISite) {
        const programIds = site?.programs.map(p => p.id);
        return new SiteFormData(
            site.id,
            site.orgId,
            site.providerId,
            site.title, 
            site.location,
            site.serviceAccountNumber,
            site.siteNumber,
            site.estimatedKw,
            site.utilityId,
            site.eventOffset,
            site.eventMaxDuration,
            programIds,
            site.partner,
            site.priceResponseEnrolled,
            site.peakSaverEnrolled
        );
    }

    static fromDefault(orgId: string | null = null) {
        return new SiteFormData(
            null,
            orgId,
            null,
            null,
            null,
            null,
            null,
            5,
            null,
            null,
            null,
            [],
            {id: ''},
            false,
            false
        );
    }
}