import { useState } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';
import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import InputNumber from 'antd/lib/input-number';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Select from 'antd/lib/select';
import Switch from 'antd/lib/switch';
import { useAuth } from '../../domain/auth/useAuth';
import { OrgSelector } from '../../components/OrgSelector/OrgSelector';
import { AuditLog } from '../../components/AuditLog/AuditLog';
import { IPriceResponseTrigger, IPriceResponseTriggerForm } from '../../domain/price-response-trigger/PriceResponseTrigger';
import { usePriceResponseTriggerSaveQuery } from '../../domain/price-response-trigger/queries';
import { SignalType, SIGNAL_LEVEL_OPTIONS } from '../../domain/events/Event';
import { HoursInput } from '../../components/HoursInput/HoursInput';
import { StateSelector } from '../../components/StateSelector/StateSelector';
import Divider from 'antd/lib/divider';
import { TriggerTimeRange } from './TriggerTimeRange';

interface IPriceResponseTriggerFormProps {
    priceResponseTrigger: IPriceResponseTriggerForm;
    onClose: (priceResponseTrigger: IPriceResponseTriggerForm | null) => any;
}

export function PriceResponseTriggerForm({ priceResponseTrigger, onClose }: IPriceResponseTriggerFormProps) {
    const [form] = Form.useForm<IPriceResponseTriggerForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const {actor} = useAuth()!;
    const savePriceResponseTriggerQuery = usePriceResponseTriggerSaveQuery();

    const isNew = priceResponseTrigger.id === null;

    async function onFormFinish(trigger: IPriceResponseTriggerForm) {
        console.info(`onFormFinish=`, trigger);
        try {
            setLoading(true);
            await savePriceResponseTriggerQuery.mutateAsync(trigger);

            notification.info({ key: 'price-response-trigger-save-info', message: 'Price response trigger saved' });
            onClose(trigger);
        } catch (err: any) {
            notification.error({ key: 'price-response-trigger-save-error', message: err.message || 'Cannot save price response trigger!' });
        }
        setLoading(false);
    }

    function onOrgChange(orgId: string | null) {
        form.setFieldsValue({ orgId: orgId! });
    }

    return (
        <Modal
            title={isNew ? 'Create price response trigger' : 'Edit price response trigger'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='price-response-trigger-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='price-response-trigger-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='price-response-trigger-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={priceResponseTrigger}
                className='price-response-trigger-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>
                {
                actor?.isAdminGroup() ? 
                <Form.Item
                    name='orgId'
                    shouldUpdate
                    label={<Typography.Text strong>Organization</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please select organization' }
                    ]}>
                    <OrgSelector orgId={priceResponseTrigger.orgId} onChange={onOrgChange}/>
                </Form.Item>
                : 
                <Form.Item name='orgId' hidden><Input value={actor.orgId}/></Form.Item>
                }
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            name='state'
                            label={<Typography.Text strong>State</Typography.Text>}
                            hasFeedback
                            >
                            <StateSelector stateCode={priceResponseTrigger.state} onChange={() => {}} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name='price'
                            label={<Typography.Text strong>Price $/MWh</Typography.Text>}
                            hasFeedback
                            >
                            <InputNumber size="large" placeholder="Price" type="number" min={0} style={{width: '100%'}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <TriggerTimeRange form={form} />
                <Form.Item
                    name="enabled"
                    label={<Typography.Text strong>Trigger Enabled</Typography.Text>}
                    valuePropName="checked"
                >
                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>
                <Divider>Event</Divider>
                <Row gutter={8}>
                    <Col span={16}>
                        <Form.Item
                            label={<Typography.Text strong>OADR Signal Level</Typography.Text>}
                            name="eventSignal"
                        >
                            <Select placeholder="Please select signal level" options={SIGNAL_LEVEL_OPTIONS} size='large' />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            name='eventDurationMinutes'
                            label={<Typography.Text strong>Duration</Typography.Text>}
                            hasFeedback
                        >
                            <HoursInput minutes={priceResponseTrigger.eventDurationMinutes} onChange={() => {}}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Form.Item
                    name="stopIfPriceDrops"
                    label={<Typography.Text strong>Stop event if price drops bellow trigger value</Typography.Text>}
                    valuePropName="checked"
                >
                    <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
                </Form.Item>

            </Form>
            <p></p>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='price_response_trigger' entityId={priceResponseTrigger.id!} /> : '' }
        </Modal>
    )
}

export class PriceResponseTriggerFormData implements IPriceResponseTriggerForm {
    constructor(
        public id: string | null, 
        public orgId: string | null, 
        public price: number | null, 
        public state: string | null, 
        public startTime: string | null, 
        public endTime: string | null, 
        public stopIfPriceDrops: boolean, 
        public eventDurationMinutes: number | null, 
        public eventSignal: SignalType, 
        public enabled: boolean) {

    }

    static fromPriceResponseTrigger(trigger: IPriceResponseTrigger) {
        return new PriceResponseTriggerFormData(
            trigger.id, 
            trigger.orgId, 
            trigger.price, 
            trigger.state, 
            trigger.startTime, 
            trigger.endTime, 
            trigger.stopIfPriceDrops, 
            trigger.eventDurationMinutes, 
            trigger.eventSignal,
            trigger.enabled
        );
    }

    static fromDefault(orgId: string | null = null) {
        return new PriceResponseTriggerFormData(null, orgId, null, null, '00:00:00', '23:59:59', false, 60, SignalType.DEFAULT, true);
    }
}