import uniq from 'lodash.uniq';
import { Actor, GuestActor, IActor } from './Actor';

const MAX_RECENT_ORG = 3;

export const Session = {
    prefix: 'daydream',
    getActor(): IActor {
        try {
            let item = localStorage.getItem(`${this.prefix}.actor`);
            if (!item) {
                item = sessionStorage.getItem(`${this.prefix}.actor`);
            }

            if (!item) return new GuestActor();

            const object = JSON.parse(item);
            return new Actor(object);
        } catch (err) {
            console.error('getActor', err);
            this.clear();
            return new GuestActor();
        }
    },

    setActor(actor: IActor, remember: boolean = true) {
        console.info('setActor', actor, remember);
        if (remember) {
            localStorage.setItem(`${this.prefix}.actor`, JSON.stringify(actor));
            let item = localStorage.getItem(`${this.prefix}.actor`);
            console.info('**', item);
        } else {
            sessionStorage.setItem(`${this.prefix}.actor`, JSON.stringify(actor));
        }
    },

    getRecentOrgs(): string[] {
        try {
            const item = localStorage.getItem(`${this.prefix}.recent.org`);
            if (!item) return [];
            const recent: string[] = JSON.parse(item);

            let keys = recent.filter(item => item);

            if (keys.length > MAX_RECENT_ORG) {
                keys = keys.slice(0, MAX_RECENT_ORG);
            }

            return keys;
        } catch (error: any) {
            localStorage.removeItem(`${this.prefix}.recent.org`);
            return [];
        }
    },

    addRecentOrg(orgId: string) {
        let keys = this.getRecentOrgs();
        keys.unshift(orgId);
        keys = uniq(keys);
        
        if (keys.length > MAX_RECENT_ORG) {
            keys = keys.slice(0, MAX_RECENT_ORG);
        }

        localStorage.setItem(`${this.prefix}.recent.org`, JSON.stringify(keys))
    },

    clear() {
        console.info('Session.clear');
        localStorage.removeItem(`${this.prefix}.actor`);
        sessionStorage.removeItem(`${this.prefix}.actor`);
    },
};