import './ProgramEnrollment.css';

import Select from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import { useEffect, useState } from 'react';

import { IProgram } from '../../domain/programs/Program';
import { useProgramListQuery } from '../../domain/programs/queries';

export interface IProgramEnrollmentProps {
    programIds?: string[];
    disabled?: boolean;
    onChange: (programIds: string[]) => void
}

export const ProgramEnrollment = ({ programIds, disabled, onChange }: IProgramEnrollmentProps) => {
    const {data, isLoading} = useProgramListQuery({pagination: {page: 1, size: 1000}});
    const programs = data?.data || [];

    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (!isLoading) {
            setSelected(programIds || [])
        }
    }, [isLoading, programIds]);

    function onChangeHandler(ids: string[]) {
        setSelected(ids);
        onChange(ids);
    }

    function makeProgramOptionsElements(programs: IProgram[]) {
        const items = programs.map(program => {
            return {
                recent: false,
                value: program.id,
                key: `program-${program.id}`,
                label: program.title
            }
        });

        return items.map(item => makeProgramOptionElement(item));
    }

    function makeProgramOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.label}
            </Select.Option>
        )
    }

    const tagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                key={`key-${value}`}
                color={'purple'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Select
            disabled={disabled}
            loading={isLoading}
            size='large'
            placeholder='Please select programs'
            mode='multiple'
            tagRender={tagRender}
            value={selected}
            onChange={onChangeHandler}
            className='program-enrollment'
            bordered={true}
            showSearch
            allowClear
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeProgramOptionsElements(programs)}
        </Select>
    )
}