import { Emerson } from '../emerson/Emerson';

export enum OrgType {
    GENERIC = 'generic',
    PROVIDER = 'provider'
}

export interface IOrg {
    id: string;
    title: string;
    type: OrgType;
    emerson: Emerson | null;
    createdAt: Date;
    updatedAt: Date;

    sitesCount?: number
}

export interface IOrgForm {
    id: string | null;
    title: string | null;
    type: OrgType;
    emerson: Emerson | null;
}