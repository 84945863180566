import Select from 'antd/lib/select';
import { useState } from 'react';
import './StateSelector.css';

export interface IStateSelectorProps {
    stateCode: string | null;
    onChange: (state: string | null) => void
}

export const StateSelector = ({stateCode, onChange}: IStateSelectorProps) => {
    const statesOptions: {value: string; key: string; label: string;}[] = [
        {
            value: 'NSW',
            key: `NSW`,
            label: 'New South Wales'
        },
        {
            value: 'VIC',
            key: `VIC`,
            label: 'Victoria'
        },
        {
            value: 'QLD',
            key: `QLD`,
            label: 'Queensland'
        },
        {
            value: 'WA',
            key: `WA`,
            label: 'Western Australia'
        },
        {
            value: 'SA',
            key: `SA`,
            label: 'South Australia'
        },
        {
            value: 'TAS',
            key: `TAS`,
            label: 'Tasmania'
        },
    ];

    const [selected, setSelected] = useState<string | null>(stateCode || null);

    function onChangeHandler(id: string) {
        setSelected(id);
        onChange(id);
    }

    function makeOptionsElements(statesOptions: {value: string; key: string; label: string;}[]) {
        return statesOptions.map(item => makeOptionElement(item));
    }

    function makeOptionElement(option: {value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.label}
            </Select.Option>
        )
    }

    return (
        <Select
            size='large'
            placeholder='Please select state'
            value={selected}
            onChange={onChangeHandler}
            className='state-selector'
            bordered={true}
            showSearch
            allowClear
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeOptionsElements(statesOptions)}
        </Select>
    )
}