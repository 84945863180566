import './AuthLayout.css';

import Card from 'antd/lib/card';
import Layout from 'antd/lib/layout';
import { Navigate } from 'react-router-dom';

import { useAuth } from '../../domain/auth/useAuth';

const { Content } = Layout;

export function AuthLayout({ children }: { children: React.ReactNode }) {
    const {actor} = useAuth();

    if (actor.isAuthenticated()) {
        return <Navigate to='/' replace={false} />
    }

    return (
        <Layout className='auth-layout'>
            <Card className='auth-layout-content'>
                <Content>
                    {children}
                </Content>
            </Card>
        </Layout>
    )
}
