import './App.less';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AuthProvider } from './domain/auth/useAuth';
import { AuthLayout } from './layouts/auth/AuthLayout';
import { PrivateLayout } from './layouts/private/PrivateLayout';
import { AppTokens } from './pages/app-tokens/AppTokens';
import { Dashboard } from './pages/dashboard/Dashboard';
import { EventsCalendar } from './pages/events/EventsCalendar/EventsCalendar';
import { EventsHistory } from './pages/events/EventsHistory/EventsHistory';
import { Login } from './pages/login/Login';
import { Orgs } from './pages/orgs/Orgs';
import { PriceResponseTriggers } from './pages/price-response-triggers/PriceResponseTriggers';
import { Programs } from './pages/programs/Programs';
import { ResetPasswordComplete } from './pages/reset-password-complete/ResetPasswordComplete';
import { ResetPassword } from './pages/reset-password/ResetPassword';
import { Sites } from './pages/sites/Sites';
import { Users } from './pages/users/Users';
import { Utilities } from './pages/utilities/Utilities';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  }
});

function App() {
  return (
    <div className='App'>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <Routes>
              <Route path='/:url*(/+)' element={<Navigate to={document.location.pathname.slice(0, -1)} />}></Route>
              <Route path='/login' element={<AuthLayout><Login /></AuthLayout>}></Route>
              <Route path='/reset-password' element={<AuthLayout><ResetPassword /></AuthLayout>}></Route>
              <Route path='/reset-password/complete/token/:token' element={<AuthLayout><ResetPasswordComplete /></AuthLayout>}></Route>
              <Route path='/orgs' element={<PrivateLayout><Orgs /></PrivateLayout>}></Route>
              <Route path='/sites' element={<PrivateLayout><Sites /></PrivateLayout>}></Route>
              <Route path='/events/calendar' element={<PrivateLayout><EventsCalendar /></PrivateLayout>}></Route>
              <Route path='/events/history' element={<PrivateLayout><EventsHistory /></PrivateLayout>}></Route>
              <Route path='/programs' element={<PrivateLayout><Programs /></PrivateLayout>}></Route>
              <Route path='/utilities' element={<PrivateLayout><Utilities /></PrivateLayout>}></Route>
              <Route path='/users' element={<PrivateLayout><Users /></PrivateLayout>}></Route>
              <Route path='/price-response' element={<PrivateLayout><PriceResponseTriggers /></PrivateLayout>}></Route>
              <Route path='/app-tokens' element={<PrivateLayout><AppTokens /></PrivateLayout>}></Route>
              <Route path='/' element={<PrivateLayout><Dashboard /></PrivateLayout>}></Route>
              <Route path='*' element={<Navigate to='/' />}></Route>
          </Routes>
        </AuthProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </div>
  );
}

export default App;
