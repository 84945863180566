import format from 'date-fns/format';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IOrg, IOrgForm } from './Org';

export interface IOrgListQuery extends IListQuery {
    filter?: {
        type?: string;
        search?: string;
    }
}

async function getOrgs(query: IOrgListQuery): Promise<WithPagination<IOrg>> {
    const searchParams = makeURLSearchParams(query);
    /** we display all available orgs at orgs page - no need to filter */
    if (searchParams.has('orgId')) searchParams.delete('orgId');

    const data = await API.fetch<WithPagination<IOrg>>(`/api/v1/orgs?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return data;
};

async function createOrg(org: IOrgForm) {
    const data: any = {
        title: org.title,
        type: org.type
    };

    return await API.fetch<IOrg>(`/api/v1/orgs`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateOrg(org: IOrgForm) {
    const data: any = {
        title: org.title,
        ...typeof org.emerson !== 'undefined' ? {emerson: org.emerson} : {}
    };

    return await API.fetch<IOrg>(`/api/v1/orgs/${org.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveOrg(org: IOrgForm) {
    return (org.id) ? await updateOrg(org) : await createOrg(org);
}

async function deleteOrg(org: IOrg) {
    await API.fetch(`/api/v1/orgs/${org.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function orgSyncEmerson(orgId: string) {
    return await API.fetch<{error: string} | {message: string}>(`/api/v1/orgs/${orgId}/emerson/sync`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function exportOrgs(query: IOrgListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv'
    });
    const result = await API.fetch<string>(`/api/v1/orgs?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `orgs--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function useOrgListQuery(query: IOrgListQuery) {
    return useQuery(['org', query], () => getOrgs(query));
    // return useQuery(['org', query], () => getOrgs(query), { keepPreviousData: false });
}

export function useOrgCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((org: IOrgForm) => createOrg(org), {
        onSuccess() {
            return queryClient.invalidateQueries(['org'])
        }
    });
}

export function useOrgUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((org: IOrgForm) => updateOrg(org), {
        onSuccess() {
            return queryClient.invalidateQueries(['org'])
        }
    });
}

export function useOrgSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((org: IOrgForm) => saveOrg(org), {
        onSuccess() {
            return queryClient.invalidateQueries(['org'])
        }
    });
}

export function useOrgDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((org: IOrg) => deleteOrg(org), {
        onSuccess() {
            return queryClient.invalidateQueries(['org'])
        }
    });
}

export function useOrgSyncEmersonQuery() {
    return useMutation((orgId: string) => orgSyncEmerson(orgId), {});
}