import Select from 'antd/lib/select';
import FieldTimeOutlined from "@ant-design/icons/lib/icons/FieldTimeOutlined";
import { IOrg } from '../../domain/orgs/Org';
import { Session } from '../../domain/auth/Session';
import { useOrg } from '../OrgContext/OrgContext';
import { useState } from 'react';
import './OrgSelector.css';

export interface IOrgSelectorProps {
    orgId?: string | null;
    disabled?: boolean;
    onChange: (orgId: string | null) => void
}

export const OrgSelector = (props: IOrgSelectorProps) => {
    const {orgs} = useOrg();
    const [id, setId] = useState(() => {
        if (props.orgId) return props.orgId;
        return undefined;
    });

    function onOrgChange(orgId: string) {
        console.info(`OrgSelector.onOrgChange orgId=`, orgId);
        setId(orgId);
        props.onChange(orgId);
    }

    function makeOrgOptionsElements(orgs: IOrg[]) {
        const recentOrgs = Session.getRecentOrgs();
        let items: {recent: boolean; value: string; key: string; label: string}[] = [];

        recentOrgs.forEach(orgId => {
            const org = orgs.find(org => org.id === orgId);
            if (!org) return;

            items.push({
                recent: true,
                value: org.id,
                key: `org-${org.id}`,
                label: org.title
            })
        });

        orgs.forEach(org => {
            if (recentOrgs.includes(org.id)) return;
            items.push({
                recent: false,
                value: org.id,
                key: `org-${org.id}`,
                label: org.title
            })
        });

        return items.map(item => makeOrgOptionElement(item));
    }

    function makeOrgOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.recent && <FieldTimeOutlined className='recent-icon' style={{ marginRight: '5px' }} />}
                {option.label}
            </Select.Option>
        )
    }

    return (
        <Select
            size='large'
            disabled={props.disabled}
            placeholder='Please select organization'
            value={id}
            onChange={onOrgChange}
            className='org-selector'
            bordered={true}
            showSearch
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeOrgOptionsElements(orgs)}
        </Select>
    )
}