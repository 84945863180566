import add from 'date-fns/add';
import sub from 'date-fns/sub';
import { IEvent, Opt } from '../../../domain/events/Event';

export interface ICalendarEvent {
    id: string;
    resourceId: string;
    start: Date;
    end: Date;
    title: string;
    classNames?: string[];
}

class CalendarEvent implements ICalendarEvent {
    id: string;
    resourceId: string;
    start: Date;
    end: Date;
    title: string;
    classNames?: string[];

    constructor(event: IEvent) {
        this.id = event.id;
        this.resourceId = event.siteId;
        this.title = event.test ? 'TEST' : '';
        this.start = new Date(event.start);
        this.end = add(new Date(event.start), { minutes: event.durationMinutes });
        this.classNames = ['event'];

        if (event.cancelled) {
            this.classNames.push('event-canceled');
        } else if (event.opt === Opt.OUT) {
            this.classNames.push('event-opt-out');
        }
    }
}

class CalendarPreEvent implements ICalendarEvent {
    id: string;
    resourceId: string;
    start: Date;
    end: Date;
    title: string;
    classNames?: string[];

    constructor(event: IEvent) {
        this.id = event.id;
        this.resourceId = event.siteId;
        this.title = '';
        this.start = sub(new Date(event.start), { minutes: event.preDurationMinutes });
        this.end = new Date(event.start);

        this.classNames = ['pre-event'];

        if (event.cancelled) {
            this.classNames.push('event-canceled');
        } else if (event.opt === Opt.OUT) {
            this.classNames.push('event-opt-out');
        }
    }
}

export const toCalendarEvents = (events: IEvent[], calendarView: string): ICalendarEvent[] => {
    const calendarEvents: ICalendarEvent[] = [];
    
    if (calendarView === 'dayGridMonth') {
        // Todo
        return [];
    }

    for (let event of events) {
        if (event.preDurationMinutes > 0) {
            calendarEvents.push(new CalendarPreEvent(event));
        }

        if (event.durationMinutes > 0) {
            calendarEvents.push(new CalendarEvent(event));
        }
    }
    
    return calendarEvents;
}