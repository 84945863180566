import formatISO from 'date-fns/formatISO';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IEvent } from './Event';
import { IEventForm } from './EventForm';

export interface IEventListQuery extends IListQuery {
    filter?: {
        orgId?: string;
        siteId?: string;
        programId?: string;
        batch?: string;
        start?: Date;
        end?: Date;
        search?: string;
    }
}

async function getEvents(query: IEventListQuery): Promise<WithPagination<IEvent>> {
    // console.info('q', query);
    // const page = query?.page ? query?.page :  1;
    // const pageSize = query?.pageSize ? query?.pageSize : 20;

    // const offset = (page <= 1) ? 0 : (page - 1) * pageSize;
    // const searchParams = new URLSearchParams({
    //     offset: `${offset}`,
    //     limit: `${query.pageSize}`,
    //     sortField: query.sortField || 'createdAt',
    //     sortOrder: query.sortOrder || 'desc',
    //     ...(query.orgId && {orgId: query.orgId}),
    //     ...(query.siteId && {siteId: query.siteId}),
    //     ...(query.programId && {programId: query.programId}),
    //     ...(query.batch && {batch: query.batch}),
    //     ...(query.start && {start: query.start.toISOString()}),
    //     ...(query.end && {end: query.end.toISOString()}),
    //     ...(query.search && {search: query.search}),
    //     ...(query.include && {include: query.include}),
    // });

    const searchParams = makeURLSearchParams(query);

    const data = await API.fetch<WithPagination<IEvent>>(`/api/v1/events?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });

    return data;
};

async function createEvent({sites, event}: {sites: string[]; event: IEventForm}) {
    // 2022-09-17T22:30:52+03:00 -> 2022-09-17T22:30:52
    const startLocal = formatISO(event.startLocal!).split('+')[0];

    const data: any = {
        sites: sites,
        event: {
            startLocal: startLocal,
            preDurationMinutes: event.preDurationMinutes,
            durationMinutes: event.durationMinutes,
            signal: event.signal,
            test: event.test
        }
    };

    console.info('create event', event);
    console.info('create event*', data);

    return await API.fetch<IEvent>(`/api/v1/events/batch`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateEvent(event: IEventForm) {
    const data: any = {
        startLocal: event.startLocal,
        preDurationMinutes: event.preDurationMinutes,
        durationMinutes: event.durationMinutes,
        signal: event.signal,
        test: event.test
    };

    return await API.fetch<IEvent>(`/api/v1/events/${event.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateEventBatch(event: IEventForm) {
    const data: any = {
        startLocal: event.startLocal,
        preDurationMinutes: event.preDurationMinutes,
        durationMinutes: event.durationMinutes,
        signal: event.signal,
        test: event.test
    };

    return await API.fetch<IEvent>(`/api/v1/events/batch/${event.batch}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function cancelEvent(eventId: string) {
    await API.fetch(`/api/v1/events/${eventId}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function cancelEventBatch(batch: string) {
    await API.fetch(`/api/v1/events/batch/${batch}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function useEventListQuery(query: IEventListQuery, opts: any = {}) {
    return useQuery(['event', query], () => getEvents(query), { keepPreviousData: false, ...opts });
}

export function useEventCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((data: {sites: string[]; event: IEventForm}) => createEvent(data), {
        onSuccess() {
            return queryClient.invalidateQueries(['event'])
        }
    });
}

export function useEventUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((event: IEventForm) => updateEvent(event), {
        onSuccess() {
            return queryClient.invalidateQueries(['event'])
        }
    });
}

export function useEventBatchUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((event: IEventForm) => updateEventBatch(event), {
        onSuccess() {
            return queryClient.invalidateQueries(['event'])
        }
    });
}

export function useEventCancelQuery() {
    const queryClient = useQueryClient();

    return useMutation((eventId: string) => cancelEvent(eventId), {
        onSuccess() {
            return queryClient.invalidateQueries(['event'])
        }
    });
}

export function useEventBatchCancelQuery() {
    const queryClient = useQueryClient();

    return useMutation((batch: string) => cancelEventBatch(batch), {
        onSuccess() {
            return queryClient.invalidateQueries(['event'])
        }
    });
}