import Segmented, { SegmentedValue } from 'antd/lib/segmented';
import { useNavigate } from 'react-router-dom';

import CalendarOutlined from '@ant-design/icons/lib/icons/CalendarOutlined';
import HistoryOutlined from '@ant-design/icons/lib/icons/HistoryOutlined';

interface IEventsViewSwitchProps {
    view: 'calendar' | 'history';
}

export const EventsViewSwitch = ({view}: IEventsViewSwitchProps) => {
    const navigate = useNavigate();
    
    const eventsViewOptions = [{
        label: 'Calendar',
        value: 'calendar',
        icon: <CalendarOutlined />,
    },
    {
        label: 'History',
        value: 'history',
        icon: <HistoryOutlined />,
    }];

    const changeEventsView = (view: SegmentedValue) => {
        return navigate(`/events/${view}`, {replace: false})
    }
    
    return <Segmented options={eventsViewOptions} value={view} onChange={changeEventsView} key='events-view-switch' />
}