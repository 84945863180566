import { Alert } from 'antd';
import Input from 'antd/lib/input';
import { useState } from 'react';

import { Partner } from '../../domain/sites/Partner';

interface IPartnerInputProps {
    partner: Partner | null;
    disabled?: boolean;
    onChange: (id: string | null) => void;
}

export const PartnerInput = (props: IPartnerInputProps) => {
    const [id, setId] = useState<string>(props.partner?.id || '');
    const disabled = props.disabled || false;

    function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const id = e.target.value || '';
        setId(id);
        props.onChange(id || null);
    }

    return (
        <>
            <Input 
                placeholder='Partner Id' 
                size='large' 
                value={id} 
                onChange={onChange} 
                disabled={disabled}
                maxLength={36}
                allowClear
            />
            {props.partner?.error && <Alert message={props.partner?.error} type="warning" showIcon />}
        </>
    )
}