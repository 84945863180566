import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Typography from 'antd/lib/typography';

import { useUserChangePasswordQuery } from '../../domain/users/queries';

interface IProfileChangePasswordProps {
    onClose: () => void;
}

export const ProfileChangePassword = ({ onClose }: IProfileChangePasswordProps) => {
    const [form] = Form.useForm();
    const changePasswordQuery = useUserChangePasswordQuery();

    const onFinish = async (data: { oldPassword: string; newPassword: string }) => {
        try {
            await changePasswordQuery.mutateAsync(data);
            notification.info({ key: 'change-password-modal', message: 'Password updated!' });
            onClose();
        } catch (error: any) {
            notification.error({ key: 'change-password-error', message: error.message || 'Cannot change password!' });
        }
    };

    return (
        <Modal
            title='Change Password'
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose()}
            footer={[
                <Button onClick={() => onClose()} key='profile-change-password-modal-cancel'>Cancel</Button>,
                <Button onClick={form.submit} key='profile-change-password-modal-submit' type='primary' loading={changePasswordQuery.isLoading}>Save</Button>,
            ]}
        >
            <Form layout="vertical" form={form} name="update-password-form" preserve={false} onFinish={onFinish}>
                <Form.Item
                    hasFeedback
                    name="oldPassword"
                    label={<Typography.Text strong>Current Password</Typography.Text>}
                    rules={[{ required: true, message: 'Current password is required!' }]}
                >
                    <Input.Password placeholder="Current password" size="large" autoComplete="new-password" />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label={<Typography.Text strong>New Password</Typography.Text>}
                    rules={[
                        { 
                            pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/, 
                            message: 'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&' 
                        },
                        { required: true, message: 'Password is required!' }
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="input password" autoComplete="new-password" size="large" />
                </Form.Item>
                <Form.Item
                    name="confirmNewPassword"
                    label={<Typography.Text strong>Confirm Password</Typography.Text>}
                    dependencies={['newPassword']}
                    rules={[
                        { required: true, message: 'Please confirm your password!' },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('New password and Confirm password do not match!'));
                            },
                        }),
                    ]}
                    hasFeedback
                >
                    <Input.Password placeholder="input password" autoComplete="new-password" size="large" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
