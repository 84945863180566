import Tooltip from 'antd/lib/tooltip';

interface IControlledTooltipProps {
    children: React.ReactNode;
    visible: boolean;
    title: string
}

export const ControlledTooltip = ({ children, visible, title }: IControlledTooltipProps) => {
    if (visible) {
        return (
            <Tooltip
                title={title}>
                {children}
            </Tooltip>
        )
    }

    return <>{children}</>;
}