import { createContext } from 'react';

import { AbilityBuilder, AbilityClass, MatchConditions, PureAbility } from '@casl/ability';

import { Actor, Role } from './Actor';

export type Actions = 'list' | 'create' | 'read' | 'update' | 'delete' | 'change' | 'view-route' | 'manage';
type RouteSubject =
    | '/orgs'
    | '/sites'
    | '/events'
    | '/events/calendar'
    | '/events/history'
    | '/programs'
    | '/utilities'
    | '/users'
    | '/price-response'
    | '/app-tokens'
    | '/';

export type Subjects =
    | RouteSubject
    | 'Org'
    | 'Site'
    | 'Program'
    | 'Utility'
    | 'User'
    | 'Event'
    | 'Audit'
    | 'PriceResponseTrigger'
    | 'AppToken'
    | 'all';

export type AppAbilityType = PureAbility<[Actions, Subjects], MatchConditions>;
export const AppAbility = PureAbility as AbilityClass<AppAbilityType>;
const lambdaMatcher = (matchConditions: MatchConditions) => matchConditions;

export function buildAbilityFor(user: Actor | null) {
    const { can, build } = new AbilityBuilder(AppAbility);

    if (!user) {
        return build({ conditionsMatcher: lambdaMatcher });
    }
    const role = user?.role;

    switch (role) {
        case Role.ADMIN:
            can(['list', 'create', 'delete', 'update', 'read', 'view-route', 'change'], 'all');
            break;
        case Role.ACCOUNT_MANAGER:
            can(['update', 'create', 'read', 'delete', 'change'], 'Org');
            can(['update', 'create', 'read', 'delete'], 'Site');
            can(['create', 'delete', 'update', 'read'], 'User');
            can(['create', 'delete', 'update', 'read'], 'Program');
            can(['create', 'delete', 'update', 'read'], 'Utility');
            can(['create', 'delete', 'update', 'read'], 'PriceResponseTrigger');
            can(['create', 'delete', 'update', 'read'], 'AppToken');
            can('read', 'Event');
            can('view-route', [
                '/',
                '/orgs',
                '/sites',
                '/users',
                '/programs',
                '/utilities',
                '/price-response',
                '/events/calendar',
                '/events/history',
                '/app-tokens',
            ]);
            break;
        case Role.CUSTOMER:
            can(['update', 'create', 'read'], 'Site');
            can(['create', 'delete', 'update', 'read'], 'User');
            can(['create', 'delete', 'update', 'read'], 'PriceResponseTrigger');
            can(['create', 'delete', 'update', 'read'], 'AppToken');
            can('read', 'Program');
            can('read', 'Utility');
            can('read', 'Event');
            can('view-route', [
                '/',
                '/sites',
                '/price-response',
                '/users',
                '/app-tokens',
                '/events/calendar',
                '/events/history'
            ]);
            break;
        case Role.OPERATOR:
            can('read', 'Site');
            can('read', 'User');
            can('read', 'PriceResponseTrigger');
            can('read', 'Program');
            can('read', 'Utility');
            can('read', 'Event');
            can('view-route', [
                '/',
                '/sites',
                '/price-response',
                '/users',
                '/events/calendar',
                '/events/history'
            ]);
            break;
        case Role.PROVIDER:
            can('read', 'Org');
            can('change', 'Org');
            can('read', 'Site');
            can('read', 'User');
            can(['create', 'delete', 'update', 'read'], 'AppToken');
            can('read', 'Program');
            can('read', 'Utility');
            can('read', 'Event');
            can('view-route', [
                '/',
                '/orgs',
                '/sites',
                '/price-response',
                '/users',
                '/app-tokens',
                '/events/calendar',
                '/events/history'
            ]);
            break;
        default:
            can('read', 'all');
            break;
    }

    return build({ conditionsMatcher: lambdaMatcher });
}

export const AbilityContext = createContext<AppAbilityType>(undefined!);