import format from 'date-fns/format';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IUtility, IUtilityForm } from './Utility';

export interface IUtilityListQuery extends IListQuery {
    filter?: {
        search?: string;
    }
}

async function getUtilities(query: IUtilityListQuery): Promise<WithPagination<IUtility>> {

    const searchParams = makeURLSearchParams(query);

    return await API.fetch<WithPagination<IUtility>>(`/api/v1/utilities?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createUtility(utility: IUtilityForm) {
    const data: any = {
        title: utility.title,
    };

    return await API.fetch<IUtility>(`/api/v1/utilities`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateUtility(utility: IUtilityForm) {
    const data: any = {
        title: utility.title
    };

    return await API.fetch<IUtility>(`/api/v1/utilities/${utility.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveUtility(utility: IUtilityForm) {
    return (utility.id) ? await updateUtility(utility) : await createUtility(utility);
}

async function deleteUtility(utility: IUtility) {
    await API.fetch(`/api/v1/utilities/${utility.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export async function exportUtilities(query: IUtilityListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv'
    });
    const result = await API.fetch<string>(`/api/v1/utilities?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `utilities--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function useUtilityListQuery(query: IUtilityListQuery) {
    return useQuery(['utility', query], () => getUtilities(query), { keepPreviousData: false });
}

export function useUtilityCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((utility: IUtilityForm) => createUtility(utility), {
        onSuccess() {
            return queryClient.invalidateQueries(['utility'])
        }
    });
}

export function useUtilityUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((utility: IUtilityForm) => updateUtility(utility), {
        onSuccess() {
            return queryClient.invalidateQueries(['utility'])
        }
    });
}

export function useUtilitySaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((utility: IUtilityForm) => saveUtility(utility), {
        onSuccess() {
            return queryClient.invalidateQueries(['utility'])
        }
    });
}

export function useUtilityDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((utility: IUtility) => deleteUtility(utility), {
        onSuccess() {
            queryClient.invalidateQueries(['utility'])
        }
    });
}