import Tag from 'antd/lib/tag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography/Typography';
import { IProgram } from '../../domain/programs/Program';

export interface IProgramTagProps {
    program: IProgram;
}

export const ProgramTag = ({ program }: IProgramTagProps) => {
    return (
        // <Tooltip title={program.title} key={program.id}>
            <Typography className='ellipsis' key={program.id}>
                <Tag color={'purple'}>{program.title}</Tag>
            </Typography>
        // </Tooltip>
    )
}