const PRICE = [
    { range: [Number.NEGATIVE_INFINITY, -50], color: '#4B0082' /** purple */, styleIndex: 1 },
    { range: [-50, -20], color: '#174287' /** blue */, styleIndex: 2 },
    { range: [-20, 0], color: '#209CEE' /** light blue */, styleIndex: 3 },
    { range: [0, 60], color: '#31ADAA' /** green */, styleIndex: 4 },
    { range: [60, 120], color: '#FFDD57' /** yellow */, styleIndex: 5 },
    { range: [120, 200], color: '#FF8C00' /** orange */, styleIndex: 6 },
    { range: [200, 500], color: '#FF3860' /** pink */, styleIndex: 7 },
    { range: [500, Number.POSITIVE_INFINITY], color: '#DB143C' /** red */, styleIndex: 8 },
];

const getPriceRange = (price: number) => PRICE.find(({ range }) => price >= range[0] && price < range[1]);

export function colorByPrice(price: number): string {
    const { color } = getPriceRange(price)!;
    return color || '#31ADAA';
}

export function styleIndexByPrice(price: number): number {
    const { styleIndex } = getPriceRange(price)!;
    return styleIndex || 1;
}