import './EventsCalendar.css';

import Button from 'antd/lib/button';
import Spin from 'antd/lib/spin';
import CheckableTag from 'antd/lib/tag/CheckableTag';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import endOfDay from 'date-fns/endOfDay';
import startOfDay from 'date-fns/startOfDay';
import merge from 'lodash.merge';
import { createRef, useEffect, useState } from 'react';

import FullCalendar, { DatesSetArg, EventContentArg } from '@fullcalendar/react';

import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';

import { ControlledTooltip } from '../../../components/ControlledTooltip/ControlledTooltip';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { ProgramSelector } from '../../../components/ProgramSelector/ProgramSelector';
import { Search } from '../../../components/Search/Search';
import { config } from '../../../config';
import { useAuth } from '../../../domain/auth/useAuth';
import { IEventForm } from '../../../domain/events/EventForm';
import { useEventListQuery } from '../../../domain/events/queries';
import { formatMinutes } from '../../../domain/shared/formatMinutes';
import { usePageState } from '../../../domain/shared/usePageState';
import { useSiteListQuery } from '../../../domain/sites/queries';
import { EventForm, EventFormData } from '../EventForm/EventForm';
import { EventsViewSwitch } from '../EventsViewSwitch';
import { prepareCalendarDates } from './Calendar';
import { ICalendarEvent, toCalendarEvents } from './CalendarEvent';
import { ICalendarResource, toCalendarResources } from './CalendarResource';

export const EventsCalendar = () => {
    const {actor} = useAuth();
    const {pageState, setPageState} = usePageState();
    const [calendarDates, setCalendarDates] = useState<{start: Date, end: Date}>({start: startOfDay(new Date()), end: endOfDay(new Date())});
    const [calendarEvents, setCalendarEvents] = useState<ICalendarEvent[]>([]);
    const [calendarResources, setCalendarResources] = useState<ICalendarResource[]>([]);
    const [event, setEvent] = useState<IEventForm | null>(null);
    const [calendarView, setCalendarView] = useState<string>('resourceTimelineDay');
    const calRef: any = createRef();

    const sitesQuery = useSiteListQuery({...pageState, pagination: {size: 1000, page:1}, include: 'org,programs'});

    const eventsQuery = useEventListQuery({
        ...pageState, 
        pagination: {size: 1000, page:1}, 
        filter: {start: calendarDates?.start, end: calendarDates?.end}
    });

    useEffect(() => {
        document.title = 'Events Calendar | Daydream Energy';
    }, []);

    useEffect(() => {
        const sites = sitesQuery.data?.data || [];
        const calendarResources = toCalendarResources(sites, calendarView);
        setCalendarResources(calendarResources);
    }, [sitesQuery.data, calendarView]);

    useEffect(() => {
        const events = eventsQuery.data?.data || [];
        const calendarEvents = toCalendarEvents(events, calendarView);
        setCalendarEvents(calendarEvents);
    }, [eventsQuery.data, calendarView]);

    function showNewEventForm() {
        setEvent(EventFormData.fromDefault());
    }

    function onEventFormClose() {
        setEvent(null);
    }

    function onEventClick(info: any) {
        if (info.view.type === "dayGridMonth") { return undefined }

        const events = eventsQuery?.data?.data || [];
        const currentEvent = events.find(event => event.id === info.event.id);
        if (!currentEvent) {
            console.info(`cannot find event with id=${info.event.id}!`);
            return;
        }
        console.info(currentEvent);
        console.info(EventFormData.fromEvent(currentEvent));

        const eventResources = info.event.getResources();
        const eventResourceId = eventResources.map((resource: any) => { return resource.id })[0];
        const resourcesItems: ICalendarResource[] = calendarResources.map(r => {
            r.selected = false;
            if (r.id === eventResourceId) r.selected = !r.selected;
            return r;
        });

        setCalendarResources(resourcesItems);
        setEvent(EventFormData.fromEvent(currentEvent));
    }

    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
        setCalendarResources([]);
    }

    function onProgramChange(value: string | null | undefined) {
        const programId = value || null;
        setPageState(merge(pageState, {filter: {programId: programId}}));
        setCalendarResources([]);
    }

    function getSelectedResources() {
        return calendarResources.filter(r => r.selected);
    }

    const handleDatesSet = (dateInfo: DatesSetArg) => {
        console.info('handleDatesSet', dateInfo);

        const calendarView = dateInfo.view.type;
        setCalendarView(calendarView);

        const dates = prepareCalendarDates(calendarView, dateInfo.start, dateInfo.end);
        setCalendarDates(dates);
        console.info('dates', dates);
    };

    const resourceAreaColumns = [
        {
            field: 'org',
            group: true,
            headerContent: 'Org',
            cellContent: function(data: any) {
                const value = data.groupValue || data.fieldValue;
                return (
                    <CheckableTag
                        checked={false}
                        onClick={() => resourceGroupClickHandler({org: value})}
                    >{value}</CheckableTag>
                )
            }
        },
        {
            field: 'program',
            group: true,
            headerContent: 'Program',
            cellContent: function(data: any) {
                const value = data.groupValue || data.fieldValue;
                return (
                    <CheckableTag
                        checked={false}
                        onClick={() => resourceGroupClickHandler({program: value})}
                    >{value}</CheckableTag>
                )
            }
        },
        {
            field: 'site',
            group: false,
            headerContent: 'Site',
            cellContent: function(data: any) {
                const res = extractResource(data.resource);

                const eventOffset = res.eventOffset! > 0 ? `${formatMinutes(res.eventOffset!)}` : '0m';
                const eventMaxDuration = res.eventMaxDuration! > 0 ? `${formatMinutes(res.eventMaxDuration!)}` : '0m';
                const extra = (res.eventOffset! > 0 || res.eventMaxDuration! > 0) ? `${eventOffset}, ${eventMaxDuration}` : '';
        
                return (
                    <CheckableTag
                        checked={!!res?.selected}
                        onClick={() => resourceClickHandler(res)}
                    >
                        {res.site} { extra && <Tooltip title='Event offset, Event max duration'><Typography.Text code>{extra}</Typography.Text></Tooltip>}
                    </CheckableTag>
                )
            }
        }
    ];
    
    function resourceGroupClickHandler(value: {org: string} | {program: string}) {
        console.info('resourceGroupLabelClickHandler', value);

        if ('org' in value) {
            const items = calendarResources.map(r => {
                if (value.org === r.org) r.selected = !r.selected;
                return r;
            });
            setCalendarResources(items);
        }

        if ('program' in value) {
            const intersection = (arr1: string[], arr2: string[]) => arr1.filter(value => arr2.includes(value)).length;

            const items = calendarResources.map(r => {
                const selectedPrograms = value.program?.split(',');
                const resourcePrograms = r.program?.split(',') || [];

                if (intersection(selectedPrograms, resourcePrograms)) r.selected = !r.selected;

                return r;
            });
            setCalendarResources(items);
        }
    }

    function resourceClickHandler(resource: ICalendarResource) {
        const items = calendarResources.map(r => {
            if (r.id === resource.id) r.selected = !r.selected;
            return r;
        });

        setCalendarResources(items);
    }

    const extractResource = (data: any): ICalendarResource => {
        return {
            id: data?._resource?.id,
            selected: data?._resource?.extendedProps?.selected,
            site: data?._resource?.extendedProps?.site,
            org: data?._resource?.extendedProps?.org,
            program: data?._resource?.extendedProps?.program,
            eventOffset: data?._resource?.extendedProps?.eventOffset,
            eventMaxDuration: data?._resource?.extendedProps?.eventMaxDuration,
        }
    }

    const eventContentHandler = (props: EventContentArg) => {
        // if (props.view.type === "dayGridMonth") {
        //     const content = <>
        //         {props.event.title && <Typography.Paragraph>Programs: <Typography.Text strong>{props.event.title}</Typography.Text></Typography.Paragraph>}
        //         <Typography.Text>Events duration: <Typography.Text strong>{timeConvertor(props.event.extendedProps.duration)}</Typography.Text></Typography.Text>
        //     </>
        //     return <Popover content={content}>
        //         <div className='event-month-content'>
        //             <div className="fc-daygrid-event-dot" style={{borderColor: 'rgb(238, 238, 238)'}}></div>
        //             <div className='fc-event-time'>{props.timeText}</div>
        //             <div className='fc-event-title' style={{textOverflow: 'ellipsis'}}>{props.event.title}</div>
        //         </div>
        //     </Popover>
        // }
    }

    const NewEventButton = () => {
        const disabled = getSelectedResources().length === 0;
        return (
        <ControlledTooltip 
            visible={disabled} 
            title='Please select at least one site from the left section of the calendar'>
            <Button
                size="large"
                key="new-event"
                type="primary"
                onClick={showNewEventForm}
                disabled={disabled}
                icon={<PlusCircleOutlined />}
            >
                New Event
            </Button>
        </ControlledTooltip>
        )
    }

    return (
        <div className='page-events-calendar'>
            <PageHeader
                pageTitle='Events'
                extra={[
                    <EventsViewSwitch view='calendar' key='events-view-switch' />
                ]}
                actions={[
                    <ProgramSelector programId={pageState.filter?.programId} onChange={onProgramChange} key='events-program-selector'/>,
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='events-search' />,
                    actor.can('create', 'Event') && <NewEventButton key='events-create' />
                ]}
            />
            <div className='events-calendar'>
            <Spin spinning={sitesQuery.isLoading || eventsQuery.isLoading}>
                <FullCalendar
                    ref={calRef}
                    schedulerLicenseKey={config.FULL_CALENDER_LICENSE_KEY}
                    plugins={[dayGridPlugin, resourceTimelinePlugin, interactionPlugin]}
                    datesSet={handleDatesSet}
                    initialView="resourceTimelineDay"
                    eventMinWidth={5}
                    headerToolbar={{
                        left: 'today prev,next',
                        center: 'title',
                        right: '' // 'resourceTimelineDay,resourceTimelineWeek,dayGridMonth'
                    }}
                    nowIndicator
                    scrollTime={'09:00'}
                    height={'80vh'}
                    eventTimeFormat={{
                        hour: 'numeric',
                        minute: '2-digit',
                        meridiem: 'short'
                    }}
                    eventClick={onEventClick}
                    resourceGroupField={'site'}
                    resourceAreaWidth={'35%'}
                    resourceOrder='org, program, site'
                    resourceAreaColumns={resourceAreaColumns}
                    resources={calendarResources}
                    events={calendarEvents}
                    eventContent={eventContentHandler}
                />
            </Spin>
            </div>
            {event && <EventForm event={event} resources={getSelectedResources()} onClose={onEventFormClose}></EventForm>}
        </div>
    )
}