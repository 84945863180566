import './EventDeliveryStatus.css';

import Popover from 'antd/lib/popover';
import Space from 'antd/lib/space';
import format from 'date-fns/format';

import CloudOutlined from '@ant-design/icons/lib/icons/CloudOutlined';
import MailFilled from '@ant-design/icons/lib/icons/MailFilled';
import MailOutlined from '@ant-design/icons/lib/icons/MailOutlined';

import { ApiIcon } from '../../../components/ApiIcon';
import { IEvent } from '../../../domain/events/Event';

interface IEventDeliveryStatusProps {
    event: IEvent;
}

export function EventDeliveryStatus({event}: IEventDeliveryStatusProps) {
    const deliveryStatus = event.deliveryStatus;

    if (!deliveryStatus) return <></>;

    let alertPublishedAt: Date | null = null;
    let alertOpenedAt: Date | null = null;
    let alertConfirmedAt: Date | null = null;
    if (deliveryStatus.alert) {
        for (let [type, status] of Object.entries(deliveryStatus.alert)) {
            if (!alertPublishedAt && status?.publishedAt) alertPublishedAt = new Date(status.publishedAt);
            if (!alertOpenedAt && status?.openedAt) alertOpenedAt = new Date(status.openedAt);
            if (!alertConfirmedAt && status?.confirmedAt) alertConfirmedAt = new Date(status.confirmedAt);
        }
    }

    const formatDate = (val: string | null | undefined) => val ? format(new Date(val), 'yyyy-MM-dd hh:mm') : '';

    let alertDeliveryDetails: any = {};
    if (deliveryStatus.alert) {

        alertDeliveryDetails = {
            scheduled: {
                publishedAt: formatDate(deliveryStatus.alert?.scheduled?.publishedAt),
                openedAt: formatDate(deliveryStatus.alert?.scheduled?.openedAt),
                confirmedAt: formatDate(deliveryStatus.alert?.scheduled?.confirmedAt)
            },
            'pre-event': {
                publishedAt: formatDate(deliveryStatus.alert?.['pre-event']?.publishedAt),
                openedAt: formatDate(deliveryStatus.alert?.['pre-event']?.openedAt),
                confirmedAt: formatDate(deliveryStatus.alert?.['pre-event']?.confirmedAt)
            },
            event: {
                publishedAt: formatDate(deliveryStatus.alert?.event?.publishedAt),
                openedAt: formatDate(deliveryStatus.alert?.event?.openedAt),
                confirmedAt: formatDate(deliveryStatus.alert?.event?.confirmedAt)
            },
            completed: {
                publishedAt: formatDate(deliveryStatus.alert?.completed?.publishedAt),
                openedAt: formatDate(deliveryStatus.alert?.completed?.openedAt),
                confirmedAt: formatDate(deliveryStatus.alert?.completed?.confirmedAt)
            }
        }
    }

    const alertTable = (
        <table>
            <thead>
                <tr>
                    <th>Alert</th>
                    <th>Published</th>
                    <th><MailOutlined /> Opened</th>
                    <th><MailFilled /> Confirmed</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>scheduled</td>
                    <td>{alertDeliveryDetails.scheduled.publishedAt}</td>
                    <td>{alertDeliveryDetails.scheduled.openedAt}</td>
                    <td>{alertDeliveryDetails.scheduled.confirmedAt}</td>
                </tr>
                {event.preDurationMinutes
                ? <tr>
                    <td>pre-event</td>
                    <td>{alertDeliveryDetails['pre-event'].publishedAt}</td>
                    <td>{alertDeliveryDetails['pre-event'].openedAt}</td>
                    <td>{alertDeliveryDetails['pre-event'].confirmedAt}</td>
                </tr>
                : ''
                }
                <tr>
                    <td>event</td>
                    <td>{alertDeliveryDetails.event.publishedAt}</td>
                    <td>{alertDeliveryDetails.event.openedAt}</td>
                    <td>{alertDeliveryDetails.event.confirmedAt}</td>
                </tr>
                <tr>
                    <td>completed</td>
                    <td>{alertDeliveryDetails.completed.publishedAt}</td>
                    <td>{alertDeliveryDetails.completed.openedAt}</td>
                    <td>{alertDeliveryDetails.completed.confirmedAt}</td>
                </tr>
            </tbody>
        </table>
    )

    const eventTable = (
        <table>
            <thead>
                <tr>
                    <th>Event</th>
                    <th>Delivered</th>
                </tr>
            </thead>
            <tbody>
                {deliveryStatus.emerson
                ? <tr>
                    <td>Emerson</td>
                    <td>{formatDate(deliveryStatus.emerson.deliveredAt)}</td>
                </tr>
                : ''
                }
                <tr>
                    <td>API</td>
                    <td>{deliveryStatus.api ? formatDate(deliveryStatus.api.deliveredAt) : ''}</td>
                </tr>
            </tbody>
        </table>
    )

    const content = (
        <div className="event-delivery-status-popover">
            {alertTable}
            <p></p>
            {eventTable}
        </div>
    );

    const defaultDateFormat = 'hh:mm a MMM dd, yyyy';
    let AlertDate: React.ReactElement<any, any> | null = null;
    if (alertConfirmedAt) {
        AlertDate = <><MailFilled /> {format(alertConfirmedAt, defaultDateFormat)}</>;
    } else if (alertOpenedAt) {
        AlertDate = <><MailOutlined /> {format(alertOpenedAt, defaultDateFormat)}</>;
    }

    let EmersonDate: React.ReactElement<any, any> | null = null;
    if (deliveryStatus.emerson?.deliveredAt) {
        EmersonDate = <><CloudOutlined /> {format(new Date(deliveryStatus.emerson.deliveredAt), defaultDateFormat)}</>
    }

    let ApiDate: React.ReactElement<any, any> | null = null;
    if (deliveryStatus.api?.deliveredAt) {
        ApiDate = <><ApiIcon /> {format(new Date(deliveryStatus.api.deliveredAt), defaultDateFormat)}</>
    }

    if (!AlertDate && !EmersonDate && !ApiDate) return <></>

    return (
        <div className='event-delivery-status'>
            <Popover content={content}>
                <Space>
                {AlertDate}
                {EmersonDate}
                {ApiDate}
                </Space>
            </Popover>
        </div>
    )
}