import Button from 'antd/lib/button';
import Dropdown from 'antd/lib/dropdown';
import Menu from 'antd/lib/menu';
import Modal from 'antd/lib/modal';
import React, { useEffect } from 'react';

import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';

import { IEventForm } from '../../../domain/events/EventForm';
import { useEventListQuery } from '../../../domain/events/queries';

export interface IUpdateEventButtonProps {
    event: IEventForm;
    onClick: Function;
    disabled?: boolean;
}
export function UpdateEventButton({event, onClick, disabled = false}: IUpdateEventButtonProps) {
    const {data, isLoading, refetch} = useEventListQuery({
        pagination: { page: 1, size: 10000 },
        filter: { batch: event.batch || undefined }
    }, { enabled: false });

    useEffect(() => {
        if (event.batch) {
            console.info(`event has batch ${event.batch}`);
            refetch();
        }
    }, [event, refetch]);

    async function updateEventHandler() {
        Modal.confirm({
            title: 'UPDATE EVENT',
            icon: <ExclamationCircleOutlined />,
            content: `This event will be updated. Are you sure?`,
            okText: 'UPDATE',
            cancelText: 'CLOSE',
            async onOk() {
                onClick();
            },
            onCancel() {},
        });
    }

    async function updateEventBatchHandler() {
        Modal.confirm({
            title: 'UPDATE EVENTS',
            icon: <ExclamationCircleOutlined />,
            content: `All ${data?.meta.total} events will be updated. Are you sure?`,
            okText: 'UPDATE',
            cancelText: 'CLOSE',
            async onOk() {
                onClick(event.batch);
            },
            onCancel() {},
        });
    }

    function onMenuClick(arg: any) {
        updateEventBatchHandler();
    }

    if (event.batch) {

        if (data?.meta.total! === 1) {
            return (
                <Button onClick={updateEventHandler} key='event-modal-update' type='primary' disabled={disabled}>Update event</Button>
            )
        }

        const menu = (
            <Menu onClick={onMenuClick}
                items={[
                    {
                        key: '1',
                        label: `Update the batch of ${data?.meta.total} events`,
                    }
                ]}
            />
        );

        return (
            <Dropdown.Button 
                overlay={menu} 
                type='primary' 
                className='update-event-button' 
                buttonsRender={([leftButton, rightButton]) => [
                    React.cloneElement(leftButton as React.ReactElement<any, string>),
                    React.cloneElement(rightButton as React.ReactElement<any, string>, { loading: isLoading }),
                ]}
                disabled={disabled}
                onClick={updateEventHandler}>
                Update event
            </Dropdown.Button>
        )
    }

    return (
        <Button onClick={updateEventHandler} key='event-modal-update' type='primary' disabled={disabled}>Update event</Button>
    )
}