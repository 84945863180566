import { ReactNode } from 'react';
import Button from 'antd/lib/button';
import Tooltip from 'antd/lib/tooltip';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import './ActionList.css';

interface IItem {
    id: string;
}


export interface IActionListProps {
    item: IItem,
    actions: {
        icon?: ReactNode | (() => string),
        title: string | ((item: any) => string),
        onClick: (item: any) => void
    }[],
    children?: any
}

interface IRenderIcon {
    item: any,
    icon?: ReactNode | Function,
    title: string
}

const ICON: { [key: string]: ReactNode } = {
    Edit: <EditOutlined />,
    Delete: <DeleteOutlined />
};

const renderIcon = ({ icon, title, item }: IRenderIcon) => {
    let iconToRender = (typeof icon === 'function') ? icon(item) : icon;
    const actionIcon = ICON[title];
    return icon ? iconToRender : actionIcon;
}

export const ActionList = ({ item, actions, children }: IActionListProps) => {

    return (
        <div className='action'>
            {children}
            {actions.map(action => {
                const actionTitle = (typeof action.title === 'function') 
                    ? action.title(item) 
                    : action.title;

                return (
                    <Tooltip title={actionTitle} key={`${item.id}-${actionTitle}`}>
                        <Button
                            type='text'
                            onClick={() => action.onClick(item)}
                            icon={renderIcon({ ...action, title: actionTitle as string, item }) || <EditOutlined />}
                        />
                    </Tooltip>
                )
            })}
        </div>
    )
};