import { ISite, ISiteWithPrices } from '../sites/Site';
import { IPrices } from './Prices';

const stateMap: Record<string, keyof IPrices> = {
    // states
    'New South Wales': 'NSW',
    'Victoria': 'VIC',
    'Queensland': 'QLD',
    'Western Australia': 'WA',
    'South Australia': 'SA',
    'Tasmania': 'TAS',
    // internal territories
    'Australian Capital Territory': 'NSW',
    'Northern Territory': 'NT'
}

export function mapPricesToSites(sites: ISite[], prices?: IPrices): ISiteWithPrices[] {
    if (!sites?.length) return [];

    return sites.map(site => {
        const stateCode = stateMap[site.location.state] || null;
        const statePrices = prices?.[stateCode] || {realtime: null, dayahead: null};
        return {
            ...site,
            prices: statePrices
        }
    })
}