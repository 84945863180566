import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import { AuditLog } from '../../components/AuditLog/AuditLog';
import { useAuth } from '../../domain/auth/useAuth';
import { useUtilitySaveQuery } from '../../domain/utilities/queries';
import { IUtility, IUtilityForm } from '../../domain/utilities/Utility';

interface IUtilityFormProps {
    utility: IUtilityForm;
    onClose: (utility: IUtilityForm | null) => any;
}

export function UtilityForm({ utility, onClose }: IUtilityFormProps) {
    const {actor} = useAuth()!;
    const [form] = Form.useForm<IUtilityForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const saveUtilityQuery = useUtilitySaveQuery();

    const isNew = utility.id === null;

    async function onFormFinish(utility: IUtilityForm) {
        console.info(`onFormFinish=`, utility);
        try {
            setLoading(true);
            await saveUtilityQuery.mutateAsync(utility);

            notification.info({ key: 'utility-save-info', message: 'Utility saved' });
            onClose(utility);
        } catch (err: any) {
            notification.error({ key: 'utility-save-error', message: err.message || 'Cannot save utility!' });
        }
        setLoading(false);
    }

    return (
        <Modal
            title={isNew ? 'Create utility' : 'Edit utility'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='utility-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='utility-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='utility-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={utility}
                className='utility-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>

                <Form.Item
                    name='title'
                    label={<Typography.Text strong>Title</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter title!' },
                        { max: 100, message: 'Number of characters should be less than 255' }
                    ]}>
                    <Input placeholder='Title' size='large' />
                </Form.Item>
                
            </Form>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='utility' entityId={utility.id!} /> : '' }
        </Modal>
    )
}

export class UtilityFormData implements IUtilityForm {
    id: string | null;
    title: string | null;
    constructor(id: string | null, title: string | null) {
        this.id = id;
        this.title = title;
    }

    static fromUtility(utility: IUtility) {
        return new UtilityFormData(utility.id, utility.title);
    }

    static fromDefault() {
        return new UtilityFormData(null, null);
    }
}