import { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { useUserQuery } from '../users/queries';
import { Actor, GuestActor, IActor } from './Actor';
import { Auth, SigninData } from './auth';
import { Session } from './Session';

interface IUseAuth {
    actor: IActor;
    signin(data: SigninData): Promise<Actor>;
    signout(): void;
    refresh(): Promise<void>;
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [actor, setActor] = useState<IActor>(Session.getActor());
    console.info('AuthProvider', actor);
    console.info('AuthProvider actor', actor.isAuthenticated());

    const { data, refetch } = useUserQuery(actor.id, { enabled: false });

    useEffect(() => {
        if (data) {
            let update = {
                ...actor,
                fullname: data.fullname,
                phone: data.phone,
                notificationSettings: data.notificationSettings,
                twoFactor: data.twoFactor,
            }
            const updatedActor = new Actor(update);

            setActor(updatedActor);
            Session.setActor(updatedActor);
        }
    }, [data]);

    const signin = async (data: SigninData) => {
        try {
            const actor = await Auth.signin(data);
            setActor(actor);
            return actor;
        } catch (err) {
            setActor(new GuestActor());
            throw err;
        }
    };

    const signout = () => {
        Auth.signout();
        setActor(new GuestActor());
    };

    const refresh = async () => {
        refetch()
    }

    const auth = {
        actor,
        signin,
        signout,
        refresh,
    }

    return (
        <AuthContext.Provider value={auth}>
            <BrowserRouter>
                {children}
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

const AuthContext = createContext<IUseAuth>(undefined!);

export const useAuth = () => {
    return useContext(AuthContext);
}