import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IAppToken, IAppTokenForm } from './AppToken';

const KEY = 'app-token';

export interface IAppTokenListQuery extends IListQuery {}

async function getAppToken(appTokenId: string): Promise<IAppToken> {
    return await API.fetch<IAppToken>(`/api/v1/app-tokens/${appTokenId}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function getAppTokens(query: IAppTokenListQuery): Promise<WithPagination<IAppToken>> {
    const searchParams = makeURLSearchParams(query);
    return await API.fetch<WithPagination<IAppToken>>(`/api/v1/app-tokens?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createAppToken(appToken: IAppTokenForm) {
    const data: any = {
        permissions: appToken.permissions
    };

    return await API.fetch<IAppToken>(`/api/v1/app-tokens`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updateAppToken(appToken: IAppTokenForm) {
    const data: any = {
        enabled: appToken.enabled
    };

    return await API.fetch<IAppToken>(`/api/v1/app-tokens/${appToken.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function saveAppToken(appToken: IAppTokenForm) {
    return (appToken.id) ? await updateAppToken(appToken) : await createAppToken(appToken);
}

async function deleteAppToken(appToken: IAppToken) {
    await API.fetch(`/api/v1/app-tokens/${appToken.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

export function useAppTokenListQuery(query: IAppTokenListQuery) {
    return useQuery([KEY, query], () => getAppTokens(query));
}

export function useAppTokenQuery(appTokenId: string, opts: any = {}) {
    return useQuery([KEY, appTokenId], () => getAppToken(appTokenId), { ...opts });
}

export function useUserCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((appToken: IAppTokenForm) => createAppToken(appToken), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function useAppTokenUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((appToken: IAppTokenForm) => updateAppToken(appToken), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function useAppTokenSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((appToken: IAppTokenForm) => saveAppToken(appToken), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function useAppTokenDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((appToken: IAppToken) => deleteAppToken(appToken), {
        onSuccess() {
            return queryClient.invalidateQueries(['user'])
        }
    });
}