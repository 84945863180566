import { ISiteWithPrices } from '../../../domain/sites/Site';
import { colorByPrice } from './priceRange';

export const makeMarker = (site: ISiteWithPrices) => {
    const position = { lat: site.location.lat, lng: site.location.long };
    const fillColor = colorByPrice(site.prices?.realtime || 0);

    return {
        key: site.id,
        position,
        title: site.title,
        icon: {
            path: google.maps.SymbolPath.CIRCLE,
            fillColor: fillColor,
            fillOpacity: 1,
            scale: 7,
            strokeColor: 'white',
            strokeWeight: 2
        }
    }
}