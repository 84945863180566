import './OrgForm.css';

import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Radio from 'antd/lib/radio';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import { AuditLog } from '../../components/AuditLog/AuditLog';
import { EmersonSettings } from '../../components/EmersonSettings/EmersonSettings';
import { useAuth } from '../../domain/auth/useAuth';
import { Emerson } from '../../domain/emerson/Emerson';
import { IOrg, IOrgForm, OrgType } from '../../domain/orgs/Org';
import { useOrgSaveQuery } from '../../domain/orgs/queries';

interface IOrgFormProps {
    org: IOrgForm;
    onClose: (org: IOrgForm | null) => any;
}

export function OrgForm({ org, onClose }: IOrgFormProps) {
    const {actor} = useAuth();
    const [form] = Form.useForm<IOrgForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const saveOrgQuery = useOrgSaveQuery();

    const isNew = org.id === null;

    async function onFormFinish(org: IOrgForm) {
        console.info(`*onFormFinish=`, org);
        try {
            setLoading(true);
            await saveOrgQuery.mutateAsync(org);

            notification.info({ key: 'org-save-info', message: 'Organization saved' });
            onClose(org);
        } catch (err: any) {
            notification.error({ key: 'org-save-error', message: err.message || 'Cannot save organization!' });
        }
        setLoading(false);
    }

    function onEmersonChange(apiKey: string | null) {
        console.info(`onEmersonChange`, apiKey);
        form.setFieldsValue({ emerson: apiKey ? {apiKey} : null});
    }

    const type = Form.useWatch('type', form);

    return (
        <Modal
            title={isNew ? 'Create organization' : 'Edit organization'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='org-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='org-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='org-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={org}
                className='org-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>

                <Form.Item
                    name='title'
                    label={<Typography.Text strong>Title</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter title!' },
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='Title' size='large' />
                </Form.Item>
                <Form.Item
                    name='type'
                    required
                    label={<Typography.Text strong>Type</Typography.Text>}
                >
                    <Radio.Group disabled={isNew === false} >
                        <Space direction='vertical'>
                            <Radio value={OrgType.GENERIC}>Generic</Radio>
                            <Radio value={OrgType.PROVIDER}>Provider</Radio>
                        </Space>
                    </Radio.Group>
                </Form.Item>
                {type === OrgType.GENERIC && actor.isAdminGroup()
                    ? (
                    <Form.Item name='emerson'>
                        <EmersonSettings orgId={org.id} emerson={org.emerson} onChange={onEmersonChange} />
                    </Form.Item>
                    )
                    : <></>
                }
            </Form>
            <p></p>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='org' entityId={org.id!} /> : '' }
        </Modal>
    )
}


export class OrgFormData implements IOrgForm {
    id: string | null;
    title: string | null;
    type: OrgType;
    emerson: Emerson | null;
    constructor(id: string | null, title: string | null, type: OrgType, emerson: Emerson | null) {
        this.id = id;
        this.title = title;
        this.type = type;
        this.emerson = emerson;
    }

    static fromOrg(org: IOrg) {
        return new OrgFormData(org.id, org.title, org.type, org.emerson);
    }

    static fromDefault() {
        return new OrgFormData(null, null, OrgType.GENERIC, {apiKey: ''},);
    }
}