import './AppTokenForm.css';

import { Alert, Descriptions, Select } from 'antd';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Typography from 'antd/lib/typography';
import Paragraph from 'antd/lib/typography/Paragraph';
import { useState } from 'react';

import { AppTokenPermission, IAppToken, IAppTokenForm } from '../../../domain/app-tokens/AppToken';
import { useAppTokenSaveQuery } from '../../../domain/app-tokens/queries';
import { useAuth } from '../../../domain/auth/useAuth';

interface IAppTokenFormProps {
    appToken: IAppTokenForm;
    onClose: (entity: IAppTokenForm | null) => any;
}

export function AppTokenForm({ onClose }: IAppTokenFormProps) {
    const [form] = Form.useForm<IAppTokenForm>();
    const [newAppToken, setNewAppToken] = useState<IAppToken | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const {actor} = useAuth()!;
    const saveAppTokenQuery = useAppTokenSaveQuery();

    const appTokenDto = {
        permissions: [AppTokenPermission.EVENT_READ],
        enabled: true,
    }

    const allowedPermissions = [AppTokenPermission.EVENT_READ];

    async function onFormFinish(entity: IAppTokenForm) {
        console.info(`onFormFinish=`, entity);
        try {
            setLoading(true);
            const token = await saveAppTokenQuery.mutateAsync(entity);
            setNewAppToken(token);

            notification.info({ key: 'app-token-save-info', message: 'App token saved' });
        } catch (err: any) {
            notification.error({ key: 'app-token-save-error', message: err.message || 'Cannot save app token!' });
        }
        setLoading(false);
    }

    async function copyAppTokenToClipboard() {
        if (!newAppToken) return;
        await navigator.clipboard.writeText(`ClientId: ${newAppToken.id} ClientSecret: ${newAppToken.clientSecret}`);
        notification.info({ key: 'app-token-copy-info', message: 'App token copied' });
    }

    const footer: JSX.Element[] = [
        <Button onClick={() => onClose(null)} key='app-token-modal-cancel' disabled={loading}>Close</Button>
    ];

    if (!newAppToken) {
        footer.push(
            <Button onClick={form.submit} key='app-token-modal-submit' type='primary' loading={loading}>Create</Button>
        )
    } else {
        footer.push(
            <Button onClick={copyAppTokenToClipboard} key='app-token-modal-copy' type='primary' loading={loading}>Copy to Clipboard</Button>
        )
    }

    return (
        <Modal
            title={newAppToken ? 'New App Token' : 'Create app token'}
            width={600}
            visible={true}
            destroyOnClose={true}
            maskClosable={false}
            keyboard={false}
            onCancel={() => onClose(null)}
            footer={footer}
        >
            {newAppToken &&
                <>
                    <Descriptions title="" bordered column={1}>
                        <Descriptions.Item label="Client Id">{newAppToken.id}</Descriptions.Item>
                        <Descriptions.Item label="Client Secret">{newAppToken.clientSecret}</Descriptions.Item>
                        <Descriptions.Item label="Permissions">{newAppToken.permissions}</Descriptions.Item>
                    </Descriptions>
                    <p></p>
                    <Alert message="Copy Client Id and Client Secret. When you close this dialog the Client Secret will not be available." type="info" showIcon />
                    <p></p>
                </>
            }
            {!newAppToken &&
                <Form
                    form={form}
                    name='app-token-form'
                    preserve={false}
                    layout='vertical'
                    onFinish={onFormFinish}
                    initialValues={appTokenDto}
                    className='app-token-form'
                >
                    <Form.Item
                        name='permissions'
                        shouldUpdate
                        label={<Typography.Text strong>Permissions</Typography.Text>}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Please select at least one permission' }
                        ]}
                    >
                        <Select 
                            size='large' 
                            className='app-token-permissions-select' 
                            mode="multiple"
                            allowClear
                        >
                            {allowedPermissions.map(permission => (
                                <Select.Option key={`permission-${permission}`} value={permission} >{permission}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Form>
            }
            
        </Modal>
    )
}

export class AppTokenFormData implements IAppTokenForm {
    constructor(
        public id: string | null, 
        public permissions: string[], 
        public enabled: boolean) {}

    static fromEntity(entity: IAppToken) {
        return new AppTokenFormData(entity.id, entity.permissions, entity.enabled);
    }

    static fromDefault() {
        return new AppTokenFormData(null, [], true);
    }
}