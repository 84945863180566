const local: IConfig = {
    API_URL: 'http://localhost:5000',
    WEATHER_API_KEY: '925c08da30c6072054f69a2939466aeb',
    GOOGLE_MAP_API: 'AIzaSyB88xDXaB-kxbBY3YWxxMSvQCZvMEHsF2Q',
    RECAPTCHA_SITE_KEY: '6LfP9IIiAAAAAMYuNFtmLthMgxxMcus5w7CzdPcY',
    FULL_CALENDER_LICENSE_KEY: '0659641500-fcs-1658853274'
};

const stg: IConfig = {
    API_URL: 'https://api.stg.daydreamenergy.com',
    WEATHER_API_KEY: '925c08da30c6072054f69a2939466aeb',
    GOOGLE_MAP_API: 'AIzaSyB88xDXaB-kxbBY3YWxxMSvQCZvMEHsF2Q',
    RECAPTCHA_SITE_KEY: '6LfP9IIiAAAAAMYuNFtmLthMgxxMcus5w7CzdPcY',
    FULL_CALENDER_LICENSE_KEY: '0659641500-fcs-1658853274'
};

const production: IConfig = {
    API_URL: 'https://api.daydreamenergy.com',
    WEATHER_API_KEY: 'a388f8086f3820d2f8bddcb331fb9f58',
    GOOGLE_MAP_API: 'AIzaSyB88xDXaB-kxbBY3YWxxMSvQCZvMEHsF2Q',
    RECAPTCHA_SITE_KEY: '',
    FULL_CALENDER_LICENSE_KEY: '0659641500-fcs-1658853274'
};

interface IConfig {
    API_URL: string;
    WEATHER_API_KEY: string;
    GOOGLE_MAP_API: string;
    RECAPTCHA_SITE_KEY: string;
    FULL_CALENDER_LICENSE_KEY: string;
}

let config: IConfig = {...stg};
switch (process.env.REACT_APP_STAGE) {
    case 'local': config = {...local}; break;
    case 'stg': config = {...stg}; break;
    case 'production': config = {...production}; break;
    default:
        config = {...stg};
}

export {config};