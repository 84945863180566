import { useState } from 'react';
import notification from 'antd/lib/notification';
import Button from 'antd/lib/button';
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined';

export interface IExportButtonProps {
    fn: () => Promise<void>;
    children: any;
}

export const ExportButton = ({fn, children}: IExportButtonProps) => {
    const [loading, setLoading] = useState(false);

    async function handleExport() {
        setLoading(true);
        try {
            await fn();
        } catch (err: any) {
            notification.error({ key: 'sites-export-error', message: err?.message || 'Cannot export sites!' });
        }
        setLoading(false);
    }

    return (
        <Button 
            onClick={handleExport} 
            size='large' 
            key='export-sites' 
            type='default' 
            icon={<DownloadOutlined />} 
            loading={loading}
        >
            {children}
        </Button>
    )
}