import { config } from '../../config';

export interface ILocation {
    address: string;
    city: string;
    county: string;
    state: string;
    zip: string;
    country: string;
    lat: number;
    long: number;
    timezone: string;
    googlePlaceId?: string | null;
}


export async function parseGooglePlace(place: google.maps.places.PlaceResult): Promise<ILocation | null> {
    if (!place?.address_components) return null;

    const location: any = {
        address: place?.formatted_address || undefined,
        city: undefined,
        county: undefined,
        state: undefined,
        zip: undefined,
        country: undefined,
        lat: undefined,
        long: undefined,
        timezone: undefined,
        googlePlaceId: undefined,
    };

    const components: any = {};
    for (let component of place.address_components) {
        components[component.types[0]] = component;
    }

    if ('street_number' in components) {
        location.address = components['street_number']?.long_name + ' ' + components['route']?.long_name;
    } else {
        location.address = components['route']?.long_name;
    }

    if ('locality' in components) {
        location.city = components['locality']?.long_name;
    } else if ('city' in components) {
        location.city = components['locality']?.long_name;
    } else {
        location.city = components['sublocality_level_1']?.long_name;
    }

    location.state = components['administrative_area_level_1']?.long_name;
    location.country = components['country']?.long_name;
    location.zip = components['postal_code']?.long_name ? components['postal_code']?.long_name : null;
    location.county = components['administrative_area_level_2']?.long_name || null;

    location.googlePlaceId = place?.place_id || null;

    if (place?.geometry?.location) {
        location.lat =  place?.geometry?.location?.lat();
        location.long = place?.geometry?.location?.lng();

        location.timezone = await getTimezoneByLatLng(location.lat, location.long);
    }

    if (location.address 
        && location.city 
        && location.state 
        && location.zip 
        && location.country 
        && typeof location.lat !== 'undefined' 
        && location.long !== 'undefined' 
        && location.timezone) {
        return location;
    }
    console.info(`invalid location`, location);
    return null;
}

async function getTimezoneByLatLng(lat: number, lng: number): Promise<string> {
    try {
        interface ITimezoneResponse {
            dstOffset: number;
            rawOffset: number;
            status: 'OK' | 'INVALID_REQUEST' | 'OVER_DAILY_LIMIT' | 'OVER_QUERY_LIMIT' | 'REQUEST_DENIED' | 'UNKNOWN_ERROR' | 'ZERO_RESULTS';
            timeZoneId: string;
            timeZoneName: string;
        }

        const currentTimestamp = (Math.round((new Date().getTime()) / 1000));
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${currentTimestamp.toString()}&key=${config.GOOGLE_MAP_API}`;

        const response = await fetch(url, { mode: 'cors' });

        const respBody: ITimezoneResponse = await response.json();

        if (response.ok && respBody.status === 'OK') {
            return respBody.timeZoneId;
        }

        return '';
    } catch (err) {
        console.error('getTimezoneByLatLng', err);
        return '';
    }
}