import './UtilitySelector.css';

import Select from 'antd/lib/select';
import { useState } from 'react';

import { useUtilityListQuery } from '../../domain/utilities/queries';
import { IUtility } from '../../domain/utilities/Utility';

export interface IUtilitySelectorProps {
    utilityId?: string | null;
    disabled?: boolean;
    onChange: (utilityId: string | null) => void
}

export const UtilitySelector = (props: IUtilitySelectorProps) => {
    const {data, isLoading} = useUtilityListQuery({pagination: {page: 1, size: 1000}});
    const utilities = data?.data || [];

    const [id, setId] = useState(() => {
        if (props.utilityId) return props.utilityId;
        return undefined;
    });

    function onChange(utilityId: string) {
        setId(utilityId);
        props.onChange(utilityId);
    }

    function makeOptionsElements(utilities: IUtility[]) {
        const items = utilities.map(utility => {
            return {
                recent: false,
                value: utility.id,
                key: `utility-${utility.id}`,
                label: utility.title
            }
        });

        return items.map(item => makeOptionElement(item));
    }

    function makeOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.label}
            </Select.Option>
        )
    }

    return (
        <Select
            loading={isLoading}
            disabled={props.disabled}
            size='large'
            placeholder='Please select utility'
            value={isLoading ? '' : id}
            onChange={onChange}
            className='utility-selector'
            bordered={true}
            showSearch
            allowClear
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeOptionsElements(utilities)}
        </Select>
    )
}