import './AuditLog.css';

import Collapse from 'antd/lib/collapse';
import Empty from 'antd/lib/empty';
import Timeline from 'antd/lib/timeline';
import Typography from 'antd/lib/typography';
import format from 'date-fns/format';

import AuditOutlined from '@ant-design/icons/lib/icons/AuditOutlined';
import CaretRightOutlined from '@ant-design/icons/lib/icons/CaretRightOutlined';
import CopyOutlined from '@ant-design/icons/lib/icons/CopyOutlined';
import LoadingOutlined from '@ant-design/icons/lib/icons/LoadingOutlined';

import { IAuditRecord } from '../../domain/audit/AuditRecord';
import { useAuditLogQuery } from '../../domain/audit/queries';

export interface IAuditLog {
    entity: string;
    entityId: string;
}

export const AuditLog = ({entity, entityId}: IAuditLog) => {

    const {data, isLoading} = useAuditLogQuery({entity, entityId});

    const makeTimelineItem = (record: IAuditRecord, index: number) => {
        const datetime = format(new Date(record.createdAt), 'hh:mm a MMM dd, yyyy');

        return (
            <Timeline.Item key={`audit-log-${index}`}>
                <Typography.Text type='secondary'>{datetime}</Typography.Text>
                <p>{record.message}</p>
                {record.context ? <pre>{JSON.stringify(record.context, null, 2)}</pre> : ''}
            </Timeline.Item>
        )
    }

    const CopyAuditToBuffer = () => {
        if (!data?.data?.length) return <></>;

        let text = '';

        data?.data.forEach(record => {
            text += `${record.createdAt}\n`;
            text += `${record.message}\n`;
            if (record.context) {
                text += `${JSON.stringify(record.context, null, 2)}\n`;
            }
            text += `\n`;
        });

        return (
            <Typography.Text
                className='copy-audit-log'
                copyable={{
                    text: text,
                    icon: [<CopyOutlined key="copy-icon" />, <CopyOutlined key="copy-icon" />],
                    tooltips: ['Copy Audit log', 'Copy Audit log'],
                }}
            ></Typography.Text>
        )
    }

    return (
        <Collapse
            className='audit-log'
            bordered={false}
            defaultActiveKey={[]}
            expandIconPosition='end'
            expandIcon={({ isActive }: any) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
        >
            <Collapse.Panel 
                header={<div className='audit-log-title'><AuditOutlined />Audit Log</div>} 
                extra={isLoading ? <LoadingOutlined style={{ fontSize: 14 }} spin /> : <CopyAuditToBuffer />}
                key='audit-log'
            >
                {!data?.data?.length
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                : <Timeline mode='left' className='audit-log' reverse>
                    {data?.data.map(makeTimelineItem)}
                </Timeline>
                }
            </Collapse.Panel>

        </Collapse>
    )
}