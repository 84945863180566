import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import notification from 'antd/lib/notification';
import Result from 'antd/lib/result';
import Typography from 'antd/lib/typography';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth } from '../../domain/auth/auth';

export const ResetPassword = () => {
    const [loading, setLoading] = useState(false);
    const [isDone, setIsDone] = useState<boolean>(false);
    const [form] = Form.useForm();

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Reset Password | Daydream Energy';
    }, []);

    const onFinish = async (values: {email: string}) => {
        setLoading(true);
        try {
            console.info('onFinish', values);
            await Auth.resetPassword(values);
            setIsDone(true);
        } catch (error: any) {
            notification.error({
                key: 'reset-password-error',
                message: 'Reset Password Error',
                description: error.message,
            });
        }
        setLoading(false);
    };

    return (
        <div className='reset-password'>
            <img className='logo' src='/logo.png' alt='Daydream Energy logo' />
            {isDone
            ? (
                <Result 
                    status="success" 
                    title="Email was successfully sent." 
                    subTitle="Please check your email with an instruction to set your new password." 
                    extra={
                        <Button type="link" onClick={() => navigate('/login')}>Return to Login</Button>
                    }
                />
            )
            : (
                <>
                    <Typography.Title level={2}>Reset Password</Typography.Title>
                    <Form form={form} onFinish={onFinish}>
                        <Form.Item noStyle>
                            <h3>Please enter your email address. We'll send you instruction how to reset your password.</h3>
                            <br/>
                        </Form.Item>
                        <Form.Item
                            name='email'
                            rules={[{ required: true, message: 'Please enter your email' }]}
                        >
                            <Input placeholder='Email' autoFocus size='large' />
                        </Form.Item>
                        
                        <Form.Item noStyle>
                            <Button type='primary' block htmlType='submit' loading={loading} size='large'>SUBMIT</Button>
                        </Form.Item>
                    </Form>
                    <Button type="link" onClick={() => navigate('/login')} className='extra-action' >Return to Login</Button>
                </>
            )}
        </div>
    )
}