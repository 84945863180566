import './TotalCountBadge.css';

import Badge from 'antd/lib/badge';

export interface ITotalCountBadgeProps {
    val?: number;
}

export const TotalCountBadge = ({ val }: ITotalCountBadgeProps) => {
    return <Badge count={val || 0} overflowCount={999999} className='total-count-badge' />
}