import { Alert, Button, Col, Row, Tooltip } from 'antd';
import Card from 'antd/lib/card';
import Input from 'antd/lib/input';
import { useState } from 'react';

import CloudSyncOutlined from '@ant-design/icons/lib/icons/CloudSyncOutlined';
import SyncOutlined from '@ant-design/icons/lib/icons/SyncOutlined';

import { Emerson } from '../../domain/emerson/Emerson';
import { useOrgSyncEmersonQuery } from '../../domain/orgs/queries';

interface IEmersonSettingsProps {
    orgId: string | null;
    emerson: Emerson | null;
    disabled?: boolean;
    onChange: (apiKey: string | null) => void;
}

export const EmersonSettings = (props: IEmersonSettingsProps) => {
    const [apiKey, setApiKey] = useState<string>(props.emerson?.apiKey || '');
    const [errorMessage, setErrorMessage] = useState<string | null>(props.emerson?.error || null);
    const disabled = props.disabled || false;
    const orgSyncEmersonQuery = useOrgSyncEmersonQuery();

    function onChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const apiKey = e.target.value || '';
        setApiKey(apiKey);
        props.onChange(apiKey || null);
    }

    async function onSyncClick() {
        if (!props.orgId) return;

        const result = await orgSyncEmersonQuery.mutateAsync(props.orgId);
        if ('error' in result) {
            setErrorMessage(result.error);
        } else {
            setErrorMessage(null);
        }
    }

    return (
        <Card bordered={true} className='settings-card'>
            <Card.Meta
                avatar={<CloudSyncOutlined />}
                title="Emerson"
                description="Set the organization Emerson API key. So that we can send events to the Emerson provider"
            />
            <p></p>
            <Row gutter={8}>
                <Col span={props.orgId ? 19 : 24}>
                    <Input.Password 
                        placeholder='API Key' 
                        size='large' 
                        value={apiKey} 
                        onChange={onChange} 
                        disabled={disabled}
                        maxLength={36}
                        allowClear
                    />
                </Col>
                {props.orgId 
                ? <Col span={5}>
                    <Tooltip title='Click to synchronize sites with Emerson'>
                        <Button icon={<SyncOutlined />} size='large' style={{width: '100%'}} onClick={onSyncClick} loading={orgSyncEmersonQuery.isLoading} disabled={!apiKey}>Sync</Button>
                    </Tooltip>
                </Col>
                : <></>
                }
            </Row>
            {errorMessage && <Alert message={errorMessage} type="warning" showIcon style={{marginTop: '8px'}}/>}
        </Card>
    )
}