import { SortOrder } from 'antd/lib/table/interface';

export function makeSorter<T>(
    sort: {
        field: string;
        order: 'asc' | 'desc';
    } | undefined,
    property: Extract<keyof T, string | number | Date>
) {

    let sortOrder: SortOrder | undefined = undefined;
    if (sort?.field === property) {
        sortOrder = sort.order === 'asc' ? 'ascend' : 'descend';
    }

    return {
        sortOrder,
        sorter(objectA: T, objectB: T) {
            if (objectA[property] > objectB[property]) {
                return 1;
            } else if (objectA[property] < objectB[property]) {
                return -1;
            } else {
                return 0;
            }
        }
    }
}