import './ProgramSelector.css';

import Select from 'antd/lib/select';
import Tag from 'antd/lib/tag';
import { useEffect, useState } from 'react';

import { IProgram } from '../../domain/programs/Program';
import { useProgramListQuery } from '../../domain/programs/queries';

export interface IProgramSelectorProps {
    programId?: string;
    onChange: (programId: string | null) => void
}

export const ProgramSelector = ({programId, onChange}: IProgramSelectorProps) => {
    const {data, isLoading} = useProgramListQuery({pagination: {page: 1, size: 1000}});
    const programs = data?.data || [];

    const [selected, setSelected] = useState<string | null>(null);

    useEffect(() => {
        if (!isLoading) {
            setSelected(programId || null)
        }
    }, [isLoading, programId]);

    function onChangeHandler(id: string) {
        setSelected(id);
        onChange(id);
    }

    function makeProgramOptionsElements(programs: IProgram[]) {
        const items = programs.map(program => {
            return {
                recent: false,
                value: program.id,
                key: `program-${program.id}`,
                label: program.title
            }
        });

        return items.map(item => makeProgramOptionElement(item));
    }

    function makeProgramOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.label}
            </Select.Option>
        )
    }

    const tagRender = (props: any) => {
        const { label, value, closable, onClose } = props;

        const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
            event.preventDefault();
            event.stopPropagation();
        };

        return (
            <Tag
                key={`key-${value}`}
                color={'purple'}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
            >
                {label}
            </Tag>
        );
    };

    return (
        <Select
            disabled={isLoading}
            loading={isLoading}
            size='large'
            placeholder='Please select program'
            // mode='multiple'
            tagRender={tagRender}
            // @ts-ignore
            value={selected}
            onChange={onChangeHandler}
            className='program-selector'
            bordered={true}
            showSearch
            allowClear
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            {makeProgramOptionsElements(programs)}
        </Select>
    )
}