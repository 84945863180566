import { TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import isEqual from 'lodash.isequal';

export interface ITableParams<T> {
    pagination: TablePaginationConfig
    filter: any;
    sorter: SorterResult<T> | SorterResult<T>[];
    extra: TableCurrentDataSource<T>
}

export function tableParamsToPageState<T>(params: ITableParams<T>) {
    console.info('tableParamsToPageState pagination=', params.pagination);
    console.info('tableParamsToPageState filter=', params.filter);
    console.info('tableParamsToPageState sorter=', params.sorter);
    console.info('tableParamsToPageState extra=', params.extra);

    const pagination: any = {
        page: params.pagination?.current || 1,
        size: params.pagination?.pageSize || 20
    }

    const sort = parseSort<T>(params.sorter);

    const filter = parseFilter(params.filter);

    return {
        pagination,
        sort,
        filter
    }
}

function parseSort<T>(sorter: SorterResult<T> | SorterResult<T>[]): {field: string; order: 'asc' | 'desc'} | undefined {

    let sortField: string | undefined = undefined;
    let sortOrder: 'asc' | 'desc' | undefined = undefined;

    const sort = Array.isArray(sorter) ? sorter[0] : sorter;
    if (sort) {
        sortField = Array.isArray(sort.field) ? sort.field[0] : sort.field;
        sortOrder = sort.order === undefined ? undefined : sort.order === 'ascend' ? 'asc' : 'desc' ;

        if (sortOrder === undefined) {
            sortField = undefined;
        }
    }

    if (sortField && sortOrder) {
        return {field: sortField, order: sortOrder}
    }
}

function parseFilter(filter: any) {
    const res: any = {};

    if ('type' in filter && filter.type) {
        res.type = filter.type.join(',');
    }

    if ('role' in filter && filter.role) {
        res.role = filter.role.join(',');
    }

    if (isEqual(res, {})) return undefined;

    return res;
}