import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Table, { TablePaginationConfig } from 'antd/lib/table';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import { useEffect, useState } from 'react';

import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { DatetimePopover } from '../../components/DatetimePopover/DatetimePopover';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { IAppToken, IAppTokenForm } from '../../domain/app-tokens/AppToken';
import { useAppTokenDeleteQuery, useAppTokenListQuery } from '../../domain/app-tokens/queries';
import { useAuth } from '../../domain/auth/useAuth';
import { usePageState } from '../../domain/shared/usePageState';
import { AppTokenForm, AppTokenFormData } from './AppTokenForm/AppTokenForm';

export const AppTokens = () => {
    const {actor} = useAuth();
    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [appToken, setAppToken] = useState<IAppTokenForm | null>(null);

    const {data, isError, error, isLoading} = useAppTokenListQuery({...pageState});
    const appTokenDeleteQuery = useAppTokenDeleteQuery();

    useEffect(() => {
        document.title = 'App Tokens | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    if (isError) {
        notification.error({
            key: 'fetch-app-tokens-list-error',
            message: (error as Error)?.message || 'Cannot fetch app tokens!'
        });
    }

    function onAppTokenFormClose() {
        setAppToken(null);
    }

    function showNewAppTokenForm() {
        setAppToken(AppTokenFormData.fromDefault());
    }

    function deleteAppTokenWithConfirmation(entity: IAppToken) {
        Modal.confirm({
            title: 'DELETE APP TOKEN',
            icon: <ExclamationCircleOutlined />,
            content: `App token \n"${entity.id || ''}" will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await appTokenDeleteQuery.mutateAsync(entity);
            },
            onCancel() {},
        });
    }

    function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<IAppToken> | SorterResult<IAppToken>[], extra: any) {
        const tableState = tableParamsToPageState<IAppToken>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('delete', 'AppToken')) {
        actions.push({title: 'Delete', onClick: (record: IAppToken) => deleteAppTokenWithConfirmation(record)})
    }
    
    const columns: ColumnsType<IAppToken> = [
        {
            title: 'Client Id',
            dataIndex: 'id',
            width: '350px',
            render: (text, record) => record.id
        },
        {
            title: 'Permissions',
            dataIndex: 'permissions',
            render: (text, record) => text
        },
        {
            title: 'Created At',
            width: '180px',
            dataIndex: 'createdAt',
            render: (text, record) => {
                return <DatetimePopover timestamp={new Date(record.createdAt).valueOf()} />
            }
        },
        {
            title: 'Last Used At',
            width: '180px',
            dataIndex: 'lastUsedAt',
            ...makeSorter(pageState.sort, 'lastUsedAt'),
            render: (text, record) => {
                if (!record.lastUsedAt) return <></>;
                return <DatetimePopover timestamp={new Date(record.lastUsedAt).valueOf()} />
            }
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    return (
        <>
            <PageHeader
                pageTitle={<>App Tokens <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    actor.can('create', 'AppToken') && <Button size='large' key='new-app-token' type='primary' onClick={showNewAppTokenForm} icon={<PlusCircleOutlined />}>New App Token</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                rowClassName={(record, index) => {return record.enabled === false ? 'table-row-disabled' : ''}}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                onChange={onTableChange}
            />
            }
            {appToken && <AppTokenForm appToken={appToken} onClose={onAppTokenFormClose} />}
        </>
    )
}