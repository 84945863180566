import './DatetimePopover.css';

import Popover from 'antd/lib/popover';
import { format, utcToZonedTime } from 'date-fns-tz';
import parseISO from 'date-fns/parseISO';
import { memo } from 'react';

interface IDatetimePopoverProps {
    timestamp: number;
    dateFormat?: string;
    siteTimezone?: string; // if present - display additional datetime in this timezone, useful for site related dates
    children?: any;
}

function DatetimePopoverComponent({ children, timestamp, siteTimezone, dateFormat = 'hh:mm a MMM dd, yyyy' }: IDatetimePopoverProps) {
    const defaultDateFormat = 'hh:mm a MMM dd, yyyy';

    let siteLocalString: string | null = null;
    if (siteTimezone) {
        const zonedDate = utcToZonedTime(timestamp, siteTimezone);
        siteLocalString = format(zonedDate, dateFormat, { timeZone: siteTimezone });
    }

    const localeString = format(timestamp, dateFormat);

    const date = parseISO(new Date(timestamp).toISOString());
    const adjustForUTCOffset = (date: Date) => {
        return new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        );
    };
    const dateWithOffset = adjustForUTCOffset(date);
    const utcString = format(dateWithOffset, defaultDateFormat);

    const content = (
        <div className="datetime-popover">
            {siteLocalString && 
            <p className="datetime-popover-site">
                <span>Site local:</span>{siteLocalString}
            </p>
            }
            <p className="datetime-popover-utc">
                <span>UTC:</span>{utcString}
            </p>
            <p className="datetime-popover-local">
                <span>Your computer:</span>{format(timestamp, defaultDateFormat)}
            </p>
        </div>
    );

    return (
        <Popover content={content}>
            {children 
                ? children
                :  <div>{localeString}</div>
            }
        </Popover>
    )
}

export const DatetimePopover = memo(DatetimePopoverComponent);