import './NotificationSettings.css';

import Card from 'antd/lib/card';
import Checkbox from 'antd/lib/checkbox';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';

import AlertOutlined from '@ant-design/icons/lib/icons/AlertOutlined';

export function NotificationsSettings() {

    const notificationConfigs = [
        {
            title: 'Event Created:',
            paths: [
                ['notificationSettings', 'eventCreated', 'email'],
                ['notificationSettings', 'eventCreated', 'sms']
            ]
        },
        {
            title: 'Pre Event:',
            paths: [
                ['notificationSettings', 'preEvent', 'email'],
                ['notificationSettings', 'preEvent', 'sms']
            ]
        },
        {
            title: 'Event Started:',
            paths: [
                ['notificationSettings', 'eventStarted', 'email'],
                ['notificationSettings', 'eventStarted', 'sms']
            ]
        },
        {
            title: 'Event Completed:',
            paths: [
                ['notificationSettings', 'eventCompleted', 'email'],
                ['notificationSettings', 'eventCompleted', 'sms']
            ]
        }
    ];

    return (
        <Card bordered={true} className='notification-settings' bodyStyle={{padding: '12px'}}>
            <Card.Meta
                avatar={<AlertOutlined/>}
                title="Notification"
                description="Set what type of notifications do you want to receive"
            />
            <p>&nbsp;</p>
            <table >
                <thead>
                    <tr>
                        <th></th>
                        <th>Email</th>
                        <th>SMS</th>
                    </tr>
                </thead>
                <tbody>
                    {notificationConfigs.map(item => (
                        <tr key={item.title}>
                            <td><Typography.Text strong>{item.title}&nbsp;</Typography.Text></td>
                            {item.paths.map(path => (
                                <td key={`${path}`}>
                                    <Form.Item name={path} valuePropName='checked' noStyle>
                                        <Checkbox />
                                    </Form.Item>
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Card>
    )
}