import merge from 'lodash.merge';
import { createContext, useContext, useEffect, useState } from 'react';

import { Role } from '../../domain/auth/Actor';
import { useAuth } from '../../domain/auth/useAuth';
import { IOrg } from '../../domain/orgs/Org';
import { useOrgListQuery } from '../../domain/orgs/queries';
import { usePageState } from '../../domain/shared/usePageState';

export interface IOrgContext {
    isLoading: boolean;
    orgId: string | null;
    setOrgId: (orgId: string | null) => void;
    orgs: IOrg[];
}

const OrgContext = createContext<IOrgContext>(undefined!);

export const OrgProvider = ({ children }: { children: React.ReactNode }) => {
    const {actor} = useAuth();
    const {pageState, setPageState} = usePageState();
    const [orgId, setOrgId] = useState<string | null>(pageState.filter?.orgId || null);
    const [orgs, setOrgs] = useState<IOrg[]>([]);

    const { data, isLoading } = useOrgListQuery({pagination: {page: 1, size: 1000}, filter: {type: 'generic'}});
    
    useEffect(() => {
        console.info('OrgProvider orgId=', orgId);

        /** if org was changed - we need to reset current page number */
        const patchedPageState = merge(pageState, {filter: {orgId}, pagination: {page: 1}})
        setPageState(patchedPageState);
    }, [orgId]);

    useEffect(() => {
        if (data) {
            if (actor.role === Role.CUSTOMER || actor.role === Role.OPERATOR) {
                setOrgId(actor.orgId);
            }
            const orgs = data.data.sort(compareByTitle);
            setOrgs(orgs);
        }
    }, [data]);

    return (
        <OrgContext.Provider value={{ isLoading, orgId, setOrgId, orgs }}>
            {children}
        </OrgContext.Provider>
    )
};

function compareByTitle(a: IOrg, b: IOrg) {
    return a.title.localeCompare(b.title);
}

export const useOrg = () => {
    return useContext(OrgContext);
};

