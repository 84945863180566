import format from 'date-fns/format';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { API, IListQuery, makeURLSearchParams } from '../api/api';
import { WithPagination } from '../WithPagination';
import { IPriceResponseTrigger, IPriceResponseTriggerForm } from './PriceResponseTrigger';

const KEY = 'price-response-trigger';

export interface IPriceResponseTriggerListQuery extends IListQuery {
    filter?: {
        orgId?: string;
        search?: string;
    }
}

async function getPriceResponseTrigger(triggerId: string): Promise<IPriceResponseTrigger> {
    return await API.fetch<IPriceResponseTrigger>(`/api/v1/price-market/triggers/${triggerId}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function getPriceResponseTriggers(query: IPriceResponseTriggerListQuery): Promise<WithPagination<IPriceResponseTrigger>> {
    const searchParams = makeURLSearchParams(query);

    return await API.fetch<WithPagination<IPriceResponseTrigger>>(`/api/v1/price-market/triggers?${searchParams.toString()}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

async function createPriceResponseTrigger(trigger: IPriceResponseTriggerForm) {
    const data: any = {
        orgId: trigger.orgId,
        price: trigger.price,
        state: trigger.state,
        startTime: trigger.startTime,
        endTime: trigger.endTime,
        stopIfPriceDrops: trigger.stopIfPriceDrops,
        eventDurationMinutes: trigger.eventDurationMinutes,
        eventSignal: trigger.eventSignal,
        enabled: trigger.enabled,
    };

    return await API.fetch<IPriceResponseTrigger>(`/api/v1/price-market/triggers`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function updatePriceResponseTrigger(trigger: IPriceResponseTriggerForm) {
    const data: any = {
        orgId: trigger.orgId,
        price: trigger.price,
        state: trigger.state,
        startTime: trigger.startTime,
        endTime: trigger.endTime,
        stopIfPriceDrops: trigger.stopIfPriceDrops,
        eventDurationMinutes: trigger.eventDurationMinutes,
        eventSignal: trigger.eventSignal,
        enabled: trigger.enabled,
    };

    return await API.fetch<IPriceResponseTrigger>(`/api/v1/price-market/triggers/${trigger.id}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

async function savePriceResponseTrigger(trigger: IPriceResponseTriggerForm) {
    return (trigger.id) ? await updatePriceResponseTrigger(trigger) : await createPriceResponseTrigger(trigger);
}

async function deletePriceResponseTrigger(trigger: IPriceResponseTrigger) {
    await API.fetch(`/api/v1/price-market/triggers/${trigger.id}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json'
        }
    });
}

async function bulkDeletePriceResponseTriggers(ids: string[]) {
    await API.fetch(`/api/v1/price-market/triggers/bulk`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            ids: ids,
            remove: true
        })
    });
}

export async function exportPriceResponseTriggers(query: IPriceResponseTriggerListQuery) {
    const searchParams = new URLSearchParams({
        format: 'csv'
    });
    const result = await API.fetch<string>(`/api/v1/price-market/triggers?${searchParams}`, {
        headers: {
            'Content-Type': 'text/csv'
        }
    });

    const blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
    const filename = `price-response-triggers--${format(new Date(), 'MM-dd-yyyy')}.csv`;
    saveAs(blob, filename);
}

export function usePriceResponseTriggerListQuery(query: IPriceResponseTriggerListQuery) {
    return useQuery([KEY, query], () => getPriceResponseTriggers(query));
}

export function usePriceResponseTriggerQuery(triggerId: string, opts: any = {}) {
    return useQuery([KEY, triggerId], () => getPriceResponseTrigger(triggerId), { ...opts });
}

export function usePriceResponseTriggerCreateQuery() {
    const queryClient = useQueryClient();

    return useMutation((trigger: IPriceResponseTriggerForm) => createPriceResponseTrigger(trigger), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function usePriceResponseTriggerUpdateQuery() {
    const queryClient = useQueryClient();

    return useMutation((trigger: IPriceResponseTriggerForm) => updatePriceResponseTrigger(trigger), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function usePriceResponseTriggerSaveQuery() {
    const queryClient = useQueryClient();

    return useMutation((trigger: IPriceResponseTriggerForm) => savePriceResponseTrigger(trigger), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function usePriceResponseTriggerDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((trigger: IPriceResponseTrigger) => deletePriceResponseTrigger(trigger), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}

export function usePriceResponseTriggerBulkDeleteQuery() {
    const queryClient = useQueryClient();

    return useMutation((ids: string[]) => bulkDeletePriceResponseTriggers(ids), {
        onSuccess() {
            return queryClient.invalidateQueries([KEY])
        }
    });
}