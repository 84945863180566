import { useState } from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import Form from 'antd/lib/form';
import notification from 'antd/lib/notification';
import Typography from 'antd/lib/typography';
import Modal from 'antd/lib/modal';
import { IProgram, IProgramForm } from '../../domain/programs/Program';
import { useProgramSaveQuery } from '../../domain/programs/queries';
import { useAuth } from '../../domain/auth/useAuth';
import './ProgramForm.css';
import { AuditLog } from '../../components/AuditLog/AuditLog';

interface IProgramFormProps {
    program: IProgramForm;
    onClose: (program: IProgramForm | null) => any;
}

export function ProgramForm({ program, onClose }: IProgramFormProps) {
    const {actor} = useAuth()!;
    const [form] = Form.useForm<IProgramForm>();
    const [loading, setLoading] = useState<boolean>(false);
    const saveProgramQuery = useProgramSaveQuery();

    const isNew = program.id === null;

    async function onFormFinish(program: IProgramForm) {
        console.info(`onFormFinish=`, program);
        try {
            setLoading(true);
            await saveProgramQuery.mutateAsync(program);

            notification.info({ key: 'program-save-info', message: 'Program saved' });
            onClose(program);
        } catch (err: any) {
            notification.error({ key: 'program-save-error', message: err.message || 'Cannot save program!' });
        }
        setLoading(false);
    }

    return (
        <Modal
            title={isNew ? 'Create program' : 'Edit program'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='program-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='program-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='program-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={program}
                className='site-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>

                <Form.Item
                    name='title'
                    label={<Typography.Text strong>Title</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter title!' },
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='Title' size='large' />
                </Form.Item>
                
                <Form.Item
                    name='utility'
                    label={<Typography.Text strong>Utility</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter utility name!' },
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='Utility name' size='large' />
                </Form.Item>
                
            </Form>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='program' entityId={program.id!} /> : '' }
        </Modal>
    )
}

export class ProgramFormData implements IProgramForm {
    id: string | null;
    title: string | null;
    utility?: string | null;
    constructor(id: string | null, title: string | null, utility?: string | null) {
        this.id = id;
        this.title = title;
        this.utility = utility;
    }

    static fromProgram(program: IProgram) {
        return new ProgramFormData(program.id, program.title, program.utility);
    }

    static fromDefault() {
        return new ProgramFormData(null, null, null);
    }
}