import { FormInstance } from 'antd/es/form/Form';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form'
import Select from 'antd/lib/select';
import Space from 'antd/lib/space'
import Typography from 'antd/lib/typography'

interface ITriggerTimeRangeProps {
    form: FormInstance<any>;
}

export function TriggerTimeRange({form}: ITriggerTimeRangeProps) {

    function preparedTimeRangeOptions(start: number = 0) {
        const options: {label: string; value: string;}[] = [];
        
        for (let hour = start; hour <= 24; hour++) {
            const hourStr = `${hour}`.padStart(2, '0');

            if (hour === 24) {
                options.push({
                    label: '24',
                    value: `23:59:59`
                })
            } else {
                options.push({
                    label: hourStr,
                    value: `${hourStr}:00:00`
                })
        }
        }

        return options;
    }

    function isTimeAfter(a: string, b: string) {
        const [aHours, aMinutes, aSeconds] = a.split(':');
        const [bHours, bMinutes, bSeconds] = b.split(':');

        const aTotalSeconds = (parseInt(aHours) * 60 * 60) + (parseInt(aMinutes) * 60) + parseInt(aSeconds);
        const bTotalSeconds = (parseInt(bHours) * 60 * 60) + (parseInt(bMinutes) * 60) + parseInt(bSeconds);

        return aTotalSeconds > bTotalSeconds;
    }

    function setWholeDay() {
        form.setFieldsValue({startTime: '00:00:00', endTime: '23:59:59'});
    }
    function setWorkingTime() {
        form.setFieldsValue({startTime: '08:00:00', endTime: '18:00:00'});
    }

    return (
        <Form.Item
            label={<Typography.Text strong>Trigger time</Typography.Text>}
            tooltip="We will create events only in the defined time range."
        >
            <Space>
                <Form.Item
                    noStyle
                    name="startTime"
                    dependencies={['endTime']}
                    rules={[
                        {
                            validator(_, startTime) {
                                const endTimeStr = form.getFieldValue('endTime');
                                if (isTimeAfter(endTimeStr, startTime) === false) {
                                    return Promise.reject(new Error('End time should be after start time'));
                                }
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <Select placeholder="Start time" options={preparedTimeRangeOptions()} size="large" style={{width: '110px'}} />
                </Form.Item>
                -
                <Form.Item
                    noStyle
                    name="endTime"
                    dependencies={['startTime']}
                >
                    <Select placeholder="End time" options={preparedTimeRangeOptions()} size="large" style={{width: '110px'}} />
                </Form.Item>
                <Button onClick={setWholeDay} type="link" className="link-btn">set whole day</Button>
                <Button onClick={setWorkingTime} type="link" className="link-btn">set working time</Button>
            </Space>
        </Form.Item>
    )
}