import './UserForm.css';

import { Select } from 'antd';
import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import { AuditLog } from '../../components/AuditLog/AuditLog';
import { NotificationsSettings } from '../../components/NotificationSettings/NotificationSettings';
import { OrgSelector } from '../../components/OrgSelector/OrgSelector';
import { ProviderSelector } from '../../components/ProviderSelector/ProviderSelector';
import { useAuth } from '../../domain/auth/useAuth';
import { useUserSaveQuery } from '../../domain/users/queries';
import { IUser, IUserForm, NotificationSettings, Role } from '../../domain/users/User';

interface IUserFormProps {
    user: IUserForm;
    onClose: (user: IUserForm | null) => any;
}

export function UserForm({ user, onClose }: IUserFormProps) {
    const [form] = Form.useForm<IUserForm>();
    const [role, setRole] = useState<Role | null>(null);
    const {actor} = useAuth()!;
    const saveUserQuery = useUserSaveQuery();

    let allowedRoles: Role[] = [];

    switch (actor.role) {
        case Role.ADMIN:
            allowedRoles = [Role.ACCOUNT_MANAGER, Role.PROVIDER, Role.CUSTOMER, Role.OPERATOR];
            break;
        case Role.ACCOUNT_MANAGER:
            allowedRoles = [Role.PROVIDER, Role.CUSTOMER, Role.OPERATOR];
            break;
        case Role.PROVIDER:
            allowedRoles = [];
            break;
        case Role.CUSTOMER:
            allowedRoles = [Role.OPERATOR];
            break;
        case Role.OPERATOR:
            allowedRoles = [];
            break;
        default:
            allowedRoles = [];
    }

    const isNew = user.id === null;

    async function onFormFinish(user: IUserForm) {
        console.info(`onFormFinish=`, user);

        try {
            await saveUserQuery.mutateAsync(user);

            notification.info({ key: 'user-save-info', message: 'User saved' });
            onClose(user);
        } catch (err: any) {
            notification.error({ key: 'user-save-error', message: err.message || 'Cannot save user!' });
        }
    }

    function onOrgChange(orgId: string | null) {
        form.setFieldsValue({ orgId: orgId! });
    }

    function onRoleChange(role: string | null) {
        if (user.id) {
            if (user.role === Role.PROVIDER && role && role !== Role.PROVIDER) {
                console.info('reset orgId');
                user.orgId = null;
                form.setFieldValue('orgId', null);
            }

            if (user.role !== Role.PROVIDER && role && role === Role.PROVIDER) {
                console.info('reset orgId');
                user.orgId = null;
                form.setFieldValue('orgId', null);
            }
        }
        setRole(role as Role);
    }

    return (
        <Modal
            title={isNew ? 'Create user' : 'Edit user'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onClose(null)} key='user-modal-cancel' disabled={saveUserQuery.isLoading}>Cancel</Button>,
                <Button onClick={form.submit} key='user-modal-submit' type='primary' loading={saveUserQuery.isLoading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='user-form'
                preserve={false}
                layout='vertical'
                onFinish={onFormFinish}
                initialValues={user}
                className='user-form'
            >
                <Form.Item name='id' hidden><Input /></Form.Item>
                <Form.Item
                    name='role'
                    shouldUpdate
                    label={<Typography.Text strong>Role</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please select role' }
                    ]}>
                    <Select onChange={onRoleChange} size='large' className='user-role-select'>
                        {allowedRoles.map(role => (
                            <Select.Option key={`role-${role}`} value={role} >{role}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {
                actor?.isAdminGroup()
                    ? <Form.Item
                        name='orgId'
                        shouldUpdate
                        label={<Typography.Text strong>Organization</Typography.Text>}
                        hasFeedback
                        rules={[
                            { required: true, message: 'Please select organization' }
                        ]}>
                        {
                        role === Role.PROVIDER
                            ? <ProviderSelector providerId={user.orgId} onChange={onOrgChange}/>
                            : <OrgSelector orgId={user.orgId} onChange={onOrgChange}/>
                        }
                    </Form.Item>
                    : <Form.Item name='orgId' hidden><Input value={actor.orgId}/></Form.Item>
                }
                <Form.Item
                    name='fullname'
                    label={<Typography.Text strong>Full Name</Typography.Text>}
                    hasFeedback
                    rules={[
                        { required: true, message: 'Please enter fullname!' },
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='Jane Doe' size='large' />
                </Form.Item>
                <Form.Item
                    name='email'
                    label={<Typography.Text strong>Email</Typography.Text>}
                    hasFeedback
                    rules={[
                        { max: 100, message: 'Number of characters should be less than 100' }
                    ]}>
                    <Input placeholder='jane@example.com' size='large' disabled={isNew === false} />
                </Form.Item>
                <Form.Item
                    name='phone'
                    label={<Typography.Text strong>Phone</Typography.Text>}
                    hasFeedback
                    rules={[
                        { min: 3, message: 'Number of characters should be 3 or more' },
                        { max: 20, message: 'Number of characters should be less than 20' },
                        { pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, message: 'Phone number can contain only digits and some special symbols such as -+()' }
                    ]}>
                    <Input placeholder='+61000000000' size='large' />
                </Form.Item>
                <Form.Item
                    name='password'
                    rules={[
                        { pattern: /((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})).*$/, message: 'Password must have at least 6 characters that include at least 1 lowercase letter, 1 uppercase letter, number, and one special symbol, e.g. !-_;$@#^%&' },
                        ...(isNew ? [{ required: true, message: 'Password is required!' }] : [])
                    ]}
                    label={<Typography.Text strong>Password</Typography.Text>}>
                    <Input.Password placeholder='' autoComplete='new-password' size='large' />
                </Form.Item>
                <NotificationsSettings />
            </Form>
            <p></p>
            { actor.can('list', 'Audit') && !isNew ? <AuditLog entity='user' entityId={user.id!} /> : '' }
        </Modal>
    )
}

export class UserFormData implements IUserForm {
    constructor(
        public id: string | null, 
        public orgId: string | null, 
        public role: Role, 
        public email: string | null, 
        public password: string | null, 
        public phone: string | null, 
        public fullname: string | null, 
        public notificationSettings: NotificationSettings) {

    }

    static fromUser(user: IUser) {
        return new UserFormData(user.id, user.orgId, user.role, user.email, null, user.phone, user.fullname, user.notificationSettings);
    }

    static fromDefault(orgId: string | null = null) {
        return new UserFormData(null, orgId, Role.OPERATOR, null, null, null, null, {
            eventCreated: {
                email: true,
                sms: true
            },        
            preEvent: {
                email: true,
                sms: true
            },        
            eventStarted: {
                email: true,
                sms: true
            },        
            eventCompleted: {
                email: true,
                sms: true
            }
        });
    }
}