import { useQuery } from 'react-query';
import { API } from '../api/api';
import { IPrices } from './Prices';

async function getPrices(): Promise<IPrices> {
    return await API.fetch<IPrices>(`/api/v1/price-market/prices`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export function usePricesQuery() {
    return useQuery(['prices'], () => getPrices(), {
        refetchInterval: 1000 * 60, // refetch each minute
    });
}