import Select from 'antd/lib/select';
import FieldTimeOutlined from "@ant-design/icons/lib/icons/FieldTimeOutlined";
import { IOrg } from '../../domain/orgs/Org';
import { Session } from '../../domain/auth/Session';
import { useOrg } from '../OrgContext/OrgContext';
import { useEffect } from 'react';
import './GlobalOrgSelector.css';

export const GlobalOrgSelector = () => {
    const {orgs, orgId, setOrgId, isLoading} = useOrg();

    useEffect(() => {
        console.info('GlobalOrgSelector orgId =', orgId);
        orgId && Session.addRecentOrg(orgId);
    }, [orgId]);

    function onOrgChange(orgId: string) {
        setOrgId(orgId);
    }

    function makeOrgOptionsElements(orgs: IOrg[]) {
        const recentOrgs = Session.getRecentOrgs();
        const items: {recent: boolean; value: string; key: string; label: string}[] = [];

        recentOrgs.forEach(orgId => {
            const org = orgs.find(org => org.id === orgId);
            if (!org) return;

            items.push({
                recent: true,
                value: org.id,
                key: `g-org-${org.id}`,
                label: org.title
            })
        });

        orgs.forEach(org => {
            if (recentOrgs.includes(org.id)) return;
            items.push({
                recent: false,
                value: org.id,
                key: `g-org-${org.id}`,
                label: org.title
            })
        });

        return items.map(item => makeOrgOptionElement(item));
    }

    function makeOrgOptionElement(option: {recent: boolean, value: string | null, key: string, label: string}) {
        return (
            <Select.Option
                value={option.value}
                key={option.key}
                label={option.label}
            >
                {option.recent && <FieldTimeOutlined className='recent-icon' style={{ marginRight: '5px' }} />}
                {option.label}
            </Select.Option>
        )
    }

    return (
        <Select
            loading={isLoading}
            size='large'
            placeholder='Please select organization'
            value={isLoading ? '' : orgId}
            onChange={onOrgChange}
            className='global-org-selector'
            bordered={false}
            showSearch
            filterOption={(input, option) => {
                if (!option?.label) return false;
                
                const optionLabel = option.label ? String(option.label) : '';
                return optionLabel.toLowerCase().includes(input.toLowerCase());
            }}
        >
            <Select.Option value={null}>All organizations</Select.Option>
            {makeOrgOptionsElements(orgs)}
        </Select>
    )
}