import Typography from 'antd/lib/typography'
import { ILocation } from '../../domain/sites/Location'

interface ILocationAddressProps {
    location: ILocation | null
}

export const LocationAddress = ({ location }: ILocationAddressProps) => {

    if (!location) return <></>

    return (
        <>
            <Typography.Text>{location.address}</Typography.Text><br />
            <Typography.Text>{location.city}, {location.state}, {location.zip}</Typography.Text>
        </>
    )
}