import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

export const TwoFactorIcon = (props: Partial<CustomIconComponentProps>) => {

    const TwoFactorIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" id="mdi-two-factor-authentication" viewBox="0 0 32 32"  height="1em">
            <path d="M2,7V9H6V11H4A2,2 0 0,0 2,13V17H8V15H4V13H6A2,2 0 0,0 8,11V9C8,7.89 7.1,7 6,7H2M9,7V17H11V13H14V11H11V9H15V7H9M18,7A2,2 0 0,0 16,9V17H18V14H20V17H22V9A2,2 0 0,0 20,7H18M18,9H20V12H18V9Z" />
        </svg>
    );

    return <Icon component={TwoFactorIcon} {...props} />
}