import Button from 'antd/lib/button';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Space from 'antd/lib/space';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import SafetyOutlined from '@ant-design/icons/lib/icons/SafetyOutlined';

import { useAuth } from '../../domain/auth/useAuth';
import { useUserLogoutAllSessionsQuery, useUserSaveQuery } from '../../domain/users/queries';
import { IUserForm } from '../../domain/users/User';
import { NotificationsSettings } from '../NotificationSettings/NotificationSettings';
import { TwoFactorSettings } from '../TwoFactorSettings/TwoFactorSettings';
import { ProfileChangePassword } from './ProfileChangePassword';

interface IProfileProps {
    isVisible: boolean;
    onClose: (args?: any) => void;
}

export const Profile = ({ isVisible, onClose }: IProfileProps) => {

    const [form] = Form.useForm<IUserForm>();
    const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
    const {actor, refresh, signout} = useAuth();
    const saveUserQuery = useUserSaveQuery();
    const logoutAllSessionsQuery = useUserLogoutAllSessionsQuery();
    
    async function onFormFinish(user: IUserForm) {
        console.info(`onFormFinish=`, user);
        try {
            await saveUserQuery.mutateAsync(user);

            /** 
             * here we ask to refetch user profile data from backend
             * so that useAuth has fresh data
             */
            refresh();

            notification.info({ key: 'profile-save-info', message: 'Profile saved' });
            onClose(user);
        } catch (err: any) {
            notification.error({ key: 'profile-save-error', message: err.message || 'Cannot save profile!' });
        }
    }

    function openChangePassword() {
        setChangePasswordVisible(true);
    }

    function onProfileChangePasswordClose() {
        setChangePasswordVisible(false);
    }

    async function onLogoutAll() {
        await logoutAllSessionsQuery.mutateAsync();
        signout();
    }
    
    return (
        <Modal
            title='Your Profile'
            width={600}
            visible={isVisible}
            destroyOnClose={true}
            onCancel={() => onClose(null)}
            footer={[
                <Button onClick={() => onLogoutAll()} key='profile-modal-logout-all' danger style={{float: 'left'}} loading={logoutAllSessionsQuery.isLoading}>Logout All Sessions</Button>,
                <Button onClick={() => onClose(null)} key='profile-modal-cancel'>Cancel</Button>,
                <Button onClick={form.submit} key='profile-modal-submit' type='primary' loading={saveUserQuery.isLoading}>Save</Button>,
            ]}
        >
        <Form
            form={form}
            name='profile-form'
            preserve={false}
            layout='vertical'
            onFinish={onFormFinish}
            initialValues={actor}
            className='profile-form'
        >
            <Form.Item name='id' hidden><Input /></Form.Item>
            <Form.Item
                name='fullname'
                label={<Typography.Text strong>Full Name</Typography.Text>}
                hasFeedback
                rules={[
                    { required: true, message: 'Please enter fullname!' },
                    { max: 100, message: 'Number of characters should be less than 100' }
                ]}>
                <Input placeholder='Jane Doe' size='large' />
            </Form.Item>
            <Form.Item
                name='email'
                label={<Typography.Text strong>Email</Typography.Text>}
                hasFeedback
                rules={[
                    { max: 100, message: 'Number of characters should be less than 100' }
                ]}>
                <Input placeholder='jane@example.com' size='large' disabled />
            </Form.Item>
            <Form.Item
                name='phone'
                label={<Typography.Text strong>Phone</Typography.Text>}
                hasFeedback
                rules={[
                    { min: 3, message: 'Number of characters should be 3 or more' },
                    { max: 20, message: 'Number of characters should be less than 20' },
                    { pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g, message: 'Phone number can contain only digits and some special symbols such as -+()' }
                ]}>
                <Input placeholder='+61000000000' size='large' />
            </Form.Item>
            <Form.Item name='password'>
                <Button onClick={openChangePassword} key='profile-modal-change-password' type='primary' icon={<SafetyOutlined />}>Change Password</Button>
            </Form.Item>
            <Space direction='vertical' style={{width: '100%'}}>
                <TwoFactorSettings />
                <NotificationsSettings />
            </Space>
        </Form>
        {changePasswordVisible && <ProfileChangePassword onClose={onProfileChangePasswordClose} />}
    </Modal>
    )
}