import startOfToday from 'date-fns/startOfToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import { IEvent } from '../../domain/events/Event';
import { isEventInThisDay } from '../../domain/events/isEventInThisDay';
import { IWeatherForecastDaily } from './queries';

export enum DREventChance {
    NONE = 0,
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3
}

export function forecastDrEventChance(data: IWeatherForecastDaily[], events?: IEvent[] | undefined) {
    let hasTodayEvent = false;
    let hasTomorrowEvent = false;

    if (events?.length) {
        const today  = startOfToday();
        const tomorrow  = startOfTomorrow();

        hasTodayEvent = events.some(event => isEventInThisDay(event, today));
        hasTomorrowEvent = events.some(event => isEventInThisDay(event, tomorrow));
    }
    
    return data.map((item, index) => {

        const THRESHOLD_HIGH = 35;
        const THRESHOLD_MED = 32;

        let drChance = {code: DREventChance.LOW, message: 'Low chance for event'};

        if (['sat', 'sun'].includes(item.dayName.toLowerCase())) {
            drChance = {code: DREventChance.NONE, message: 'No event'};
        } else if (item.temperature > THRESHOLD_HIGH) {
            drChance = {code: DREventChance.HIGH, message: 'High chance for event'};
        } else if (item.temperature > THRESHOLD_MED) {
            drChance = {code: DREventChance.MEDIUM, message: 'Medium chance for event'};
        }

        if (hasTodayEvent && index === 0) {
            drChance = {code: DREventChance.HIGH, message: 'Event scheduled'};
        }

        if (hasTomorrowEvent && index === 1) {
            drChance = {code: DREventChance.HIGH, message: 'Event scheduled'};
        }

        return {
            ...item,
            drChance
        }
    });
}