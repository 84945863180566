import { ISite } from '../sites/Site';

export const SIGNAL_LEVEL_OPTIONS = [
    {
        label: 'Normal (0)',
        value: 0
    },
    {
        label: 'Moderate (1)',
        value: 1
    },
    {
        label: 'High (2)',
        value: 2
    },
    {
        label: 'Special (3)',
        value: 3
    }
];

export enum SignalType {
    DEFAULT = 2,
    NORMAL = 0,
    MODERATE = 1,
    HIGH = 2,
    SPECIAL = 3,
}

export enum Opt {
    IN = 'opt-in',
    OUT = 'opt-out',
}

export enum EventSourceType {
    USER = 'user',
    PRICE_RESPONSE = 'price-response',
    PEAK_SAVER = 'peak-saver',
}

export interface UserEventSource {
    type: EventSourceType.USER;
    email: string;
    program?: string;
}

export interface PriceResponseEventSource {
    type: EventSourceType.PRICE_RESPONSE;
    id: string; // price response trigger id 
}

export interface PeakSaverEventSource {
    type: EventSourceType.PEAK_SAVER;
}

export interface DeliveryStatus {
    alert?: {
        scheduled: { publishedAt: string; openedAt: string | null; confirmedAt: string | null } | null,
        'pre-event': { publishedAt: string; openedAt: string | null; confirmedAt: string | null } | null,
        event: { publishedAt: string; openedAt: string | null; confirmedAt: string | null } | null,
        completed: { publishedAt: string; openedAt: string | null; confirmedAt: string | null } | null
    },
    emerson?: { deliveredAt: string | null; },
    api?: { deliveredAt: string | null; }
    
}

export type EventSource = UserEventSource | PriceResponseEventSource | PeakSaverEventSource;

export interface IEvent {
    id: string;
    siteId: string;
    site?: ISite;
    startLocal: string;
    start: string;
    preDurationMinutes: number; // pre-event duration
    durationMinutes: number;
    version: number; // modification number
    signal: SignalType;
    test: boolean;
    cancelled: boolean;
    opt: Opt
    batch: string | null;
    source: EventSource;
    createdAt: string;
    updatedAt: string;

    deliveryStatus?: DeliveryStatus;
}