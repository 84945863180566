import Tooltip from 'antd/lib/tooltip';

import WarningFilled from '@ant-design/icons/lib/icons/WarningFilled';

interface IWarningIconProps {
    title: string
}

export const WarningIcon = ({ title }: IWarningIconProps) => {
    return (
        <Tooltip
            title={title}>
            <WarningFilled style={{color: '#ffc53a'}}/>
        </Tooltip>
    )
}