import './PrivateLayout.css';

import Dropdown from 'antd/lib/dropdown';
import Layout from 'antd/lib/layout';
import { Content, Header } from 'antd/lib/layout/layout';
import Menu from 'antd/lib/menu';
import { useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import ExportOutlined from '@ant-design/icons/ExportOutlined';
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined';
import IdcardFilled from '@ant-design/icons/lib/icons/IdcardFilled';
import UserOutlined from '@ant-design/icons/UserOutlined';

import { ApiIcon } from '../../components/ApiIcon';
import { GlobalOrgSelector } from '../../components/GlobalOrgSelector/GlobalOrgSelector';
import { OrgProvider } from '../../components/OrgContext/OrgContext';
import { Profile } from '../../components/Profile/Profile';
import { Subjects } from '../../domain/auth/ability';
import { Role } from '../../domain/auth/Actor';
import { useAuth } from '../../domain/auth/useAuth';
import { PageMenu } from './PageMenu';

export function PrivateLayout({ children }: { children: React.ReactNode }) {
    const [isProfileDialogVisible, setProfileDialogVisibility] = useState<boolean>(false);
    const {actor, signout} = useAuth();
    const navigate = useNavigate();

    if (actor.isAuthenticated() === false) {
        return <Navigate to='/login' replace={false} />
    }

    const url = new URL(window.location.href);

    if (actor.can('view-route', url.pathname as Subjects) === false) {
        return <Navigate to='/' replace={false} />
    }

    function showProfileDialog() {
        setProfileDialogVisibility(true);
    }

    function closeProfileDialog(user?: any) {
        setProfileDialogVisibility(false);
        if (user) {
            // fetchUserAction();
        }
    }

    function navigateToAppTokens() {
        return navigate('/app-tokens', {replace: false})
    }

    const actorMenuItems = [
        {key: 'actor-edit', icon: <UserOutlined style={{ width: '18px', fontSize: '18px', color: '#000000' }} />, label: 'Profile', onClick: showProfileDialog},
        ...([Role.ADMIN, Role.ACCOUNT_MANAGER, Role.CUSTOMER, Role.PROVIDER].includes(actor.role)) 
            ? [{key: 'actor-app-tokens', icon: <ApiIcon style={{ width: '18px', fontSize: '18px', color: '#000000' }} />, label: 'App Tokens', onClick: navigateToAppTokens}] 
            : [],
        {key: 'actor-log-out', icon: <ExportOutlined style={{ width: '18px', fontSize: '18px', color: '#000000' }} />, label: 'Logout', onClick: signout},
    ];

    return (
        <Layout className='private-layout' >
            <OrgProvider>
                <Header className='header'>
                    <Link to='/'>
                        <img className='app-logo full-logo' src={'/logo.png'} alt='Daydream Energy logo' />
                    </Link>
                    {actor.can('change', 'Org') && <GlobalOrgSelector />}
                    
                    <PageMenu />
                    <Dropdown key='actor-menu' trigger={['click']} overlay={(
                        <Menu items={actorMenuItems} />
                    )}>
                        <div className='actor-menu'>
                            <IdcardFilled style={{ fontSize: '24px', verticalAlign: 'middle' }} />
                            <span className='actor-name'>{actor?.fullname} </span>
                            <DownOutlined className='actor-menu-icon' />
                        </div>
                    </Dropdown>
                </Header>
                <Content className='layout-content'>
                    {children}
                </Content>
                <Profile isVisible={isProfileDialogVisible} onClose={closeProfileDialog} />
            </OrgProvider>
        </Layout>
    )
}