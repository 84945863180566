import { Modal, notification } from 'antd';
import Button from 'antd/lib/button';
import Table, { ColumnsType, TablePaginationConfig } from 'antd/lib/table';
import { SorterResult, TableRowSelection } from 'antd/lib/table/interface';
import merge from 'lodash.merge';
import { useEffect, useState } from 'react';

import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import EditOutlined from '@ant-design/icons/EditOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/lib/icons/ExclamationCircleOutlined';
import PlusCircleOutlined from '@ant-design/icons/PlusCircleOutlined';

import { ActionList, IActionListProps } from '../../components/ActionList/ActionList';
import {
    ClearPageFilterButton
} from '../../components/ClearPageFilterButton/ClearPageFilterButton';
import { makeSorter } from '../../components/CustomDataTable/makeSorter';
import { tableParamsToPageState } from '../../components/CustomDataTable/tableParamsToPageState';
import { toTablePagination } from '../../components/CustomDataTable/toTablePagination';
import { ExportButton } from '../../components/ExportButton/ExportButton';
import { LocationAddress } from '../../components/LocationAddress/LocationAddress';
import { useOrg } from '../../components/OrgContext/OrgContext';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { PeakSaverTag } from '../../components/PeakSaverTag/PeakSaverTag';
import { PriceResponseTag } from '../../components/PriceResponseTag/PriceResponseTag';
import { ProgramTag } from '../../components/ProgramTag/ProgramTag';
import { Search } from '../../components/Search/Search';
import { TotalCountBadge } from '../../components/TotalCountBadge/TotalCountBadge';
import { useAuth } from '../../domain/auth/useAuth';
import { usePageState } from '../../domain/shared/usePageState';
import {
    exportSites, useSiteBulkDeleteQuery, useSiteDeleteQuery, useSiteListQuery
} from '../../domain/sites/queries';
import { ISite, ISiteForm } from '../../domain/sites/Site';
import { SiteBulkForm } from './SiteBulkForm';
import { SiteForm, SiteFormData } from './SiteForm/SiteForm';

export const Sites = () => {
    const {actor} = useAuth();
    const {orgId} = useOrg();
    const {pageState, setPageState} = usePageState();
    const [tableData, setTableData] = useState<any>({data: [], meta: {current: 1, pageSize: pageState.pagination.size, total: 0}});
    const [site, setSite] = useState<ISiteForm | null>(null);
    const [bulkFormVisible, setBulkFormVisible] = useState<boolean>(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    
    const query = merge({...pageState, include: 'org,provider,programs,utility'}, {filter: {orgId}});
    const {data, isError, error, isLoading} = useSiteListQuery(query);
    const siteDeleteQuery = useSiteDeleteQuery();
    const siteBulkDeleteQuery = useSiteBulkDeleteQuery();

    useEffect(() => {
        document.title = 'Sites | Daydream Energy';
    }, []);

    useEffect(() => {
        if (data) {
            const meta = toTablePagination(data.meta)
            setTableData({data: data.data, meta});
        }
    }, [data]);

    if (isError) {
        notification.error({
            key: 'fetch-site-list-error',
            message: (error as Error)?.message || 'Cannot fetch sites!'
        });
    }

    function handleSearch(value: string) {
        setPageState(merge(pageState, {filter: {search: value}}));
        setSelectedRowKeys([]);
    }
    
    function onSiteFormClose() {
        setSite(null);
    }

    function showNewSiteForm() {
        setSite(SiteFormData.fromDefault(orgId));
    }

    function showSiteForm(site: ISite) {
        setSite(SiteFormData.fromSite(site));
    }

    function deleteSiteWithConfirmation(site: ISite) {
        Modal.confirm({
            title: 'DELETE SITE',
            icon: <ExclamationCircleOutlined />,
            content: `Site \n"${site.title || ''}" and its events will be deleted. Are you sure?`,
            okText: 'DELETE',
            async onOk() {
                await siteDeleteQuery.mutateAsync(site);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function bulkDeleteSiteWithConfirmation(siteIds: any[]) {
        console.info('bulkDeleteSiteWithConfirmation siteIds', siteIds);
        Modal.confirm({
            title: 'DELETE SITES',
            icon: <ExclamationCircleOutlined />,
            content: `${siteIds.length} sites and their events will be deleted. Are you sure?`,
            okText: siteIds.length === 1 ? `DELETE SITE` : `DELETE ${siteIds.length} SITES`,
            async onOk() {
                await siteBulkDeleteQuery.mutateAsync(selectedRowKeys as string[]);
                setSelectedRowKeys([]);
            },
            onCancel() {},
        });
    }

    function showSiteBulkForm(siteIds: any[]) {
        setBulkFormVisible(true);
    }

    function onSiteBulkFormClose() {
        setBulkFormVisible(false);
        setSelectedRowKeys([]);
    }

    function onSelectChange(newSelectedRowKeys: React.Key[]) {
        setSelectedRowKeys(newSelectedRowKeys);
    }

    async function onTableChange(pagination: TablePaginationConfig, filter: any, sorter: SorterResult<ISite> | SorterResult<ISite>[], extra: any) {
        const tableState = tableParamsToPageState<ISite>({pagination, filter, sorter, extra});

        setPageState({...pageState, ...tableState });
        setSelectedRowKeys([]);
    }

    const actions: IActionListProps['actions'] = [];
    if (actor.can('update', 'Site')) {
        actions.push({title: 'Edit', onClick: (record: ISite) => showSiteForm(record)})
    }

    if (actor.can('delete', 'Site')) {
        actions.push({title: 'Delete', onClick: (record: ISite) => deleteSiteWithConfirmation(record)})
    }

    function renderEnrollment(site: ISite) {
        const components: any[] = [];

        if (site.priceResponseEnrolled) {
            components.push(<PriceResponseTag key='price-response-tag'/>)
        }

        if (site.peakSaverEnrolled) {
            components.push(<PeakSaverTag key='peak-saver-tag' />)
        }

        site?.programs?.forEach((p, i) => {
            components.push(<ProgramTag program={p} key={`program-tag-${p.id}`} />)
        })

        return components;
    }

    const columns: ColumnsType<ISite> = [
        {
            title: 'Title',
            dataIndex: 'title',
            ellipsis: true,
            ...makeSorter(pageState.sort, 'title'),
            render: (text, record) => record.title
        },
        {
            title: 'Org',
            dataIndex: 'orgId',
            render: (text, record) => record?.org?.title
        },
        {
            title: 'Provider',
            dataIndex: 'providerId',
            render: (text, record) => record?.provider?.title
        },
        {
            title: 'Address',
            dataIndex: 'location.address',
            ellipsis: true,
            render: (text, record) => <LocationAddress location={record.location} />
        },
        {
            title: 'Timezone',
            dataIndex: 'location.timezone',
            render: (text, record) => record.location.timezone
        },
        {
            title: 'Enrollment',
            dataIndex: 'programs',
            render: (text, record) => <>{renderEnrollment(record)}</>
        },
        {
            title: 'Utility',
            dataIndex: 'utility',
            ...makeSorter(pageState.sort, 'utility'),
            render: (text, record) => record.utility?.title || ''
        },
        {
            title: 'Site Number',
            dataIndex: 'siteNumber',
            ...makeSorter(pageState.sort, 'siteNumber'),
            render: (text) => text
        },
        {
            key: 'action',
            sorter: false,
            width: 90,
            render: (text, record) => <ActionList actions={actions} item={record} />
        }
    ];

    const rowSelection: TableRowSelection<ISite> = {
        selectedRowKeys,
        onChange: onSelectChange,
        selections: [
            Table.SELECTION_ALL,
            Table.SELECTION_INVERT,
            Table.SELECTION_NONE,
        ],
    };

    return (
        <>
            <PageHeader
                pageTitle={<>Sites <TotalCountBadge val={tableData?.meta?.total} /></>}
                actions={[
                    <ClearPageFilterButton key='clear-sites-filter'/>,
                    <Search onSearch={handleSearch} defaultValue={pageState.filter?.search ||''} key='site-search' />,
                    actor.can('update', 'Site') && selectedRowKeys.length > 0 && <Button onClick={() => showSiteBulkForm(selectedRowKeys)} size='large' key='site-bulk-edit' type='default' icon={<EditOutlined />}>Edit Selected</Button>,
                    actor.can('delete', 'Site') && selectedRowKeys.length > 0 && <Button onClick={() => bulkDeleteSiteWithConfirmation(selectedRowKeys)} size='large' key='site-bulk-remove' type='primary' icon={<DeleteOutlined />}>Delete Selected</Button>,
                    <ExportButton fn={() => exportSites({include: 'org,provider'})} key='site-export'>Export</ExportButton>,
                    actor.can('create', 'Site') && <Button size='large' key='new-site' type='primary' onClick={showNewSiteForm} icon={<PlusCircleOutlined />}>New Site</Button>
                ]}
            />
            {tableData.data && 
            <Table
                size='middle'
                rowKey='id'
                sticky
                columns={columns}
                dataSource={tableData.data}
                loading={isLoading}
                pagination={{ ...tableData.meta, size: 'default', showSizeChanger: true }}
                rowSelection={rowSelection}
                onChange={onTableChange}
            />
            }
            {site && <SiteForm site={site} onClose={onSiteFormClose}></SiteForm>}
            {bulkFormVisible && <SiteBulkForm ids={selectedRowKeys as string[]} onClose={onSiteBulkFormClose}></SiteBulkForm>}
        </>
    )
}