import Button from 'antd/lib/button';
import Modal from 'antd/lib/modal';
import { useState } from 'react';

import { CaptchaStep } from './CaptchaStep';
import { RequiredStep } from './RequiredStepsError';
import { TwoFactorStep } from './TwoFactorStep';

interface IRequiredStepsProps {
    steps: RequiredStep[];
    onClose: (args?: any) => void;
    onComplete: (args?: any) => void;
}

export function RequiredSteps(props: IRequiredStepsProps) {
    const [result, setResult] = useState<any>(null);
    const [stepIndex, setStepIndex] = useState<number>(0);

    function onClose() {
        props.onClose();
    }

    function onComplete() {
        console.info('RequiredSteps.onComplete', result);
        props.onComplete(result);
    }

    function onNext(value?: any) {
        console.info(`RequiredSteps.onNext stepIndex=${stepIndex}`, value);
        const isLastStep = stepIndex === (props.steps.length - 1);
        
        const state = {...result, ...value};
        setResult(state);

        if (!isLastStep) {
            setStepIndex(stepIndex + 1);
        }
    }

    function renderStep(step: RequiredStep) {
        if (step.type === '2fa') {
            return <TwoFactorStep tokenId={step.tokenId} onClose={() => onClose()} onComplete={onNext} />
        }

        if (step.type === 'captcha') {
            return <CaptchaStep onClose={() => onClose()} onComplete={onNext} />
        }
    }

    function getTitle(step: {type: string}) {
        if (step.type === '2fa') return 'Two Factor Authentication';
        if (step.type === 'captcha') return 'Captcha';

        return 'Required details';
    }

    return (
        <Modal
            title={getTitle(props.steps[stepIndex])}
            width={400}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose()}
            footer={[
                <Button onClick={() => onComplete()} key='required-steps-submit' type='primary' disabled={false}>Submit</Button>
            ]}
        >
            {renderStep(props.steps[stepIndex])}
        </Modal>
    )
}