import { Actions, AppAbilityType, buildAbilityFor, Subjects } from './ability';

export enum Role {
    ADMIN = 'admin',
    ACCOUNT_MANAGER = 'account-manager',
    CUSTOMER = 'customer',
    OPERATOR = 'operator',
    PROVIDER = 'provider',
    GUEST = 'guest'
}

export interface IActor {
    id: string,
    orgId: string,
    fullname: string,
    email: string,
    phone: string | null,
    role: Role,
    accessToken: string;
    twoFactor: {
        enabled: boolean
    };
    notificationSettings: {
        eventCreated: {
            email: boolean;
            sms: boolean;
        }
    
        preEvent: {
            email: boolean;
            sms: boolean;
        }
    
        eventStarted: {
            email: boolean;
            sms: boolean;
        }
    
        eventCompleted: {
            email: boolean;
            sms: boolean;
        }
    }

    isAdminGroup(): boolean;
    isAuthenticated(): boolean;
    can(action: Actions, subject: Subjects): boolean;
}

export class Actor implements IActor {

    id: string;
    orgId: string;
    fullname: string;
    email: string;
    phone: string;
    role: Role;

    private tokens: {
        accessToken: string;
    }
    
    notificationSettings: { 
        eventCreated: { email: boolean; sms: boolean; }; 
        preEvent: { email: boolean; sms: boolean; }; 
        eventStarted: { email: boolean; sms: boolean; }; 
        eventCompleted: { email: boolean; sms: boolean; }; 
    };
    twoFactor: {
        enabled: boolean
    };

    private ability: AppAbilityType;

    constructor(obj: any) {
        if (!obj?.tokens?.accessToken) throw new Error(`Invalid actor data!`);
        if (!obj?.id) throw new Error(`Invalid actor data!`);
        if (!obj?.role) throw new Error(`Invalid actor data!`);

        this.id = obj.id;
        this.orgId = obj.orgId;
        this.fullname = obj.fullname;
        this.email = obj.email;
        this.phone = obj.phone;
        this.role = obj.role;
        this.notificationSettings = obj.notificationSettings;
        this.tokens = obj.tokens;
        this.twoFactor = obj.twoFactor;

        this.ability = buildAbilityFor(this);
    }

    get accessToken() {
        return this.tokens.accessToken;
    }

    isAdminGroup() {
        return [Role.ADMIN, Role.ACCOUNT_MANAGER].includes(this.role);
    }

    isAuthenticated() {
        return !!(this.tokens.accessToken && this.isAccessTokenExpired() === false);
    }

    private isAccessTokenExpired() {
        const parseJwt = (token: string) => {
            try {
                return JSON.parse(atob(token.split('.')[1]));
            } catch (e) {
                return null;
            }
        };

        const payload = parseJwt(this.tokens.accessToken);
        if (payload.exp * 1000 < Date.now()) {
            return true;
        }

        return false;
    }

    can(action: Actions, subject: Subjects) {
        return this.ability.can(action, subject)
    }
}

export class GuestActor implements IActor {
    id: string;
    orgId: string;
    fullname: string;
    email: string;
    phone: string;
    role: Role;
    private tokens: {
        accessToken: string;
    }
    notificationSettings: { 
        eventCreated: { email: boolean; sms: boolean; }; 
        preEvent: { email: boolean; sms: boolean; }; 
        eventStarted: { email: boolean; sms: boolean; }; 
        eventCompleted: { email: boolean; sms: boolean; }; 
    };
    twoFactor: {
        enabled: boolean
    };
    
    constructor() {
        this.id = '';
        this.orgId = '';
        this.fullname = '';
        this.email = '';
        this.phone = '';
        this.role = Role.GUEST;
        this.notificationSettings = {
            eventCreated: { email: false, sms: false },
            preEvent: { email: false, sms: false },
            eventStarted: { email: false, sms: false },
            eventCompleted: { email: false, sms: false }
        };
        this.tokens = {accessToken: ''};
        this.twoFactor = {enabled: false};
    }
    
    get accessToken() {
        return this.tokens.accessToken;
    }

    isAdminGroup() {
        return false;
    }

    isAuthenticated(): boolean {
        // console.info(`GuestActor.isAuthenticated = false`);
        return false;
    }

    can(action: Actions, subject: Subjects) {
        return false;
    }

}