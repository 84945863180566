import Tag from 'antd/lib/tag';
import Typography from 'antd/lib/typography/Typography';

import StockOutlined from '@ant-design/icons/lib/icons/StockOutlined';

export const PeakSaverTag = () => {
    return (
        <Typography className='ellipsis' key='peak-saver-tag'>
            <Tag color={'orange'} icon={<StockOutlined />} >Peak Saver</Tag>
        </Typography>
    )
}