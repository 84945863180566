import { ISite } from '../../../domain/sites/Site';

export interface ICalendarResource {
    id: string;
    selected: boolean;
    org: string;
    program?: string;
    site: string;
    eventOffset?: number | null;
    eventMaxDuration?: number | null;
}

export const toCalendarResources = (sites: ISite[], calendarView: string): ICalendarResource[] => {
    const resources: ICalendarResource[] = [];
    
    if (calendarView === 'dayGridMonth') {
        // Todo
        return [];
    }

    for (let site of sites) {
        resources.push({
            id: site.id,
            selected: false,
            org: site.org?.title!,
            program: site.programs ? site.programs.map(p => p.title).join(', ') : undefined,
            site: site.title,
            eventOffset: site.eventOffset,
            eventMaxDuration: site.eventMaxDuration,
        });
    }
    
    return resources;
}