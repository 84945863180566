import './Dashboard.css';

import { Tooltip } from 'antd';
import Spin from 'antd/lib/spin';
import Tag from 'antd/lib/tag';
import { useEffect, useMemo, useState } from 'react';

import AimOutlined from '@ant-design/icons/lib/icons/AimOutlined';
import { GoogleMap, Marker, MarkerClusterer, useJsApiLoader } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';

import { useOrg } from '../../components/OrgContext/OrgContext';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { SiteInfoCard } from '../../components/SiteInfoCard/SiteInfoCard';
import { config } from '../../config';
import { mapPricesToSites } from '../../domain/price-market/mapPricesToSites';
import { usePricesQuery } from '../../domain/price-market/queries';
import { useSiteListQuery } from '../../domain/sites/queries';
import { ISiteWithPrices } from '../../domain/sites/Site';
import { makeMarker } from './map/makeMarker';
import { mapStyle } from './map/mapStyle';
import {
    makePriceMap, markerClustererCalculator, markerClustererOptions
} from './map/markerClusterer';

const auCenter = {lat: -26.6510071, lng: 134.5376637};

type MapState = {
    zoom: number;
    center: {lat: number; lng: number}
}

const defaultMapState: MapState = {
    zoom: 6,
    center: auCenter,
}

export const Dashboard = () => {
    const {orgId} = useOrg();
    const [mapRef, setMapRef] = useState<any>(null);
    const [mapState, setMapState] = useState<MapState>(defaultMapState);
    const [sites, setSites] = useState<ISiteWithPrices[]>([]);
    const [site, setSite] = useState<ISiteWithPrices | null>(null);
    
    const {data, isLoading} = useSiteListQuery({pagination: {page: 1, size: 50000}, filter: {orgId: orgId || undefined}, include: 'org'});
    const {data: prices} = usePricesQuery();

    useEffect(() => {
        document.title = 'Dashboard | Daydream Energy';
    }, []);

    useEffect(() => {
        console.info('orgId changed', orgId);
        setSite(null);
    }, [orgId]);

    useEffect(() => {
        console.info('sites', data);
        console.info('prices', prices);
        if (!data?.data) return;

        const sitesWithPrices = mapPricesToSites(data.data, prices);
        setSites(sitesWithPrices);

        if (site) {
            // refresh site price
            const updatedSite = sitesWithPrices.find(s => s.id === site.id);
            setSite(updatedSite || null);
        }

        if (!data.data?.length) {
            setMapState(defaultMapState);
        } else {
            fitBounds(data.data);
        }
    }, [data, prices]);
    
    useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: config.GOOGLE_MAP_API
    });

    const fitBounds = (sites: ISiteWithPrices[]) => {
        if (!mapRef) return;

        console.info('fitBounds', sites.length);
        const bounds = new window.google.maps.LatLngBounds();
        sites.map(site => {
            bounds.extend({lat: site.location.lat, lng: site.location.long});
            return site.id;
        });
        mapRef.fitBounds(bounds);

        setMapState({zoom: mapRef.getZoom(), center: {
            lat: mapRef.getCenter().lat(),
            lng: mapRef.getCenter().lng(),
        }});
    };

    const loadHandler = (map: any) => {
        // Store a reference to the google map instance in state
        setMapRef(map);
        fitBounds(sites);
    };

    function onCenterMapHandler() {
        fitBounds(sites);
    }

    function onMarkerClickHandler(site: ISiteWithPrices, e: google.maps.MapMouseEvent) {
        setSite(site);
    }

    function makeMarkers(sites: ISiteWithPrices[], clusterer?: Clusterer) {
        return sites.map((site) => {
            const marker = makeMarker(site);
            return (
                <Marker {...marker} onClick={(e) => onMarkerClickHandler(site, e)} noClustererRedraw={true} clusterer={clusterer} />
            )
        })
    }

    const priceMap = useMemo(() => makePriceMap(sites), [sites]);

    return (
        <div className='dashboard-page'>
            <PageHeader
                pageTitle='Dashboard'
                actions={[]}
            />
            <div className='map-wrapper'>
                <GoogleMap
                    mapContainerClassName='map'
                    onLoad={loadHandler}
                    onCenterChanged={() => {}}
                    onClick={e => {}}
                    center={mapState.center}
                    zoom={mapState.zoom}
                    options={{
                        mapTypeControlOptions: {
                            mapTypeIds: [
                                google.maps.MapTypeId.ROADMAP,
                                google.maps.MapTypeId.SATELLITE
                            ]
                        },
                        styles: mapStyle,
                        maxZoom: 19
                    }}
                    mapContainerStyle={{
                        height: '100%',
                        width: '100%'
                    }}
                >
                    {sites.length && 
                    <MarkerClusterer 
                        key='marker-cluster' 
                        options={{
                            ...markerClustererOptions,
                            calculator: (markers) => markerClustererCalculator(markers, priceMap)
                        }}>
                        {clusterer => <>{makeMarkers(sites, clusterer)}</>}
                    </MarkerClusterer>
                    }
                </GoogleMap>
                <div className='map-extra'>
                    {isLoading
                        ? <Tooltip title='Loading...'><Spin spinning={true} size='small' /></Tooltip>
                        : <Tooltip title='Center map'><AimOutlined style={{ fontSize: '24px' }} onClick={onCenterMapHandler}/></Tooltip>
                    }
                </div>
                <div className='map-legend'>
                    <span>Local MW Price&nbsp;&nbsp;</span>
                    <Tag color='#4B0082'>&lt; -$50</Tag>
                    <Tag color='#174287'>&lt; -$20</Tag>
                    <Tag color='#209CEE'>&lt; $0</Tag>
                    <Tag color='#31ADAA'>normal</Tag>
                    <Tag color='#FFDD57'>&gt;$60</Tag>
                    <Tag color='#FF8C00'>&gt;$120</Tag>
                    <Tag color='#FF3860'>&gt;$200</Tag>
                    <Tag color='#DB143C'>&gt;$500</Tag>
                </div>
                { site && <div className='map-site-info'> <SiteInfoCard site={site} onClose={() => {setSite(null)}} /> </div> }
            </div>
        </div>
    )
}