import { RequiredStepsError } from '../../components/RequiredSteps/RequiredStepsError';
import { config } from '../../config';
import { Actor } from './Actor';
import { Session } from './Session';

export interface SigninData {
    email: string;
    password: string;
    remember: boolean;
    twoFactorToken?: string;
    captcha?: string | null;
}

export const Auth = {
    isAuthenticated: false,

    async signin(data: SigninData) {
        const body = { email: data.email.trim(), password: data.password };

        const response = await fetch(`${config.API_URL}/api/v1/auth/signin`, {
            method: 'POST',
            headers: {
                ...(data.captcha ? {'g-response-key': data.captcha} : {}),
                ...(data.twoFactorToken ? {'dd-2fa-token': data.twoFactorToken} : {}),
                'Content-Type': 'application/json'
            },
            credentials: 'include',
            body: JSON.stringify(body),
        });

        const respBody: any = await response.json();

        if (response.status === 200) {
            Auth.isAuthenticated = true;

            const actor = new Actor(respBody);
            Session.setActor(actor, !!data.remember);
            return actor;
        }

        if (response.status === 400) {
            if (respBody?.require?.length) {
                throw new RequiredStepsError(respBody?.require);
            }
        }

        throw new Error(respBody?.error?.message || `Signin error!`);
    },

    signout() {
        Auth.isAuthenticated = false;
        Session.clear();
        window.location.href = '/login';
    },

    terminateSession() {
        Auth.isAuthenticated = false;
        Session.clear();
        const url = new URL(window.location.href);
        const isHomePage = url.pathname === '/';
        const returnPath = encodeURIComponent(`${url.pathname}${url.search}`);
        window.location.href = isHomePage ? '/login' : `/login?return_path=${returnPath}`;
    },

    async resetPassword(data: { email: string }) {
        const response = await fetch(`${config.API_URL}/api/v1/auth/reset-password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    
        if (response.ok) {
            return;
        }
    
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot send an email`);
    },
    
    async resetPasswordComplete(data: { password: string; token: string | undefined }) {
        const response = await fetch(`${config.API_URL}/api/v1/auth/reset-password/complete`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    
        if (response.ok) {
            return;
        }
    
        const respBody: any = await response.json();
        throw new Error(respBody?.error?.message || `Cannot set new password`);
    }
};