import { Tooltip } from 'antd';
import Button from 'antd/lib/button';
import Col from 'antd/lib/col';
import Form from 'antd/lib/form';
import InputNumber from 'antd/lib/input-number';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Row from 'antd/lib/row';
import Switch from 'antd/lib/switch';
import Typography from 'antd/lib/typography';
import { useState } from 'react';

import CheckOutlined from '@ant-design/icons/lib/icons/CheckOutlined';
import CloseOutlined from '@ant-design/icons/lib/icons/CloseOutlined';
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined';
import UnlockOutlined from '@ant-design/icons/lib/icons/UnlockOutlined';

import { HoursInput } from '../../components/HoursInput/HoursInput';
import { OrgSelector } from '../../components/OrgSelector/OrgSelector';
import { ProgramEnrollment } from '../../components/ProgramEnrollment/ProgramEnrollment';
import { ProviderSelector } from '../../components/ProviderSelector/ProviderSelector';
import { UtilitySelector } from '../../components/UtilitySelector/UtilitySelector';
import { useAuth } from '../../domain/auth/useAuth';
import { useSiteBulkUpdateQuery } from '../../domain/sites/queries';

interface ISiteBulkFormProps {
    ids: string[];
    onClose: () => any;
}

interface IFieldState {
    orgId: boolean;
    providerId: boolean;
    utilityId: boolean;
    eventOffset: boolean;
    eventMaxDuration: boolean;
    estimatedKw: boolean;
    programIds: boolean;
    priceResponseEnrolled: boolean;
    peakSaverEnrolled: boolean;
}

export function SiteBulkForm({ ids, onClose }: ISiteBulkFormProps) {
    const {actor} = useAuth()!;
    const [form] = Form.useForm<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const siteBulkUpdateQuery = useSiteBulkUpdateQuery();

    const [state, setState] = useState<IFieldState>({
        orgId: false,
        providerId: false,
        utilityId: false,
        eventOffset: false,
        eventMaxDuration: false,
        estimatedKw: false,
        programIds: false,
        priceResponseEnrolled: false,
        peakSaverEnrolled: false,
    });

    const update: any = {
        orgId: undefined,
        providerId: undefined,
        utilityId: undefined,
        eventOffset: undefined,
        eventMaxDuration: undefined,
        estimatedKw: undefined,
        programIds: undefined,
        priceResponseEnrolled: false,
        peakSaverEnrolled: false,
    }

    async function onFormFinish(data: any) {
        console.info(`onFormFinish=`, data);
        const update: any = {};

        for (let field of Object.keys(data)) {
            if (state[field as keyof IFieldState]) {
                update[field] = data[field];
            }
        }

        if (state['programIds'] && !data.programIds) {
            /** user can unlock programIds and select nothing - this means user wants to detach all programs from selected sites */
            update['programIds'] = [];
        }

        console.info('update', update);

        try {
            setLoading(true);

            for (let key of Object.keys(update)) {
                if (update[key] === null) delete update[key];
            }

            await siteBulkUpdateQuery.mutateAsync({ids, update});

            notification.info({ key: 'bulk-site-save-info', message: 'Sites updated' });
            onClose();
        } catch (err: any) {
            notification.error({ key: 'bulk-site-save-error', message: err.message || 'Cannot update sites!' });
        }
        setLoading(false);
    }

    function onProgramChange(programIds: string[]) {
        form.setFieldsValue({ programIds: programIds! });
    }

    function onOrgChange(orgId: string | null) {
        form.setFieldsValue({ orgId: orgId! });
    }

    function onProviderChange(providerId: string | null) {
        form.setFieldsValue({ providerId: providerId! });
    }

    function onUtilityChange(id: string | null) {
        form.setFieldsValue({ utilityId: id });
    }

    function onChangeRow(checked: boolean, field: string) {
        setState(state => {
            return {...state, [field]: checked }
        })
    };

    return (
        <Modal
            title={'Update sites'}
            width={600}
            visible={true}
            destroyOnClose={true}
            onCancel={() => onClose()}
            footer={[
                <Button onClick={() => onClose()} key='bulk-site-modal-cancel' disabled={loading}>Cancel</Button>,
                <Button onClick={form.submit} key='bulk-site-modal-submit' type='primary' loading={loading}>Save</Button>,
            ]}
        >
            <Form
                form={form}
                name='bulk-site-form'
                preserve={false}
                layout='vertical'
                initialValues={update}
                onFinish={onFormFinish}
                className='site-form'
            >
                {
                actor?.isAdminGroup() && 
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.orgId ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'orgId')} />
                        </Tooltip>
                    </Col>
                    <Col span={21}>
                        <Form.Item
                            name='orgId'
                            shouldUpdate
                            label={<Typography.Text strong>Organization</Typography.Text>}
                            hasFeedback
                        >
                            <OrgSelector orgId={null} onChange={onOrgChange} disabled={!state.orgId} />
                        </Form.Item>
                    </Col>
                </Row>
                }
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.providerId ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'providerId')} />
                        </Tooltip>
                    </Col>
                    <Col span={21}>
                        <Form.Item
                            name='providerId'
                            shouldUpdate
                            label={<Typography.Text strong>Provider</Typography.Text>}
                            hasFeedback
                        >
                            <ProviderSelector providerId={null} onChange={onProviderChange} disabled={!state.providerId} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.utilityId ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'utilityId')} />
                        </Tooltip>
                    </Col>
                    <Col span={21}>
                        <Form.Item
                            name='utilityId'
                            label={<Typography.Text strong>Utility</Typography.Text>}
                            hasFeedback
                        >
                            <UtilitySelector utilityId={null} onChange={onUtilityChange} disabled={!state.utilityId}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.programIds ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'programIds')} />
                        </Tooltip>
                    </Col>
                    <Col span={21}>
                        <Form.Item
                            name='programIds'
                            shouldUpdate
                            label={<Typography.Text strong>Programs</Typography.Text>}
                            tooltip={
                                <span>This will replace all currently attached programs with selected programs. If you unlock this field and select nothing this will detach all programs for selected sites.</span>
                            }
                            hasFeedback
                        >
                            <ProgramEnrollment programIds={[]} onChange={onProgramChange} disabled={!state.programIds} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.priceResponseEnrolled ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'priceResponseEnrolled')} />
                        </Tooltip>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="priceResponseEnrolled"
                            label={<Typography.Text strong>Price Response</Typography.Text>}
                            tooltip={
                                <span>By enrolling in 'Price Response' we will automatically create events when the price becomes higher and you will receive a notification.</span>
                            }
                            valuePropName="checked"
                        >
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={false} disabled={!state.priceResponseEnrolled} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.peakSaverEnrolled ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'peakSaverEnrolled')} />
                        </Tooltip>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name="peakSaverEnrolled"
                            label={<Typography.Text strong>Peak Saver</Typography.Text>}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} checked={false} disabled={!state.peakSaverEnrolled} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.eventOffset ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'eventOffset')} />
                        </Tooltip>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name='eventOffset'
                            label={<Typography.Text strong>Event offset</Typography.Text>}
                            tooltip={
                                <span>Allow an offset to be applied to the event time to start earlier than scheduled.<br/>Allowed interval is 0-30 min</span>
                            }
                            hasFeedback
                            rules={[]}
                        >
                            <InputNumber type='number' min={0} max={30} step={5} size='large' addonAfter={'minutes'} disabled={!state.eventOffset} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.eventMaxDuration ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'eventMaxDuration')} />
                        </Tooltip>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name='eventMaxDuration'
                            label={<Typography.Text strong>Event max duration</Typography.Text>}
                            tooltip={
                                <span>Allow a maximum duration to be applied to the event time.<br/>Allowed interval is 0-2 hours<br/>0 means that the max duration limit is not set.</span>
                            }
                            hasFeedback
                        >
                            <HoursInput minutes={null} onChange={() => {}} disabled={!state.eventMaxDuration} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                actor?.isAdminGroup() && 
                <Row gutter={8} align="middle">
                    <Col span={3}>
                        <Tooltip title={state.estimatedKw ? 'Lock field if you do not want to change it' : 'Unlock field if you want to update it'}>
                            <Switch size="small" checkedChildren={<UnlockOutlined />} unCheckedChildren={<LockOutlined />} onChange={(e) => onChangeRow(e, 'estimatedKw')} />
                        </Tooltip>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            name='estimatedKw'
                            label={<Typography.Text strong>Estimated Energy Usage</Typography.Text>}
                            tooltip={
                                <span>Estimated energy usage in kilowatts of the site</span>
                            }
                            hasFeedback
                            rules={[]}
                        >
                            <InputNumber type='number' min={0} size='large' addonAfter={'KW'} width='100%' disabled={!state.estimatedKw} />
                        </Form.Item>
                    </Col>
                </Row>
                }
            </Form>
        </Modal>
    )
}